import React, {Component, ReactElement} from 'react';
import GridContainer from '../../../vendor/components/Grid/GridContainer';
import GridItem from '../../../vendor/components/Grid/GridItem';
import CustomFormInput from '../../../core/components/form_data/custom_form_input';
import {FormDataContext, WithFormProp} from '../../../core/components/form_data';
import DateTimeFormInput from '../../../core/components/form_data/date_time_picker_form_input';
import {Waiver} from './edit_waivers';
import {withStyles, WithStyles} from '@material-ui/core';
import dashboardStyle from '../../../core/components/dashboard/dashboardStyle';
import RenderCustomForm, {validateCustomFormData} from '../../../core/components/custom_form/render_custom_form';
import {Attachment, Clear, CloudDownload, CloudUpload, Print} from '@material-ui/icons';
import Button from '../../../vendor/components/CustomButtons/Button';
import {confirmModal, HelpModal, runModal, runModalForm} from '../../../core/components/Modal/Modal';
import FileSelectFormInput from '../../../core/components/form_data/file_select_form_input';
import {withSession} from '../../../core/session_context';
import {current4HYear, optionallyConvertQuillToHTML} from '../../../core/shared/shared_helpers';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ReportViewer from '../../../core/components/report_viewer';
import {Session} from '../../../core/components/app/types';
import moment from 'moment';


interface Props extends WithStyles {
  waiver?: Waiver,
  waiverData: any,
  enrollment?: any,
  memberType?: 'clubMember' | 'volunteer',
  session: Session,
  confirmableWaivers?: Waiver[]
  waiverConfirmationData: any,
  readOnly?: boolean,
  paperEnrollment?: boolean,
  accountType?: any,
  adminView?: boolean
  updateWaiverConfirmationData: (_: any) => void
  noPrint?: boolean
  formOnly?: boolean
}

export function validateWaiverData(waiver: Waiver, waiverData: Record<string, string>,
                                   memberType: 'clubMember' | 'volunteer', props: any): string[] {
  if ((waiverData.uploadName && waiverData.uploadUrl) || waiver?.type === 'confirm' || waiver.signatureOptional) {
    return [];
  }

  const errors: string[] = [];

  if (waiver?.type === 'form') {

    // @ts-ignore
    errors.push(...validateCustomFormData(waiver.form || [], waiverData, 1, props)
        .map(e => e + ` in ${waiver?.title}`));

    if (waiver.key === "annualConnecticut4hVolunteerAgreementForm") {
      const enrollmentCustodialCare = props?.enrollment?.data?.areyousupervisingyouth;
      const waiverCustodialCare = waiverData?.custodialCare;

      if (waiverCustodialCare != enrollmentCustodialCare) {
        errors.push(`The answer for "Are you supervising youth (have custodial care)? (please select yes or no)" in 
        the waiver of ${waiver?.title} does not match your response in the "Personal Info" tab. Your response in the 
        personal info tab is ${enrollmentCustodialCare} and your response in the waiver is ${waiverCustodialCare}`);

      }

    }
  }



  if (waiver?.type !== 'form' || waiver?.includeSignature) {
    if (memberType === 'clubMember' && (!waiverData.studentName?.trim() || !waiverData.studentDate?.trim())) {
      errors.push(`${waiver?.title} must be signed`);
    } else if (!waiverData.parentName?.trim() || !waiverData.parentDate?.trim()) {
      errors.push(`${waiver?.title} must be signed`);
    }

    const enrollmentYear = props?.enrollment?.year || current4HYear(props.session.settings?.state);
    const yearStartMonth = props?.session?.settings?.state?.yearStartMonth || props?.settings?.state?.yearStartMonth;
    const yearStartDay = props?.session?.settings?.state?.yearStartDay || props?.settings?.state?.yearStartDay;

    const startDate = enrollmentYear + '-' +
        (yearStartMonth + 1).toString().padStart(2, '0') + '-' +
        (yearStartDay).toString().padStart(2, '0');

    const endDate = (enrollmentYear + 1) + '-' +
        (yearStartMonth + 1).toString().padStart(2, '0') + '-' +
        (yearStartDay).toString().padStart(2, '0');


    if (waiverData.studentDate !== null && !(moment(waiverData.studentDate).isSameOrAfter(startDate) && moment(waiverData.studentDate).isBefore(endDate))) {
      errors.push(`Member Signature date has a date that is outside of the current 4-H year (${moment(startDate).format("M/D/YY")} - ${moment(endDate).subtract(1, 'd').format("M/D/YY")}) in ${waiver?.title} `);
    }
    if (waiverData.parentDate !== null && !(moment(waiverData.parentDate).isSameOrAfter(startDate) && moment(waiverData.parentDate).isBefore(endDate))) {
      errors.push(`Adult Signature date has a date that is outside of the current 4-H year (${moment(startDate).format("M/D/YY")} - ${moment(endDate).subtract(1, 'd').format("M/D/YY")}) in ${waiver?.title} `);
    }


  }
  return errors;
}

class WaiverView extends Component<Props> {

  async uploadPaperCopy(waiverData: Record<string, string>, onChange: (_: any) => void) {
    const {waiver, session: {backendClient, account}} = this.props;
    const {uploadUrl, uploadName} = await runModalForm(() => <div>
      <FileSelectFormInput id="file" labelText="Paper Copy Upload"
                           accept={['images/*', 'application/pdf']}
                           maxFileSize={15000000}/>
    </div>, {
      title: `Upload Paper Copy of ${waiver?.title}`,
      onSubmit: async ({file}: { file: any }) => {
        if (waiverData.uploadUrl) {
          await this.deletePaperUpload(uploadUrl);
        }
        const uploadName = file.name,
            {url} = await backendClient.createUpload({accountId: account?.id || 0}, {file});
        return {uploadUrl: url, uploadName};
      }
    });
    onChange({uploadUrl, uploadName});
  }

  async deletePaperUpload(uploadUrl: string) {
    await confirmModal('Delete this Upload?', 'Are you sure?', 'danger');
    await window.fetch(uploadUrl, {method: 'delete'})
        .catch(() => {
        });
  }

  renderUploadSubmission(uploadName: string, uploadUrl: string, onChange: (_: any) => void) {
    return <div>
      Current Paper Copy Upload: <Button color="danger" simple onClick={async () => {
      await this.deletePaperUpload(uploadUrl);
      onChange({uploadName: null, uploadUrl: null});
    }}><Clear/> Delete Upload</Button>
      <div>
        <Attachment style={{fontSize: 48, verticalAlign: 'middle'}}/>
        <a href={uploadUrl} download> {uploadName}</a>
      </div>
    </div>;
  }

  showUpload(waiver: Waiver): boolean {
    const {adminView, session: {account}} = this.props;
    if (waiver.allowUploadCompleted) {
      return true;
    }

    if ((waiver.allowUploadCompletedFor === 'admin') && adminView) {
      return true;
    }

    if (waiver.type === 'upload' && waiver.allowUploadCompletedFor !== 'admin') {
      return true;
    }

    return waiver.allowUploadCompletedFor === 'admin-household' && account?.accountType === 'Household';
  }

  renderWaiver(waiver: Waiver,
               waiverData: Record<string, string>,
               onChange: (_: Record<string, string>) => void): ReactElement {
    const {memberType = 'Volunteer', readOnly, paperEnrollment, accountType, formOnly} = this.props,
        hasUpload = !!(waiverData.uploadUrl && waiverData.uploadName);
    const {adminView, session: {account}} = this.props;


    waiver.body = optionallyConvertQuillToHTML(waiver.body);

    if (waiver?.type === 'confirm') {
      const {
            confirmableWaivers = [], waiverConfirmationData,
            updateWaiverConfirmationData
          } = this.props,
          confirmKey = waiver.confirmWaiverKey,
          confirmWaiver = confirmableWaivers.find(cw => cw.key === confirmKey);
      if (!confirmKey || !confirmWaiver) {
        return <p>Missing Waiver</p>;
      }
      const data = (waiverConfirmationData || {})[confirmKey] || {},
          onChange = (newData: any) => {
            Object.keys(newData).forEach(key =>
                newData[key] === undefined ? delete newData[key] : '');
            return updateWaiverConfirmationData({
              ...waiverConfirmationData,
              [confirmKey]: {...data, ...newData}
            });
          };

      return <FormDataContext.Provider value={{data, onChange: onChange as any}}>
        {this.renderWaiver(confirmWaiver, data, onChange)}
      </FormDataContext.Provider>;
    }

    if (waiver?.type === 'form') {

      return <div>
        {!formOnly
            ? <div className="fr-view" dangerouslySetInnerHTML={{__html: waiver.body || ''}}/>
            : null}

        {this.showUpload(waiver) && <div>
          <Button color="primary" simple onClick={() => this.uploadPaperCopy(waiverData, onChange)}>
            <CloudUpload/> {hasUpload ? 'Re-Upload' : 'Upload'} Completed Form</Button>
          <HelpModal>Upload a paper copy of the completed form</HelpModal>
        </div>}

        {hasUpload
            ? this.renderUploadSubmission(waiverData.uploadName, waiverData.uploadUrl, onChange)
            : <>
              <RenderCustomForm customForm={waiver.form || []}
                                account={account}
                                adminView={adminView}
                                readOnly={readOnly}
                                paperEnrollment={paperEnrollment}
                                accountType={accountType}
              />
              {waiver.includeSignature ? <>
                <h4 style={{marginTop: '2rem'}}>Signature</h4>
                {memberType === 'clubMember' ?
                    <GridContainer>
                      <GridItem xs={12} md={5} lg={5}>
                        <CustomFormInput id="studentName" disabled={readOnly}
                                         labelText="Member Name"/>
                      </GridItem>
                      <GridItem md={5} lg={5}>
                        <DateTimeFormInput dateFormat id="studentDate"
                                           disabled={readOnly}
                                           labelText="Date"/>
                      </GridItem>
                      <GridItem xs={12} md={5} lg={5}>
                        <CustomFormInput id="parentName"
                                         disabled={readOnly}
                                         labelText="Parent Name"/>

                      </GridItem>
                      <GridItem md={5} lg={5}>
                        <DateTimeFormInput dateFormat id="parentDate"
                                           disabled={readOnly}
                                           labelText="Date"/>
                      </GridItem>
                    </GridContainer> : <GridContainer>
                      <GridItem xs={12} md={5} lg={5}>
                        <CustomFormInput id="parentName"
                                         disabled={readOnly}
                                         labelText="Name"/>
                      </GridItem>
                      <GridItem md={5} lg={5}>
                        <DateTimeFormInput dateFormat id="parentDate"
                                           disabled={readOnly}
                                           labelText="Date"/>
                      </GridItem>
                    </GridContainer>}</> : ''}</>}
      </div>;
    } else {

      return <div>
        {!formOnly
            ? <div className="fr-view" dangerouslySetInnerHTML={{__html: waiver.body || ''}}/>
            : null}

        {(waiver?.type === 'upload' && waiver?.uploadSrc) ?
            <div>
              <Button color="primary" onClick={() => runModalForm(() =>
                      <iframe title={waiver?.title} src={waiver.uploadSrc}
                              style={{border: 'none', width: 640, minHeight: 420}}/>,
                  {title: waiver?.title, noCancel: true, noSubmit: true, size: 'lg'})}>
                View {/*{waiver?.title}*/} Waiver
              </Button>
              <Button color="primary" onClick={() => {
                let link = document.createElement('a');
                link.download = '';
                link.href = waiver.uploadSrc || '';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}>
                <CloudDownload/>
                {/*Download /!*{waiver.title}*!/ Waiver*/}
                Download {/*{waiver.title}*/} Waiver
              </Button>
            </div>
            : ''}
        <div style={{marginTop: '2rem'}}/>


        {(this.showUpload(waiver) && (waiver?.allowUploadCompletedFor === 'admin' && adminView)) && <div>
          <Button color="primary" simple onClick={() => this.uploadPaperCopy(waiverData, onChange)}>
            <CloudUpload/> {hasUpload ? 'Re-Upload' : 'Upload'} Completed Form</Button>
          <HelpModal>Upload a paper copy of the completed form</HelpModal>
        </div>}

        {(this.showUpload(waiver) && waiver?.allowUploadCompletedFor === 'admin-household') && <div>
          <Button color="primary" simple onClick={() => this.uploadPaperCopy(waiverData, onChange)}>
            <CloudUpload/> {hasUpload ? 'Re-Upload' : 'Upload'} Completed Form</Button>
          <HelpModal>Upload a paper copy of the completed form</HelpModal>
        </div>}

        {hasUpload
            ? this.renderUploadSubmission(waiverData.uploadName, waiverData.uploadUrl, onChange) : ''}

        <h4 style={{marginTop: '2rem'}}>By Entering my Name I Agree to the Above Release</h4>
        {memberType === 'clubMember' ?
            <GridContainer>
              <GridItem xs={12} md={5} lg={5}>
                <CustomFormInput id="studentName" disabled={readOnly}
                                 labelText="Member Name"/>
              </GridItem>
              <GridItem md={5} lg={5}>
                <DateTimeFormInput dateFormat id="studentDate"
                                   disabled={readOnly}
                                   labelText="Date"/>
              </GridItem>
              <GridItem xs={12} md={5} lg={5}>
                <CustomFormInput id="parentName"
                                 disabled={readOnly}
                                 labelText={`${this.props.session.settings?.state?.parentLabel || 'Parent'} Name`}/>

              </GridItem>
              <GridItem md={5} lg={5}>
                <DateTimeFormInput dateFormat id="parentDate"
                                   disabled={readOnly}
                                   labelText="Date"/>
              </GridItem>
            </GridContainer> : <GridContainer>
              <GridItem xs={12} md={5} lg={5}>
                <CustomFormInput id="parentName"
                                 disabled={readOnly}
                                 labelText="Name"/>
              </GridItem>
              <GridItem md={5} lg={5}>
                <DateTimeFormInput dateFormat id="parentDate"
                                   disabled={readOnly}
                                   labelText="Date"/>
              </GridItem>
            </GridContainer>}
      </div>;
    }
  }

  async printWaiver(waiver: Waiver, data: any) {
    const {memberType = 'Volunteer', confirmableWaivers, waiverConfirmationData} = this.props;
    await runModal(() => <div style={{width: '700px'}}>
      <ReportViewer session={this.props.session} reportName="states/all/waivers"
                    params={{
                      waivers: [{
                        waiver:
                            waiver.type === 'confirm'
                                ? {...confirmableWaivers?.find(w => w.key === waiver.confirmWaiverKey), memberType}
                                : {...waiver, memberType}
                        , data: waiver.type === 'confirm' ? waiverConfirmationData[waiver.confirmWaiverKey || ''] : data
                      }]
                    }}/>
    </div>, {
      size: 'lg'
    }).catch(() => {
    });
  }

  render() {
    const {classes, waiver, noPrint, formOnly} = this.props;
    return (
        <div style={{maxWidth: '100%'}}>
          {waiver ? <WithFormProp prop={waiver?.key}>
            <FormDataContext.Consumer>
              {({onChange, data: waiverData}) => <>
                <h3 style={{marginTop: '2rem'}} className={classes.infoText}>
                  {waiver?.title}{waiver?.signatureOptional ? ' (Optional)' : ''}
                  {(!noPrint && !formOnly) ?
                      <Tooltip title="Download Waiver"><IconButton
                          onClick={this.printWaiver.bind(this, waiver, waiverData)}><Print/></IconButton>
                      </Tooltip> : null}
                </h3>
                {this.renderWaiver(waiver, waiverData, onChange)}
              </>}
            </FormDataContext.Consumer>
          </WithFormProp> : null}
        </div>
    );
  }
}

export default withSession(withStyles(dashboardStyle as any)(WaiverView));
