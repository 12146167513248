import React from 'react';

import checkboxStyles from '../../../vendor/assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';
import { withStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import { FormDataContext } from './index';

const styles = {
  ...checkboxStyles,
  lgLabel: {
    ...checkboxStyles.label,
    fontSize: '170%',
  },
};

function SingleCheckbox(props) {
  const {
    readValue = v => v,
    writeValue = (newValue, oldValue) => newValue,
  } = props;

  return <FormDataContext.Consumer>
    {({data, onChange}) => {
      return <div
          className={props.classes.checkboxAndRadio + ' ' +
          props.classes.checkboxAndRadioHorizontal} style={{marginTop: 0}}>
        <FormControlLabel
            control={<Checkbox
                disabled={props.disabled}
                checked={props.checked || !!readValue(data[props.id])}
                onClick={e => onChange({
                  [props.id]: writeValue(e.target.checked, data[props.id]),
                })}
                checkedIcon={<Check className={props.classes.checkedIcon}/>}
                icon={<Check className={props.classes.uncheckedIcon}/>}
                classes={{checked: props.classes.checked}}/>}
            classes={{
              label: props.size === 'lg'
                  ? props.classes.lgLabel
                  : props.classes.label
            }}
            label={props.label || props.labelText}
        />
      </div>;
    }
    }
  </FormDataContext.Consumer>;
}

export default withStyles(styles)(SingleCheckbox);