import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Add as AddIcon,
  ArrowBack,
  Archive,
  Attachment,
  Check as CheckIcon,
  Check,
  Clear,
  Delete as RemoveIcon,
  Edit as EditIcon,
  Save,
  Warning,
} from '@material-ui/icons';
import Table from '../../vendor/components/Table/Table';
import Button from '../../vendor/components/CustomButtons/Button';
import CustomInput from '../../vendor/components/CustomInput/CustomInput';
import GridItem from '../../vendor/components/Grid/GridItem';
import GridContainer from '../../vendor/components/Grid/GridContainer';
import FormControl from '@material-ui/core/FormControl';
import styles from '../../vendor/assets/jss/material-dashboard-pro-react/customSelectStyle.js';
import {withStyles} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SnackbarContent from '../../vendor/components/Snackbar/SnackbarContent';
import ImageInput from './image_input';
import Avatar from '@material-ui/core/Avatar';
import checkboxStyles from '../../vendor/assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js';
import Modal from './Modal/Modal';
import CountySelect from './region_select/county_select_multiple';
import StateSelect from './region_select/state_select';
import {withSession} from '../session_context';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SelectMultiple from '../../core/components/select_multiple';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import defaultImage from '../shared/img/blank_200x200.png';
import WithPaging from './with_paging';
import LoadingButton from './loading_button';
import {extractError} from '../shared/shared_helpers';
import Success from '../../vendor/components/Typography/Success';
import Danger from '../../vendor/components/Typography/Danger';
import Tooltip from '@material-ui/core/Tooltip';
import DateTimeFormInput from './form_data/date_time_picker_form_input';
import CustomFileFormInput from './form_data/custom_file_form_input';
import CustomFormInput from './form_data/custom_form_input';
import {FormData, FormListEditor} from './form_data';

const fill_space = '   ';
const style = {
  ...styles,
  ...checkboxStyles,
};

class TableEdit extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {};
    this.root = React.createRef();
  }

  //////////////////////////////////////////////////////////////////////////
  //
  //  FORMAT THE COLUMN TYPES FOR THE TABLE COLUMNS
  //  TYPES ARE:
  //              date
  //              time
  //              datetime
  //              enum
  //              select_multiple
  //              money
  //              weight
  //              image
  //              boolean
  //              file
  //              file_attachments
  //              password
  //              state_select
  //              county_select
  //
  //////////////////////////////////////////////////////////////////////////
  formatCell(col, row, reloadPage) {
    const value = row[col.key];
    if (col.format) {
      return col.format(value, row, {reloadPage});
    }
    if (col.type === 'date' && value) {
      return moment(value, 'YYYY-MM-DD').format('l');
    }
    if (col.type === 'time' && value) {
      return moment(value, 'HH:mm a').format('h:mm A');
    }
    if (col.type === 'datetime' && value) {
      return moment(value).format('MMM D YYYY h:mm a');
    }
    if (col.type === 'enum' && value) {
      const {rows = []} = this.props;
      const values = (typeof col.values) === 'function'
          ? col.values(row, {rows, getAllValues: true})
          : col.values;
      if (values instanceof Array) {
        const option = values.find(v => v === value || v.value === value);
        return (option && option.label) ? option.label : option;
      } else {
        return values ? values[value] : '';
      }
    }
    if (col.type === 'select_multiple' && value) {
      const values = (col.values || []).map(
          v => (typeof v === 'string') ? {name: v, id: v} : v);
      if (value instanceof Array) {
        return value.map((val) => (
            values.find((option) => option.id === val) || {}).name,
        ).join(', ');
      } else {
        return value;
      }
    }
    if (col.type === 'money' && (value || value === 0)) {
      return typeof value === 'number' ? `$${value.toFixed(2)}` : '$' + value;
    }
    if (col.type === 'weight' && (value || value === 0)) {
      return typeof value === 'number' ? `${value.toFixed(2)} lbs` : value +
          ' lbs';
    }
    if (col.type === 'image' && value) {
      /*return <img className="img-thumbnail" onClick={() => this.setState(
          {showImage: {src: value, alt: col.alt}})}
                  src={value} alt={col.alt}
                  style={{maxWidth: 100, maxHeight: 80}}/>;*/
      return <Avatar src={value || defaultImage} alt={col.alt}/>;
    }
    if (col.type === 'boolean') {
      return value ? <Success><CheckIcon/></Success>
          : <Danger><Clear/></Danger>;
    }

    if (col.type === 'file') {
      return value ? <Tooltip title={value?.replace(/.*\/\d{4}-\d{2}-\d{2}_\d{2}-\d{2}-\d{2}_/, '')} placement='top'>
        <Button justIcon simple color='github' onClick={() => window.open(value, '_blank')}><Attachment/></Button>
      </Tooltip> : '';
    }

    if (col.type === 'file_attachments') {
      return <span style={{display: 'flex', alignItems: 'baseline'}}>
        {(value || []).map((a, i) => a && i < 3 ?
            <Tooltip title={a?.replace(/.*\/\d{4}-\d{2}-\d{2}_\d{2}-\d{2}-\d{2}_/, '')} placement='top'>
              <Button justIcon simple color='github' onClick={() => window.open(a, '_blank')}><Attachment/></Button>
            </Tooltip> : '')}
        {(value || []).length > 3 ? ' . . .' : ''}
      </span>;
    }

    return value;
  }

  cellEditor(col, row) {
    const value = row[col.key];
    const {classes, session: {backendClient}} = this.props;
    if (col.formatEditor) {
      return col.formatEditor(value, row, v => this.setValue(col, v), col);
    }
    if (col.type === 'date') {
      return <FormData data={row} disabled={col.disabled} onChange={(newRow) => this.setValue(col, newRow[col.key])}>
        <DateTimeFormInput id={col.key} disabled={col.disabled} dateFormat label={col.label}/>
      </FormData>;
    }
    if (col.type === 'time') {
      return <FormData data={row} disabled={col.disabled} onChange={(newRow) => this.setValue(col, newRow[col.key])}>
        <DateTimeFormInput id={col.key} disabled={col.disabled} timeFormat label={col.label}/>
      </FormData>;
    }
    if (col.type === 'datetime') {
      return <FormData data={row} disabled={col.disabled} onChange={(newRow) => this.setValue(col, newRow[col.key])}>
        <DateTimeFormInput id={col.key} disabled={col.disabled} dateFormat timeFormat label={col.label}/>
      </FormData>;
    }
    if (col.type === 'enum') {
      const {classes, rows = []} = this.props,
          {isNew} = this.state || {};
      let values = col.values instanceof Function
          ? col.values(row, {rows, isNew})
          : col.values;
      //.map(k => ({label: col.values[k], value: k}));
      return (
          <FormControl fullWidth
                       className={classes.selectFormControl}>
            <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}>
              {col.label}
            </InputLabel>
            <Select
                disabled={col.disabled}
                MenuProps={{className: classes.selectMenu}}
                classes={{select: classes.select}}
                value={value || ''}
                onChange={({target: {value}}) => this.setValue(col, value)}
                inputProps={{name: col.key, id: col.key}}>
              {(values instanceof Array
                      ? values.map(v => v.label ? v : {label: v, value: v})
                      : Object.keys(values || {})
                          .sort((a, b) =>
                              (values[a] || '').localeCompare(values[b]))
                          .map(k => ({label: values[k], value: k}))
              ).map(({label, value}) =>
                  <MenuItem classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }} key={value} value={value}>
                    {label}
                  </MenuItem>)}
            </Select>
          </FormControl>);
    }
    if (col.type === 'password') {
      return (
          <CustomInput
              labelText={col.label}
              disabled={col.disabled}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: value || '',
                type: 'password',
                onChange: ({target: {value}}) => this.setValue(col, value),
              }}/>);
    }
    if (col.type === 'image') {
      return <div style={{marginTop: '1rem'}}>
        <ImageInput value={value || defaultImage} label={col.label}
                    avatar={col.avatar}
                    disabled={col.disabled}
                    onChange={value => {
                      this.setValue(col, value);
                      // This saves the row to prevent deleting an upload
                      // then forgetting to save, resulting in a broken link
                      if (row.id !== undefined) {
                        return this.saveRow();
                      }
                    }}/>
        {col.imageDesc &&
        <Typography variant="caption">{col.imageDesc}</Typography>}
      </div>;
    }
    if (col.type === 'boolean') {
      return <FormControlLabel
          control={
            <Checkbox
                tabIndex={-1}
                disabled={col.disabled}

                onClick={({target: {checked}}) => this.setValue(col, checked)}
                checkedIcon={<Check className={classes.checkedIcon}/>}
                checked={!!value}
                icon={<Check className={classes.uncheckedIcon}/>}
                classes={{checked: classes.checked}}
            />}
          classes={{label: classes.label}}
          label={col.label}
      />;
    }
    if (col.type === 'state_select') {
      return <div>
        <h5>{col.label}</h5>
        <StateSelect
            value={value}
            disabled={col.disabled}
            onChange={value => this.setValue(col, value)}/>
      </div>;
    }
    if (col.type === 'county_select') {
      return <div>
        <h5>{col.label}</h5>
        <CountySelect stateId={col.stateId}
                      value={value}
                      disabled={col.disabled}
                      onChange={value => this.setValue(col, value)}/>
      </div>;
    }
    if (col.type === 'select_multiple') {
      return <SelectMultiple options={col.values}
                             label={col.label}
                             fullWidth={true}
                             disabled={col.disabled}
                             value={value} noSort={col.noSort}
                             onChange={value => this.setValue(col, value)}/>;
    }
    if (col.type === 'file') {
      return <FormData data={row} onChange={(newRow) => {
        this.setValue(col, newRow[col.key]);
        // This saves the row to prevent deleting an upload
        // then forgetting to save, resulting in a broken link
        if (row.id !== undefined) {
          return this.saveRow();
        }
      }}>
        {col.label ? <h6>{col.label}</h6> : ''}
        <CustomFileFormInput id={col.key} labelText={col.label} accept={col.acceptedFiles} disabled={col.disabled}/>
      </FormData>;
    }
    if (col.type === 'file_attachments') {
      return <FormData data={{[col.key]: (row[col.key] || []).map(val => ({url: val}))}} onChange={(newRow) => {
        this.setValue(col, (newRow[col.key] || []).map(val => val.url));
        // This saves the row to prevent deleting an upload
        // then forgetting to save, resulting in a broken link
        if (row.id !== undefined) {
          return this.saveRow();
        }
      }}>
        {col.label ? <h6>{col.label}</h6> : ''}
        <FormListEditor prop={col.key} name={col.label} childrenMapper={(obj, index, onRemove) => {
          return <>
            <GridItem xs={1}>
              <Tooltip title={`Delete ${col.label}`} placement='top'>
                <Button color='danger' simple justIcon onClick={async () => {
                  if (row[col.key][index]) {
                    await window.fetch(row[col.key][index],
                        {method: 'DELETE', headers: backendClient.defaultHeaders});
                  }
                  onRemove();
                }}><Clear/></Button>
              </Tooltip>
            </GridItem>
            <GridItem xs={11}>
              <CustomFileFormInput id='url' labelText={col.label} accept={col.acceptedFiles} disabled={col.disabled}
                                   noRemove/>
            </GridItem>
          </>;
        }}/>
      </FormData>;
    }
    if (col.type === 'switch') {
      return <div>
        <FormControlLabel
            control={<Switch
                disabled={col.disabled}
                checked={!!value}
                onChange={({target: {checked}}) => this.setValue(col, checked)}
                classes={col.color ? {} : {
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  thumb: classes.switchIcon,
                  track: classes.switchBar,
                }}
                color={col.color}
            />
            }
            classes={{
              label: classes.label,
            }}
            label={col.label}
        />

      </div>;

    }
    /*if (col.type === 'longtext') {
      return (<TextareaAutosize className="form-control" value={value || ''} rows={3}
                                onChange={({target: {value}}) => this.setValue(col, value)}/>);
    }*/
    if (col.type === 'weight') {
      return <CustomInput
          labelText={col.label}
          helperText={col.helperText}
          formControlProps={{fullWidth: true}}
          inputProps={{
            disabled: col.disabled,
            value: value || '',
            onChange: ({target: {value}}) => this.setValue(col, value),
            placeholder: col.placeholder,
            endAdornment: <InputAdornment position="end">Lbs</InputAdornment>,
          }}/>;
    }

    if (col.type === 'number') {
      return <CustomFormInput
          labelText={col.label}
          type={"number"}
          formControlProps={{fullWidth: true}}
          inputProps={{
            disabled: col.disabled,
            value: value || '',
            onChange: ({target: {value}}) => this.setValue(col, value),
            placeholder: col.placeholder,
          }}/>;
    }
    if (col.type === 'money') {
      return <CustomInput
          labelText={col.label}
          formControlProps={{fullWidth: true}}
          inputProps={{
            disabled: col.disabled,
            value: value || '',
            onChange: ({target: {value}}) => this.setValue(col, value),
            placeholder: col.placeholder,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}/>;
    }
    if (col.type === 'longtext') {
      return <TextField
          onChange={e => this.setValue(col, e.target.value)}
          variant="outlined"
          id="longtext"
          label={col.label}
          placeholder={col.placeholder}
          multiline
          fullWidth
          rows={col.rows || 4}
          disabled={col.disabled}
          style={{marginTop: '1rem'}}
          value={value || ''}/>;
    }
    return <CustomInput
        labelText={col.label}
        helperText={col.helperText}
        formControlProps={{fullWidth: true}}
        inputProps={{
          value: value || '',
          onChange: ({target: {value}}) => this.setValue(col, value),
          placeholder: col.placeholder,
          disabled: col.disabled,
        }}/>;
  }

  editRow(row, isNew = false) {
    const {onEnterEdit} = this.props;
    if (onEnterEdit) onEnterEdit(row);
    this.scrollUpToTop();
    this.setState({curRow: row, isNew});
  }

  validateAndUpdate(row) {
    const {cols} = this.props,
        newRow = Object.assign({}, row),
        errors = [];
    cols.forEach(col => {
      if (['money', 'weight', 'number'].indexOf(col.type) > -1) {
        newRow[col.key] = parseFloat(row[col.key] || '0');
        if (isNaN(newRow[col.key]) ||
            !/^-?(\d)*\.?(\d)*$/.test(row[col.key] || '0')) {
          errors.push(
              {field: col.key, message: `${col.label} must be a number`});
        }
      }
      const isRequired = typeof col.required === 'function'
          ? col.required(newRow) : !!col.required;
      if (isRequired && (newRow[col.key] === undefined || newRow[col.key] === null)) {
        errors.push({field: col.key, message: `${col.label} is required`});
      }
      if (col.type === 'date' && row[col.key]) {
        const mDate = moment(toISODateStr(row[col.key]), 'YYYY-MM-DD');
        if (!mDate.isValid() || mDate.year() < 1000) {
          errors.push({
            field: col.key,
            message: `${col.label} isn't a valid date format, use / to separate the day, month, and year. Example: 1/1/${new Date().getFullYear()}`,
          });
        } else {
          newRow[col.key] = toISODateStr(mDate);
        }
      }
      if (col.validate) {
        col.validate(newRow[col.key], newRow).forEach(e => {
          errors.push({field: col.key, message: e});
        });
      }
    });
    if (errors.length) {
      this.setState({errors});
    } else {
      this.setState({errors: null});
      return newRow;
    }
  }

  async saveRow() {
    const {curRow} = this.state,
        row = this.validateAndUpdate(curRow);
    if (!row) {
      return;
    }
    try {
      let result;
      if (curRow.id || !this.props.onCreateRow) {
        result = this.props.onSaveRow && this.props.onSaveRow(row);
      } else {
        result = this.props.onCreateRow(row);
      }
      if (result instanceof Promise) {
        await result;
      }
      return true;
    } catch (error) {
      console.error(error);
      if (error instanceof Response) {
        const {errors = [], message} = await error.json()
            .catch(() => ({}));
        console.log('Error while saving row', errors, error);
        if (message) {
          errors.push(message);
        }
        if (!errors.length) {
          errors.push('Unknown Error');
        }
        this.setState({errors: errors.map(message => ({message}))});
      } else {
        const message = await extractError(error, 'Unknown Error');
        this.setState({errors: [{message}]});
      }
      return false;
    }
  }

  scrollUpToTop() {
    const {session: {scrollToTop}} = this.props;
    if (scrollToTop) {
      const cumulativeOffset = function (element) {
        let top = 0;
        do {
          top += element.offsetTop || 0;
          element = element.offsetParent;
        } while (element);
        return top;
      };
      scrollToTop(cumulativeOffset(this.root.current) - 80);
    }
  }

  exitEditView() {
    if (this.props.onExitEdit) {
      this.props.onExitEdit();
    }
    if (this.root.current && this.props.session) {

      this.scrollUpToTop();
    }
    this.setState({curRow: undefined, errors: undefined, isNew: undefined});
  }

  removeRow(item) {
    this.setState({confirmDeleteItem: undefined});
    return (this.props.onRemoveRow &&
        this.props.onRemoveRow(item, (this.props.rows || []).indexOf(item)));
  }

  setValue(col, value) {
    const {curRow} = this.state,
        {onChange = x => x} = this.props,
        newRow = onChange(Object.assign({}, curRow, {[col.key]: value}));
    this.setState({curRow: newRow});
  }

  async editNewRow() {
    this.editRow(this.props.newRow ? await this.props.newRow() : {}, true);
  }

  renderEditView() {
    const {
          cols = [], title,
          newTitle = `New ${title}`, editTitle = `Edit ${title}`,
          rows = [], noGridStyling
        } = this.props,
        {curRow, isNew, errors} = this.state,
        editCols = cols.filter(c => (typeof c.editable) === 'function'
            ? c.editable(curRow, {rows})
            : c.editable);
    return (<div key="edit">
      <h5>{isNew ? newTitle : editTitle}</h5>
      <GridContainer style={noGridStyling ? {width: '100%'} : {}}>
        {editCols.map(c =>
            <GridItem key={c.key} xs={c.xs || 12} md={c.md} lg={c.lg}
                      xl={c.xl}>
              {this.cellEditor(c, curRow)}
            </GridItem>)}
      </GridContainer>
      {errors && <div style={{marginTop: '1rem'}}>
        <SnackbarContent close
                         onClose={() => this.setState({errors: undefined})}
                         icon={Warning} color="danger">
          <ul style={{padding: 0}}>
            {errors.map((e, i) => <li key={e.field || i}>{e.message}</li>)}
          </ul>
        </SnackbarContent>
      </div>}
      <Button simple color="primary"
              onClick={this.exitEditView.bind(this)}><ArrowBack/> Back</Button>
      <LoadingButton color="primary"
                     onClick={async () => {
                       if (await this.saveRow()) {
                         this.exitEditView();
                       }
                     }}><Save/> Save</LoadingButton>
    </div>);
  }

  renderTable(rows, page = 0, reloadPage = () => {
  }) {
    const {
          cols = [], title, updateOnly, noDelete, limitRows, addButtonPosition = 'bottom-left',
          noEdit, showArchiveButton, canDelete = () => true, canEdit = () => true,
        } = this.props,
        {confirmDeleteItem} = this.state,
        formCols = cols.filter(c => !c.formOnly);

    return <div>
      {!updateOnly && (!limitRows || limitRows > rows.length) && (addButtonPosition.startsWith('top')) ?
          <GridContainer style={{width: '100%', margin: '0'}}
                         justify={addButtonPosition === 'top-right' ? 'flex-end' : 'flex-start'}><GridItem><Button
              color="success"
              style={addButtonPosition === 'top-right' ? {float: 'right'} : {}}
              onClick={() => {
                this.setState({lastPage: page});
                this.editNewRow();
              }}>
            <AddIcon/> {title}
          </Button></GridItem></GridContainer> : ''}
      <Table
          tableHead={formCols.map(c => c.label).concat(noEdit ? [] : ['Edit'])}
          tableData={rows.map((r, i) =>
              formCols.map(c => this.formatCell(c, r, () => reloadPage(page)),
              ).concat(canEdit(r) ? !noEdit ? [
                <Tooltip title={`Edit ${title}`} placement="top"><Button key="edit" color="info" simple
                                                                         onClick={() => {
                                                                           this.setState({lastPage: page});
                                                                           this.editRow(r);
                                                                         }}>
                  <EditIcon/>
                </Button></Tooltip>,
              ] : [] : ['']).concat(canDelete(r) ? !updateOnly ? !noDelete ? showArchiveButton ? [<Tooltip
                      title={`Archive ${title}`} placement="top"><Button key="remove" color="info" simple
                                                                         onClick={() => this.setState({
                                                                           lastPage: page, confirmDeleteItem: r,
                                                                         })}>
                    {showArchiveButton ? <Archive/> : <RemoveIcon/>}
                  </Button></Tooltip>,
                  ] :
                  [
                    <Tooltip title={`Delete ${title}`} placement="top"><Button key="remove" color="danger" simple
                                                                               onClick={() => this.setState({
                                                                                 lastPage: page, confirmDeleteItem: r,
                                                                               })}>
                      {showArchiveButton ? <Archive/> : <RemoveIcon/>}
                    </Button></Tooltip>,
                  ] : [] : [] : ['']),
          )}
      />
      {!updateOnly && (!limitRows || limitRows > rows.length) && (addButtonPosition.startsWith('bottom')) ?
          <GridContainer style={{width: '100%', margin: '0'}}
                         justify={addButtonPosition === 'bottom-right' ? 'flex-end' : 'flex-start'}><GridItem><Button
              color="success"
              onClick={() => {
                this.setState({lastPage: page});
                this.editNewRow();
              }}>
            <AddIcon/> {title}
          </Button></GridItem></GridContainer> : ''}
      {
        showArchiveButton ?
            <Modal id="confirm_delete" title="Confirm Archive" footer="CONFIRM"
                   closeable
                   show={confirmDeleteItem === 0 || !!confirmDeleteItem}
                   onClose={() => this.setState(
                       {confirmDeleteItem: undefined})}
                   onConfirm={() => this.setState(
                       {confirmDeleteItem: undefined})}
                   actions={<div><Button onClick={() => this.setState(
                       {confirmDeleteItem: undefined})}
                                         color="primary" simple>
                     Cancel
                   </Button>
                     <Button onClick={async () => {
                       const result = this.removeRow(confirmDeleteItem);
                       if (result instanceof Promise) {
                         await result;
                       }
                       reloadPage(page);
                     }}
                             color="info">
                       Archive
                     </Button></div>}>
              Are you sure you want to archive this profile?
            </Modal>
            :
            <Modal id="confirm_delete" title="Confirm Delete" footer="CONFIRM"
                   closeable
                   show={confirmDeleteItem === 0 || !!confirmDeleteItem}
                   onClose={() => this.setState(
                       {confirmDeleteItem: undefined})}
                   onConfirm={() => this.setState(
                       {confirmDeleteItem: undefined})}
                   actions={<div><Button onClick={() => this.setState(
                       {confirmDeleteItem: undefined})}
                                         color="primary" simple>
                     Cancel
                   </Button>
                     <Button onClick={async () => {
                       const result = this.removeRow(confirmDeleteItem);
                       if (result instanceof Promise) {
                         await result;
                       }
                       reloadPage(page);
                     }}
                             color="danger">
                       Delete
                     </Button></div>}>
              Are you sure you want to delete this?
            </Modal>
      }
    </div>;
  }

  render() {
    const {rows = [], withPaging, loadPageData, pageSize} = this.props;
    const {curRow, lastPage = 0} = this.state;

    return <div ref={this.root}>
      {curRow ? this.renderEditView() :
          (withPaging
              ? <WithPaging renderPage={({pageData, pageIndex, reloadPage}) =>
                  this.renderTable(pageData, pageIndex, reloadPage)}
                            pageSize={pageSize}
                            initialPage={lastPage}
                            loadPageData={loadPageData ? loadPageData
                                : async (page) => page === 0 ? rows : []}/>
              : this.renderTable(rows))}
    </div>;
  }
}

TableEdit.propTypes = {
  rows: PropTypes.array.isRequired,
  cols: PropTypes.array.isRequired,
  title: PropTypes.string,
  newTitle: PropTypes.string,
  editTitle: PropTypes.string,
  withPaging: PropTypes.bool,
  updateOnly: PropTypes.bool,
  noDelete: PropTypes.bool,
  limitRows: PropTypes.number,
  noEdit: PropTypes.bool,
  canDelete: PropTypes.func,
  showArchiveButton: PropTypes.bool,
  canEdit: PropTypes.func,
  onChange: PropTypes.func,
  loadPageData: PropTypes.func,
  newRow: PropTypes.func,
  onRemoveRow: PropTypes.func,
  onSaveRow: PropTypes.func,
  onCreateRow: PropTypes.func,
  onEnterEdit: PropTypes.func,
  onExitEdit: PropTypes.func,
  pageSize: PropTypes.number,
  addButtonPosition: PropTypes.oneOf(['bottom-left', 'bottom-right', 'top-left', 'top-right']),
  classes: PropTypes.any,
  session: PropTypes.any,
};

export default withSession(withStyles(style)(TableEdit));

function toISODateStr(value) {
  if (value && value.format) {
    return value.format('YYYY-MM-DD');
  }
  return /\d{1,2}\/\d{1,2}\/\d{4}/.test(value) ?
      moment(value, 'M/D/YYYY').utc().format('YYYY-MM-DD') : value;
}