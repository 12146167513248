import React, {Component} from 'react';
import {withSession} from '../../session_context';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import styles from '../../../vendor/assets/jss/material-dashboard-pro-react/customSelectStyle';
import {withStyles} from '@material-ui/core';
import CountySelect from './county_select';
import GridContainer from '../../../vendor/components/Grid/GridContainer';
import GridItem from '../../../vendor/components/Grid/GridItem';
import {FormDataContext} from '../form_data/';
import {sortBy} from '../../shared/shared_helpers';

class ClubSelect extends Component {

  async componentDidMount() {
    await this.componentDidUpdate({}, {}, {});
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.value && prevProps.value !== this.props.value) {
      await this.loadFromClub(this.props.value);
    } else if (this.props.countyId && prevProps.countyId !== this.props.countyId) {
      await this.loadFromCounty(this.props.countyId);
    }
  }

  async loadFromClub(clubId) {
    const {session: {backendClient, settings, orgId}} = this.props;
    const {newClubId} = this.state || {};
    if (clubId && newClubId !== clubId) {
      const club = await backendClient.findClub({id: clubId});
      this.setState({
        clubs: sortBy('name', await backendClient.listClubs({countyId: club.countyId, orgId, includeStateWide: true})),
        countyId: club.countyId,
        label: settings.state.clubLabel,
        clubDescription: club.properties.clubDescription,
      });
    }
  }

  async loadFromCounty(countyId) {
    const {session: {backendClient, settings, orgId}} = this.props;
    if (countyId) {
      this.setState({
        clubs: sortBy('name', await backendClient.listClubs({countyId, orgId, includeStateWide: true})),
        countyId,
        label: settings.state.clubLabel
      });
    }
  }

  async changeCounty(countyId) {
    const {session: {backendClient, orgId, settings}} = this.props;
    if (countyId) {
      this.setState(
          {
            clubs: sortBy('name', await backendClient.listClubs({countyId, orgId, includeStateWide: true})),
            countyId,
            label: settings.state.clubLabel,
            clubDescription: '',
          });
    }
  }

  // Gets the club description for the selected club.
  async getClubDescription(selectedClub) {
    const {session: {backendClient, orgId, settings}} = this.props;
    const club = await backendClient.findClub({id: selectedClub});
    this.setState({clubDescription: club.properties.clubDescription})
  }

  render() {
    const {
          classes,
          value,
          disabled,
          onChange,
          id,
          showCountySelect,
          showStateSelect,
          stateId,
          role,
          filter = () => true,
          session:{state, settings},
          fullWidth
        } = this.props,
        {clubs = [], countyId, label, clubDescription} = this.state || {};
    // console.log("SETTINGS", settings.showClubRoleDescription);


    const clubRole = [
          {selectedRole: "Volunteer", accessRole: "The role you have selected has no access to member information in ZSuite. You will be counted as a volunteer in the system and still be able to act as a volunteer in your county, but there is no additional functionality to the role you have selected.", Link: <a />},
          {selectedRole: "Leader", accessRole: "The role you have selected grants you access to the club management tools. This will allow you to look at a listing of club members and volunteers/leaders for your club. There are other helpful club management tools you will have access to. Follow this link for help docs on your new club management tools:", Link: <a href={"https://zsuite.s3.us-east-2.amazonaws.com/static/Help/ZSuiteHelp/ClubVolunteerFunctions.html"} target="_blank"> Tools </a>},
          {selectedRole: "ProjectLeader", accessRole: "The role you have selected will have you count as a “Project Leader” in ZSuite. You will currently need to reach out to your county admin for “Project Leader” access in our system which will allow you to view a list of club members in the project you are a leader of as well as other Project Leader Management tools.", Link: <a /> }
      ];

    return (<GridContainer>
      {/*START OF COUNTY AND CLUB SELECT GRID ITEM*/}
      <GridItem xs={12} sm={12} md={6} lg={6}>
        {this.props.countyId && !showCountySelect ? ''
            : <CountySelect stateId={stateId} value={countyId}
                            fullWidth={fullWidth}
                            disabled={disabled}
                            onChange={async newCountyId => {
                              await this.changeCounty(newCountyId);
                              if (newCountyId !== countyId) {
                                onChange();
                              }
                            }}
                            showStateSelect={showStateSelect}/>
        }
        {countyId &&
        <FormControl fullWidth={fullWidth} className={classes.selectFormControl}>
          <InputLabel htmlFor={id} className={classes.selectLabel}>
            {label || 'Club'}
          </InputLabel>
          <Select
              MenuProps={{className: classes.selectMenu}}
              classes={{select: classes.select}}
              value={value || ''}
              disabled={disabled}
              onChange={e => {
                this.setState({newClubId: e.target.value});
                onChange(e.target.value);
                const selectedClub = e.target.value;
                this.getClubDescription(selectedClub);
              }}
              inputProps={{name: id, id}}>
            {clubs.filter(filter).map((club) =>
                <MenuItem value={club.id}
                          key={club.id}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}>{club.name}</MenuItem>)}

          </Select>
        </FormControl>
        }
      </GridItem>
      {/*END  OF COUNTY AND CLUB SELECT GRID ITEM*/}

      {/*START ROLE ACCESS DESCRIPTION GRID ITEM*/}
        {
          settings.showClubRoleDescription ?
          clubRole.filter(club => club.selectedRole === role).map((club, index) =>
              (
                <GridItem xs={12} sm={12} md={6} lg={6} key={index}>
                    <p>
                        <b>Chosen Role Access In ZSuite: </b><br />
                        {club.accessRole}
                        {club.Link}
                    </p>
                </GridItem>
            )) : ''
        }
        {/*END ROLE ACCESS DESCRIPTION GRID ITEM*/}

      {/*START OF CLUB DESCRIPTION GRID ITEM*/}
      {state?.id !== 'US-UT' &&
      <GridItem xs={12} sm={12} md={6} lg={6}>
        {clubDescription ?
            <p>
              <b>{settings.state.clubLabel} Description: </b><br/>
              {clubDescription}
            </p> : ''
        }
      </GridItem>
      }

      {/*END OF CLUB DESCRIPTION GRID ITEM*/}


      {/*Old Code*/}
      {/*{this.props.countyId && !showCountySelect ? ''*/}
      {/*    : <GridItem xs={12} sm={12} md={12} lg={12}>*/}
      {/*      <CountySelect stateId={stateId} value={countyId}*/}
      {/*                    fullWidth={fullWidth}*/}
      {/*                    onChange={async newCountyId => {*/}
      {/*                      await this.changeCounty(newCountyId);*/}
      {/*                      if (newCountyId !== countyId) {*/}
      {/*                        onChange();*/}
      {/*                      }*/}
      {/*                    }}*/}
      {/*                    showStateSelect={showStateSelect}/>*/}
      {/*    </GridItem>}*/}
      {/*{countyId && <GridItem xs={12} sm={12} md={12} lg={12}>*/}
      {/*  <FormControl fullWidth={fullWidth} className={classes.selectFormControl}>*/}
      {/*    <InputLabel htmlFor={id} className={classes.selectLabel}>*/}
      {/*      {label || 'Club'}*/}
      {/*    </InputLabel>*/}
      {/*    <Select*/}
      {/*        MenuProps={{className: classes.selectMenu}}*/}
      {/*        classes={{select: classes.select}}*/}
      {/*        value={value || ''}*/}
      {/*        onChange={e => {*/}
      {/*          this.setState({newClubId: e.target.value});*/}
      {/*          onChange(e.target.value);*/}
      {/*        }}*/}
      {/*        inputProps={{name: id, id}}>*/}
      {/*      {clubs.filter(filter).map((club) =>*/}
      {/*          <MenuItem value={club.id}*/}
      {/*                    key={club.id}*/}
      {/*                    classes={{*/}
      {/*                      root: classes.selectMenuItem,*/}
      {/*                      selected: classes.selectMenuItemSelected,*/}
      {/*                    }}>{club.name}</MenuItem>)}*/}

      {/*    </Select>*/}
      {/*  </FormControl>*/}
      {/*</GridItem>}*/}
    </GridContainer>);
  }
}

export default withSession(withStyles(styles)(ClubSelect));

export const ClubSelectFormData = withSession(withStyles(styles)(props => <FormDataContext.Consumer>{
  ({data, onChange}) => <ClubSelect {...props} value={data[props.id]}
                                    onChange={id => onChange({...data, [props.id]: id})}/>
}</FormDataContext.Consumer>))