import React, {Component} from 'react';
import {DropzoneArea} from 'material-ui-dropzone';
import {withSession} from '../session_context';
import Muted from '../../vendor/components/Typography/Muted';
import Button from '../../vendor/components/CustomButtons/Button';
import {AttachFile, Clear} from '@material-ui/icons';
import {FormDataContext} from './form_data';

class FileAttachmentsEditor extends Component {
  async uploadFile(file) {
    const {session: {backendClient, account: {id}}, value = [], onChange} = this.props;
    const {url} = await backendClient.createUpload({accountId: id}, {file: file[0]});
    onChange(value.concat([{name: file[0].name, url}]));
  }

  async deleteFile(file) {
    const {session: {backendClient}, value = [], onChange} = this.props,
        //newValue = new Array(value),
        newValue = value,
        index = value.indexOf(file);
    if (index > -1) {
      await fetch(file.url, {
        method: 'DELETE', headers: {
          ...backendClient.defaultHeaders, accept: 'application/json',
        },
      });
      newValue.splice(index, 1);
      onChange(newValue);
    }
  }

  render() {
    const {value = [], limit = 10000, text, acceptedFiles = ['image/*', 'application/*', 'text/*']} = this.props;
    return (
        <div style={{margin: '2rem 0'}}>
          {value.length ? value.map(
              attachment => <div style={{margin: '1rem 0'}}
                                 key={attachment.name}>
                <Button simple color="danger" onClick={this.deleteFile.bind(this, attachment)}> <Clear/>
                </Button>
                <a href={attachment.url} download>
              <span style={{display: 'inline-block', verticalAlign: 'middle'}}>
                <AttachFile/>
              </span> {attachment.name}
                </a>

              </div>) : <Muted>None</Muted>}
          <hr/>
          <DropzoneArea acceptedFiles={acceptedFiles}
                        filesLimit={limit}
                        maxFileSize={100000000}
                        dropzoneText={text}
                        showPreviewsInDropzone={false}
                        onDrop={this.uploadFile.bind(this)}/>

        </div>
    );
  }
}

export default withSession(FileAttachmentsEditor);

export const FileAttachmentsFormInput = withSession(function (props) {
  const {id, labelText} = props;
  return <FormDataContext.Consumer>{
    ({data, onChange}) => <div style={{marginTop: '2rem'}}>
      <label>{labelText}</label>
      <FileAttachmentsEditor {...props}
                             value={data[id]}
                             onChange={newValue => onChange({[id]: newValue})}/>
    </div>
  }</FormDataContext.Consumer>;
});