import React from 'react';
import {FormDataContext} from './index';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import {withSession} from '../../session_context';
import {optionallyConvertQuillToHTML} from "../../shared/shared_helpers";

const FroalaEditor = React.lazy(() => import('react-froala-wysiwyg'));

function CustomFroalaTextField(props) {
  return (
      <FormDataContext.Consumer>
        {({data, onChange}) =>
            <FroalaEditor
                config={{
                  key: 'iTB2xC5C1B4C2A1A1F1pZGCTRSAPJWTLPLZHTQQb1JGZxE2F2G2G1B10B2C1E6D1H2==',
                  attribution: false,
                  documentReady: props.documentReady,
                  toolbarButtons: {

                    'moreText': {
                      'buttons': [
                        'bold',
                        'italic',
                        'underline',
                        'strikeThrough',
                        'subscript',
                        'superscript',
                        'fontSize',
                        'textColor',
                        'backgroundColor',
                        'clearFormatting',

                      ],
                    },
                    'moreRich': {
                      'buttons': [
                        'insertLink',
                        'specialCharacters',
                        'embedly'],
                    },
                    'moreMisc': {
                      'buttons': [
                        'undo',
                        'redo',
                        'fullscreen',
                        'spellChecker'
                      ],
                      'align': 'right',
                      'buttonsVisible': 3,
                    },
                    'moreParagraph': {
                      'buttons': ['alignLeft', 'alignCenter', 'alignRight']
                    },
                    inlineStyles: {
                      'Header': 'fontSize: 22; fontWeight: bold; margin: 0 0 0 10px',
                    },
                    ...props.toolbarButtons
                  },
                  placeholderText: props.labelText || props.label,
                  quickInsertEnabled: false,
                  charCounterCount: true,
                  ...props.charCounterMax ? {charCounterMax: props.charCounterMax} : {},
                  fontSizeSelection: true,
                  fontSize: ['8', '9', '10', '11', '12', '14', '18', '24', '30'],
                  heightMin: props.heightMin ? props.heightMin : 150,
                  heightMax: props.heightMax ? props.heightMax : 160,
                  events: {
                    ...(props.disabled ? {'froalaEditor.initialized': (e, editor) => editor.getEditor().edit.off()} : {}),
                    'image.removed': function ($img) {
                      const src = $img.attr('src'),
                          prefix = props.session.backendClient.getUserFileUrl({path: ''});
                      console.log('src', src, ' PREFIX: ' + prefix);

                      if (src && src.startsWith(prefix)) {
                        return props.session.backendClient.deleteUpload({
                          path: src.replace(prefix, ''),
                        });
                      }
                    },
                  },
                  imageUploadURL: props?.session?.backendClient?.createUploadUrl({
                    accountId: props.session.account.id || 0, urlProperty: 'link',
                  }),
                  imageUploadParam: 'file',
                  imageUploadMethod: 'POST',
                  imageMaxSize: 1024 * 1024 * 10, // 10Mb
                  imageAllowedTypes: ['jpeg', 'jpg', 'png'],
                }}
                model={data[props.id] === undefined || data[props.id] === null ? '' : optionallyConvertQuillToHTML(data[props.id])}
                onModelChange={(body) => onChange({[props.id]: body})}
            />
        }
      </FormDataContext.Consumer>
  );
}

export default withSession(CustomFroalaTextField);