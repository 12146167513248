import React from 'react';
import GridItem from '../../../vendor/components/Grid/GridItem';
import GridContainer from '../../../vendor/components/Grid/GridContainer';
import { FormDataContext } from './index';
import { InputLabel } from '@material-ui/core';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import CustomInput from '../../../vendor/components/CustomInput/CustomInput';

export default function TimeRangeSelect(props) {
  return (<FormDataContext.Consumer>
    {({data, onChange}) => {
      const sharedProps = {
        minutesStep: 5,
        format: 'MM/DD/YYYY hh:mm A',
        disabled: props.disabled,
        TextFieldComponent: (props) =>
            <CustomInput {...props}
                         labelText={props.label}
                         inputProps={{
                           ...props.InputProps,
                           value: props.value,
                           onChange: props.onChange,
                           disabled: props.disabled,
                           inputRef: props.inputRef
                         }}
                         formControlProps={{fullWidth: true}}
            />
      };
      return <GridContainer justify='center'>
        {props.labelText ? <GridItem xs={12}>
          <InputLabel htmlFor={props.startTimeId} style={{marginBottom: 5}}>
            {props.labelText}
          </InputLabel>
        </GridItem> : ''}
        <GridItem md={5} lg={5}>
          <KeyboardDateTimePicker
              {...sharedProps}
              label={props.startLabel || 'Start Time'}
              value={data[props.startTimeId] || null}
              onChange={(time) => time === null
                  ? onChange({[props.startTimeId]: time})
                  : time.isValid() && onChange({[props.startTimeId]: time.format('YYYY-MM-DD[T]HH:mm:ss')})}
          />
        </GridItem>
        <GridItem md='auto' lg='auto'>
          <br/>
          <p style={{marginTop: 15}}>to</p>
        </GridItem>
        <GridItem md={5} lg={5}>
          <KeyboardDateTimePicker
              {...sharedProps}
              label={props.endLabel || 'End Time'}
              value={data[props.endTimeId] || null}
              onChange={(time) => time === null
                  ? onChange({[props.endTimeId]: time})
                  : time.isValid() && onChange({[props.endTimeId]: time.format('YYYY-MM-DD[T]HH:mm:ss')})}
          />
        </GridItem>
      </GridContainer>;
    }}
  </FormDataContext.Consumer>);
}