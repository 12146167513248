import React from 'react';
import { FormDataContext } from './index';
import TransferList from '../transfer_list';

function TransferListFormData(props) {
  return <FormDataContext.Consumer>
    {({data, onChange}) => {
      return <TransferList {...props}
                           data={data[props.id]}
                           choices={props.choices}
                           onChange={value => onChange({[props.id]: value})}/>;
    }}
  </FormDataContext.Consumer>;
}

export default TransferListFormData;