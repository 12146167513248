import React from 'react';

const SessionContext = React.createContext({});

export function withSession(Component) {
  return props =>
      <SessionContext.Consumer>
        {session => <Component session={session} {...props} />}
      </SessionContext.Consumer>;
}

export default SessionContext;
