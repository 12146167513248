import React, {Component} from 'react';
import GridItem from '../../../../vendor/components/Grid/GridItem';
import GridContainer from '../../../../vendor/components/Grid/GridContainer';
import {FormData} from '../../../../core/components/form_data';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '../../../../vendor/components/Table/Table';
import {fixedAdd, formatMoney} from '../../../../core/shared/shared_helpers';
import RenderPaymentMethods from '../../../../core/components/payments/render_payment_methods';
import {withRouter} from 'react-router';
import Spinner from '../../../../core/components/spinner';
import Button from '../../../../vendor/components/CustomButtons/Button';
import {KeyboardArrowLeft, Warning} from '@material-ui/icons';
import {runSweetAlert} from '../../../../core/components/Modal/Modal';
import SnackbarContent from "../../../../vendor/components/Snackbar/SnackbarContent";
import {PaymentTransactionLineItem} from '../../../../core/services/backend-client';

class Step3Checkout extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.reloadLineItems();
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if (prevProps.registration !== this.props.registration) {
      this.reloadLineItems();
    }
  }

  async reloadLineItems() {
    const {registration, session: {backendClient}} = this.props;
    this.setState({
      lineItems: await backendClient.updatePendingEventRegistrationLineItems({}, [registration.id]),
      loading: false
    });
  }

  async paymentSuccess() {
    const {history} = this.props;
    await runSweetAlert({
      success: true,
      title: 'Success!',
      body: 'Your payment was successful and you are registered for the event!'
    });
    history.push('/');
  }

  async submitAndPayLater() {
    const {history} = this.props;
    await runSweetAlert({
      success: true,
      title: 'Success!',
      body: 'Your Registration was successful, payment may be required at the event.'
    });
    history.push('/');
  }

  render() {
    const {event, registration, settings, back, stateId} = this.props;
    const {lineItems = [], transactionFee = 0, loading} = this.state;
    const totalLineItems = lineItems.reduce((a: any, b: any) => fixedAdd(a, b.amount), 0),
        totalDue = Math.max(0, fixedAdd(transactionFee, totalLineItems));
    if (loading) return <Spinner/>;

    const arCostCenter = event?.properties?.costCenter || '';
    const arDesignationNum = event?.properties?.designationNum || '';

    const arEventDescription = `${event?.name} | RC0036-FD103-${arCostCenter}-${arDesignationNum}-FN0320-CS028`;
    const authNetDescription = stateId === 'US-AR' ? arEventDescription : '';

    // console.log("STATE ID", stateId.stateId);
    // console.log("AR COST CENTER", arCostCenter);
    // console.log("AR DESIGNATION NUMBER", arDesignationNum);
    // console.log("AR EVENT DESCRIPTION", arEventDescription);
    // console.log("AUTH NET DESCRIPTION", authNetDescription);

    // const mtSelectedEventPaymentMethod = event?.properties?.mtEventPaymentMethod || 'stripe_connect';
    // const mtEventPaymentMethodFiltered = settings.state.eventPaymentMethods.filter(
    //     (epm: { methodType: string; }) =>
    //         epm.methodType.toLowerCase() === (mtSelectedEventPaymentMethod || '').toLowerCase()
    // )
    // console.log("EVENT", event);
    // console.log("STATE EVENT PAYMENT METHODS", settings.state.eventPaymentMethods);

    return <div style={{
      maxWidth: 720,
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '0 1rem',
    }}>
      <FormData data={this.state}
                onChange={this.setState.bind(this)}>
        <GridContainer justify="center">
          <GridItem xs={12} md={10} lg={8}>
            <Table tableHead={['Name', 'Amount']} tableData={[
              ...(lineItems.map((li: PaymentTransactionLineItem) => [
                [li.desc, (li.details?.length && li.details.length > 1) ? '' : formatMoney(li.amount)],
                ...((li.details?.length && li.details.length > 1) ? li.details.map(detail => [
                  <div style={{marginLeft: '2rem'}}>{detail.desc}</div>, formatMoney(detail.amount)]) : []),
              ]).reduce((t: any, a: any) => t.concat(a), [])),
              ...(transactionFee > 0 ? [['Transaction Fee', formatMoney(transactionFee)]] : []),
              [
                <h4>Total Due:</h4>,
                <h4><strong>{formatMoney(totalDue)}</strong></h4>
              ]
            ]}/>

            <div style={{marginTop: '2rem'}}>
              <div style={{textAlign: 'right', display: 'flex'}}>
                <Button onClick={() => back()} style={{marginRight: '0.5rem'}}
                        simple color="warning"><KeyboardArrowLeft/> Back To Registration</Button>
                {!event.onlinePaymentOnly && totalDue ?
                    <Button onClick={() => this.submitAndPayLater()}
                            simple style={{marginRight: '0.5rem'}}
                            color="info">Submit & Pay In-Person</Button> : ''}
              </div>

              {(
                  (stateId !== 'US-OK' || event?.properties?.enableOnlinePayments || event.id === 12584 || event.id === 12593 || event.id === 20827
                      || event.id === 20865 || event.id === 20867 || event.id === 21037 || event.id === 21120)
                  && (stateId !== 'US-ID' && stateId !== 'US-AR'
                      //&& (stateId !== 'US-MT' && stateId !== 'ex-st')
                  )
                  && !event.properties.disableOnlinePayments
              ) ?
                  <RenderPaymentMethods session={{...this.props.session, settings}}
                                        history={this.props.history}
                                        amount={totalDue}
                                        type="EVENT"
                                        referenceData={{
                                          registrationIds: [registration?.id],
                                          itemCode: event.eventIndex,
                                          authNetDescription: authNetDescription
                                        }}
                                        availableMethods={settings.state.eventPaymentMethods}
                                        onUpdateTransactionFee={transactionFee => this.setState({transactionFee})}
                                        onSubmit={() => this.paymentSuccess()}/>
                  : (stateId === 'US-ID' && event?.properties?.paymentDetailCode && !event.properties.disableOnlinePayments) ?
                      <RenderPaymentMethods session={{...this.props.session, settings}}
                                            history={this.props.history}
                                            amount={totalDue}
                                            type="EVENT"
                                            referenceData={{
                                              registrationIds: [registration?.id],
                                              itemCode: event.eventIndex,
                                              detailCode: (event?.properties?.paymentDetailCode || ""),
                                              authNetDescription: authNetDescription
                                            }}
                                            availableMethods={settings.state.eventPaymentMethods}
                                            onUpdateTransactionFee={transactionFee => this.setState({transactionFee})}
                                            onSubmit={() => this.paymentSuccess()}/>
                      :
                      (stateId === 'US-AR' && event?.properties?.costCenter && event?.properties?.designationNum && !event.properties.disableOnlinePayments) ?
                          <RenderPaymentMethods session={{...this.props.session, settings}}
                                                history={this.props.history}
                                                amount={totalLineItems}
                                                type="EVENT"
                                                referenceData={{
                                                  //countyId: county?.id,
                                                  //state: state,
                                                  registrationIds: [registration?.id],
                                                  returnTo: `/event-registrations/${event.id}`,
                                                  itemCode: event.eventIndex,
                                                  detailCode: (event?.properties?.paymentDetailCode || ""),
                                                  authNetDescription: authNetDescription
                                                }}
                                                availableMethods={settings.state.eventPaymentMethods}
                                                onUpdateTransactionFee={transactionFee => this.setState({transactionFee})}/>
                          // : ((stateId == 'US-MT' || stateId === 'ex-st') && mtSelectedEventPaymentMethod) ?
                          //     <RenderPaymentMethods session={{...this.props.session, settings}}
                          //                           history={this.props.history}
                          //                           amount={totalDue}
                          //                           type="EVENT"
                          //                           referenceData={{
                          //                             registrationIds: [registration?.id],
                          //                             itemCode: event.eventIndex,
                          //                             authNetDescription: authNetDescription
                          //                           }}
                          //                           availableMethods={mtEventPaymentMethodFiltered}
                          //                           onUpdateTransactionFee={transactionFee => this.setState({transactionFee})}
                          //                           onSubmit={() => this.paymentSuccess()}/>

                          : <SnackbarContent
                              message="Online Payments are currently unavailable. Please pay in-person or via check at your local 4-H Office"
                              icon={Warning} color="warning"/>
              }
            </div>
          </GridItem>
        </GridContainer>
      </FormData>

    </div>
  }
}

export default withStyles({} as any)(withRouter(Step3Checkout));