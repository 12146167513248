import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
import Snack from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
// @material-ui/icons
import Close from '@material-ui/icons/Close';

import styles
  from '../../assets/jss/material-dashboard-pro-react/components/snackbarContentStyle.js';

const useStyles = makeStyles(styles);

export default function SnackbarContent(props) {
  const classes = useStyles();
  const {message, color, close, icon, onClose, children} = props;
  var action = [];
  const messageClasses = cx({
    [classes.iconMessage]: icon !== undefined,
  });
  if (close !== undefined) {
    action = [
      <IconButton
          className={classes.iconButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
      >
        <Close className={classes.close}/>
      </IconButton>,
    ];
  }
  const iconClasses = cx({
    [classes.icon]: classes.icon,
    [classes.infoIcon]: color === 'info',
    [classes.successIcon]: color === 'success',
    [classes.warningIcon]: color === 'warning',
    [classes.dangerIcon]: color === 'danger',
    [classes.primaryIcon]: color === 'primary',
    [classes.roseIcon]: color === 'rose',
  });
  return (
      <Snack
          message={
            <div>
              {icon !== undefined
                  ? <props.icon className={iconClasses}/>
                  : null}
              <span className={messageClasses}>{message || children}</span>
            </div>
          }
          classes={{
            root: classes.root + ' ' + classes[color],
            message: classes.message,
          }}
          action={action}
      />
  );
}

SnackbarContent.defaultProps = {
  color: 'info',
};

SnackbarContent.propTypes = {
  message: PropTypes.node,
  color: PropTypes.oneOf([
    'info',
    'success',
    'warning',
    'danger',
    'primary',
    'rose',
  ]),
  close: PropTypes.bool,
  icon: PropTypes.object,
  onClose: PropTypes.func,
  children: PropTypes.any,
};
