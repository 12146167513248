import React, {Component, ReactNode} from 'react';
import CustomFormEditor, {CustomForm} from '../../../core/components/custom_form/custom_form_editor';
import {CardHeader} from '@material-ui/core';
import CardBody from '../../../vendor/components/Card/CardBody';
import Card from '../../../vendor/components/Card/Card';
import {Add, ArrowDownward, ArrowUpward, Delete, Edit, Print, Visibility} from '@material-ui/icons';
import {runModal, runModalForm} from '../../../core/components/Modal/Modal';
import CustomFormInput from '../../../core/components/form_data/custom_form_input';
import {FormDataContext} from '../../../core/components/form_data';
import {optionallyConvertQuillToHTML, toCamelCase} from '../../../core/shared/shared_helpers';
import {withSession} from '../../../core/session_context';
import Accordion from '../../../vendor/components/Accordion/Accordion';
import CustomSelectFormInput from '../../../core/components/form_data/custom_select_form_input';
import WaiverView from './waiver_view';
import Button from '../../../vendor/components/CustomButtons/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CustomFroalaTextField from '../../../core/components/form_data/custom_froala_text_field';
import ReportViewer from '../../../core/components/report_viewer';
import CustomFileFormInput from '../../../core/components/form_data/custom_file_form_input';
import SingleCheckbox from '../../../core/components/form_data/single_checkbox';
import GridContainer from '../../../vendor/components/Grid/GridContainer';
import GridItem from '../../../vendor/components/Grid/GridItem';
import MultiSelect from '../../../core/components/form_data/multi_select';
import SelectMultiple from "../../../core/components/select_multiple";

export interface Waiver {
  title: string,
  key: string,
  type: 'text' | 'upload' | 'form' | 'confirm'
  body?: string,
  uploadSrc?: string,
  form?: CustomForm,
  confirmWaiverKey?: string,
  maxAge?: number,
  maxAgeAsOf?: 'currentDate' | 'startOfYear',
  minAge?: number,
  minAgeAsOf?: 'currentDate' | 'startOfYear',
  allowUploadCompleted?: boolean,
  allowUploadCompletedFor?: 'admin' | 'admin-household',
  includeSignature?: boolean,
  signatureOptional: boolean,
  applicableClubRoles?: string[]
  applicableCounties?: string[]
}

interface Props {
  session: any,
  addWaiverLabel?: string,
  waivers: Waiver[],
  confirmableWaivers?: Waiver[]

  onChange(newWaivers: Waiver[]): void,

  memberType: string
  clubRoles?: { key: string, label: string }[]
}

const FROALA_BUTTONS = {
  "moreParagraph": {
    "buttons": [
      "alignLeft",
      "alignCenter",
      "alignRight",
      "alignJustify",
      "formatOL",
      "formatUL",
      "lineHeight",
      "outdent",
      "indent",
    ]
  },
  "moreRich": {
    "buttons": [
      "insertLink",
      "insertTable",
      "specialCharacters",
      "embedly",
      "insertHR"
    ]
  },
}

function parseIntOrUndefined(str: string) {
  const value = parseInt(str, 10);
  return value === 0 ? value : (value || undefined);
}

class EditWaivers extends Component<Props> {
  rootRef: React.RefObject<HTMLDivElement>;

  constructor(props: Readonly<Props>) {
    super(props);
    this.rootRef = React.createRef();
  }

  async editWaiver(waiver: Partial<Waiver>, index?: number) {
    const {onChange, waivers, confirmableWaivers, session, clubRoles} = this.props;
    waiver.body = optionallyConvertQuillToHTML(waiver.body);
    const confirmableWaiverOptions = (confirmableWaivers || [])
        .filter(cw => !waivers.find(w => waiver.key !== w.key && w.confirmWaiverKey === cw.key));
    const counties = await session.backendClient.listCounties({stateId: session.state.id});


    // @ts-ignore
    const newWaiver = await runModalForm(() =>
        <FormDataContext.Consumer>{({data}: { data: any }) => <>
          <GridContainer>
            <GridItem xs={12}>
              <CustomSelectFormInput id='type' labelText='Waiver Type'
                                     options={[
                                       {label: 'Text Waiver', value: 'text'},
                                       {label: 'Upload Waiver', value: 'upload'},
                                       {label: 'Waiver Form', value: 'form'},
                                       ...(confirmableWaivers ? [{
                                         label: 'Waiver Confirmation',
                                         value: 'confirm'
                                       }] : [])
                                     ]}/>
            </GridItem>
            {data.type === 'confirm'
                ? <GridItem xs={12}><CustomSelectFormInput id='confirmWaiverKey'
                                                           labelText='Select a waiver to confirm'
                                                           options={confirmableWaiverOptions.map(cw => ({
                                                             label: cw.title,
                                                             value: cw.key
                                                           }))}/></GridItem>
                : <>
                  <GridItem xs={12}><CustomFormInput id="title" labelText="Waiver Form Title"/></GridItem>
                  <GridItem xs={12}><CustomFroalaTextField id="body" labelText="Instructions (optional)"
                                                           documentReady
                                                           heightMax={400}
                                                           toolbarButtons={FROALA_BUTTONS}/></GridItem>
                  <GridItem xs={12}>{data.type === 'form' ?
                      <SingleCheckbox id='includeSignature'
                                      labelText='Show Signature Fields at the bottom'/> : ''}</GridItem>
                  <GridItem xs={12} md={6}>
                    <CustomFormInput id="minAge" labelText="Minimum Applicable Age"
                                     validate={(a: string) => !(parseIntOrUndefined(a) === undefined)}/>
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    {data.minAge ?
                        <CustomSelectFormInput id="minAgeAsOf" labelText="Minimum Age As Of" options={[
                          {id: 'currentDate', name: 'Current Date'},
                          {id: 'startOfYear', name: 'Start Of 4H Year'}
                        ]}/> : ''}
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <CustomFormInput id="maxAge" labelText="Maximum Applicable Age"
                                     validate={(a: string) => !(parseIntOrUndefined(a) === undefined)}/>
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    {data.maxAge ?
                        <CustomSelectFormInput id="maxAgeAsOf" labelText="Maximum Age As Of" options={[
                          {id: 'currentDate', name: 'Current Date'},
                          {id: 'startOfYear', name: 'Start Of 4H Year'}
                        ]}/> : ''}
                  </GridItem>
                  <GridItem xs={12}>{data.type === 'upload' ?
                      <CustomFileFormInput id="uploadSrc" labelText="Upload File"/> : ''}</GridItem>
                  <GridItem xs={12} lg={6}>
                    <CustomSelectFormInput id="allowUploadCompletedFor"
                                           required={true}
                                           labelText={"Allow Form Upload By (REQUIRED)"}
                                           options={[
                                             ...(
                                                 data.type !== 'upload'
                                                     ? [{name: 'None', id: undefined},] //SHOW THIS DROPDOWN OPTION IF THE WAIVER IS NOT AN UPLOAD WAIVER
                                                     : [] //SHOW THIS OPTION (NOTHING) IF THE WAIVER IS AN UPLOAD WAIVER
                                             ),
                                             {name: 'Admins Only', id: 'admin'},
                                             {name: 'Both Admins and Households', id: 'admin-household'}
                                           ]}/>
                  </GridItem>
                  <GridItem xs={12}>
                    <SingleCheckbox id="signatureOptional" labelText="Signature Optional"/>
                  </GridItem>

                  {session?.settings?.allowHiddenEnrollmentWaivers ?
                      <div>
                        <GridItem xs={12}>
                          <SingleCheckbox id="hideWaiverFromEnrollment"
                                          labelText="Hide this waiver from enrollments?"/>
                        </GridItem>
                      </div>
                      : ''}


                  {clubRoles?.length ? <GridItem xs={12}>
                    <MultiSelect id="applicableClubRoles"
                                 helpText="Club roles for which this waiver is applicable to, will be required to sign for each club"
                                 labelText="Applicable Only to Club Roles" options={
                      (clubRoles || []).map(({key, label}) => ({id: key, name: label}))
                    }/>
                  </GridItem> : null}
                  <GridItem xs={12}>
                    <MultiSelect id="applicableCounties"
                        //@ts-ignore
                                 helpText={<p>
                                   Counties for which this waiver is applicable to, will be required to sign for each
                                   county. <br/>
                                   If left blank, it will be visible to every county in the state.
                                 </p>}
                                 labelText="Applicable Only Counties"
                                 options={(counties || [])}
                    />
                  </GridItem>

                </>}
          </GridContainer>
        </>}</FormDataContext.Consumer>, {
      title: `${index === undefined ? 'New' : 'Edit'} Waiver`,
      initialState: waiver,
      submitLabel: 'Save',
      size: 'md', fullWidth: true, notClosable: true,
      session,
      onSubmit: (data: any) => {
        if (data.type === 'confirm') {
          const confirmWaiver = confirmableWaiverOptions.find(cw => cw.key === data.confirmWaiverKey) as Waiver;
          if (!confirmWaiver) {
            throw new Error('Invalid waiver selection: ' + data.confirmWaiverKey)
          }

          return {
            ...data,
            title: 'Confirm ' + confirmWaiver.title,
            key: toCamelCase('Confirm ' + confirmWaiver.title),
            body: 'Please confirm that the following is correct',
            minAge: parseIntOrUndefined(data.minAge),
            maxAge: parseIntOrUndefined(data.maxAge)
          }
        }


        return {
          ...data,
          key: toCamelCase(data.title),
          minAge: parseIntOrUndefined(data.minAge),
          maxAge: parseIntOrUndefined(data.maxAge),
        }

      }
    }).catch(() => {
    });

    if (newWaiver) {
      if (index === undefined) {
        onChange(waivers.concat([newWaiver]));
      } else {
        onChange(waivers.map((w, i) => i === index ? newWaiver : w));
      }
    }
  }

  removeWaiver(index: number) {
    const {
      onChange
      ,
      waivers
    }

        = this.props;
    onChange(waivers.filter((_, i) => i !== index));
  }

  renderWaiver(waiver: Waiver): ReactNode {
    const {onChange, waivers, confirmableWaivers = []} = this.props;
    waiver.body = optionallyConvertQuillToHTML(waiver.body);
    switch (waiver.type) {
      case 'upload':
        return <div>
          <div className='fr-view' dangerouslySetInnerHTML={{__html: waiver.body || ''}}/>
          <iframe title={waiver.title} src={waiver.uploadSrc}
                  style={{border: 'none', width: '100%', minHeight: 420}}/>
        </div>;
      case 'form':
        return <div>
          <div className='fr-view' dangerouslySetInnerHTML={{__html: waiver.body || ''}}/>
          <CustomFormEditor value={waiver.form || []}
                            onChange={(form: any) =>
                                onChange(waivers.map((w) =>
                                    w.key === waiver.key ? {...w, form} : w))}/>
        </div>;
      case 'confirm':
        return <div>
          <div className='fr-view' dangerouslySetInnerHTML={{__html: waiver.body || ''}}/>
          <WaiverView waiver={confirmableWaivers.find(cw => cw.key === waiver.confirmWaiverKey)}/>
        </div>;
      default:
        return <div className='fr-view' dangerouslySetInnerHTML={{__html: waiver.body || ''}}/>;

    }
  }

  moveWaiverUp(index: number) {
    const {onChange, waivers} = this.props;
    if (index > 0) {
      const newWaivers = [...waivers];
      newWaivers[index] = waivers[index - 1];
      newWaivers[index - 1] = waivers[index];
      onChange(newWaivers);
    }
  }

  moveWaiverDown(index: number) {
    const {onChange, waivers} = this.props;
    if (index < (waivers.length - 1)) {
      const newWaivers = [...waivers];
      newWaivers[index] = waivers[index + 1];
      newWaivers[index + 1] = waivers[index];
      onChange(newWaivers);
    }
  }

  async printWaiver(waiver: Waiver) {
    const {memberType} = this.props;
    await runModal(() => <div style={{width: '700px'}}>
      <ReportViewer session={this.props.session} reportName='states/all/waivers'
                    params={{waivers: [{waiver: {...waiver, memberType}}]}}/>
    </div>, {
      title: waiver.title,
      size: 'lg'
    })

  }

  render() {
    const {waivers, session: {scrollToTop}, memberType} = this.props;
    return (<div ref={this.rootRef}>
      {<Accordion
          active={0}
          onSelect={() => {
            if (this.rootRef.current) {
              const target = offsetFromTop(this.rootRef.current),
                  currentPos = document.querySelector('#mainPanel')?.scrollTop || window.scrollY;
              if (currentPos > target) {
                scrollToTop(target);
              }
            }
          }}
          collapses={waivers.map((waiver, index) => ({

            title: waiver.title + (waiver?.signatureOptional ? " (Optional)" : ''),
            content: <Card key={waiver.key}>
              <CardHeader
                  action={[
                    <Tooltip key='up' title="Move Waiver Up" placement="top">
                      <Button color='github' justIcon simple
                              onClick={this.moveWaiverUp.bind(this, index)}>
                        <ArrowUpward/>
                      </Button></Tooltip>,
                    <Tooltip key='down' title="Move Waiver Down" placement="top">
                      <Button color='github' justIcon simple
                              onClick={this.moveWaiverDown.bind(this, index)}>
                        <ArrowDownward/>
                      </Button></Tooltip>,
                    <Tooltip key='print' title="Print Waiver" placement="top">
                      <Button color='github' justIcon simple
                              onClick={this.printWaiver.bind(this, waiver)}>
                        <Print/>
                      </Button></Tooltip>,
                    <Tooltip key='edit' title="Edit Waiver" placement="top">
                      <Button color='github' justIcon simple
                              onClick={this.editWaiver.bind(this, waiver, index)}>
                        <Edit/>
                      </Button></Tooltip>,
                    <Tooltip key='remove' title="Delete Waiver" placement="top">
                      <Button color='github' justIcon simple
                              onClick={this.removeWaiver.bind(this, index)}>
                        <Delete/>
                      </Button></Tooltip>]}
                  title={waiver.title}/>
              <CardBody>
                <div>{this.renderWaiver(waiver)}
                  <Button color="primary" simple startIcon={<Visibility/>}
                          onClick={async () => await runModalForm(() =>
                                  <WaiverView adminView
                                              waiver={waiver}
                                              readOnly={true}
                                              memberType={memberType}/>
                              , {
                                title: 'Waiver Preview',
                                submitLabel: 'Ok',
                                noCancel: true,
                                session: this.props.session
                              }).catch(() => {
                          })}
                  >Preview Waiver</Button></div>
              </CardBody>
            </Card>
          }))}/>}
      <Button color='success' onClick={() => this.editWaiver({})}><Add/> New Waiver</Button>
    </div>);
  }
}

export default withSession(EditWaivers);

export const EditWaiversInput = withSession((props: any) =>
    <FormDataContext.Consumer>{({data, onChange}: any) =>
        <EditWaivers {...props} waivers={data[props.id]}
                     onChange={(newWaivers => onChange({...data, [props.id]: newWaivers}))}/>
    }</FormDataContext.Consumer>
);

function offsetFromTop(el: HTMLElement) {
  let offsetTop = 0;
  do {
    if (!isNaN(el.offsetTop)) {
      offsetTop += el.offsetTop;
    }
    if (!el.offsetParent) {
      break;
    } else {
      el = el.offsetParent as HTMLElement;
    }
  } while (!!el);
  return offsetTop;
}
