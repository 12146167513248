import React, {Component} from 'react';
import {Session} from './app/types';
import {withSession} from '../session_context';
import {HouseholdMemberSearchResult, PaymentAdjustment, PaymentAdjustmentCondition} from '../services/backend-client';
import Spinner from './spinner';
import Button from '../../vendor/components/CustomButtons/Button';
import Table from '../../vendor/components/Table/Table';
import {Add, Clear, Edit, FileCopy} from '@material-ui/icons';
import {
  current4HYear,
  downloadAsCSV,
  formatCurrent4HYear,
  formatMoney,
  pluralize,
  uniq
} from '../shared/shared_helpers';
import {confirmModal, HelpModal, runModalForm} from './Modal/Modal';
import GridContainer from '../../vendor/components/Grid/GridContainer';
import GridItem from '../../vendor/components/Grid/GridItem';
import CustomFormInput from './form_data/custom_form_input';
import {FormDataContext, FormListEditor} from './form_data';
import CustomTextField from './form_data/custom_text_field';
import {Tooltip} from '@material-ui/core';
import CustomSelectFormInput from './form_data/custom_select_form_input';
import {CountySelectFormData} from './region_select/county_select';
import {ClubSelectFormData} from './region_select/club_select';
import {computeRecordBookTypes, recordBookTypesForState} from '../../record_books/record_book_types';
import TransferListFormData from './form_data/transferlist_form_data';
import DateTimeFormInput from './form_data/date_time_picker_form_input';
import SingleCheckbox from './form_data/single_checkbox';
import AutoComplete from './autocomplete/AutoComplete';
import CustomDropdown from "../../vendor/components/CustomDropdown/CustomDropdown";
import {type} from "os";
import {CloudDownload} from "../../mvc/react/ReactIcons";
import CardHeader from "../../vendor/components/Card/CardHeader";

//Known backend pages used:
//* PaymentAdjustment.java (class)
//* PaymentAdjustmentRepository.java
//* PaymentAdjustmentRepositoryTest.java
//* PaymentAdjustmentResource.java
//**********************************

interface Props {
  session: Session
  defaultCountyId?: number
  // Directly Managed props
  value?: PaymentAdjustment[]
  onChange?: (newValue: PaymentAdjustment[]) => void
  // Non-managed props
  loadAdjustments?: (opts: { showAll?: boolean, queryStr?: string }) => Promise<PaymentAdjustment[]>
  onAdd?: (newAdjustment: PaymentAdjustment) => Promise<PaymentAdjustment | undefined>
  onUpdate?: (updatedAdjustment: PaymentAdjustment) => Promise<void>
  onRemove?: (removedAdjustment: PaymentAdjustment) => Promise<void>
  showAll?: boolean // Show all adjustments
  queryStr?: string // Search Bar Variable
}

interface State {
  loading?: boolean
  adjustments: PaymentAdjustment[]
  projectTypes?: string[]
  year?: number | 'all',
  sortOption?: string
}

interface HouseholdMemberCondition extends PaymentAdjustmentCondition {
  householdMembers: { accountId: number, householdMemberId: number }[]
}

class PaymentAdjustmentsTable extends Component<Props, State> {
  async componentDidMount() {
    const {value, loadAdjustments, showAll, queryStr, session} = this.props;
    if (!value && !!loadAdjustments) {
      this.setState({loading: true});
      const adjustments = await loadAdjustments({showAll, queryStr});
      this.setState({adjustments, loading: false});
    }
    const sortOptionDefault = session?.state?.id === 'US-UT' ? 'descriptionAsc' : 'createdAtAsc';
    const projectTypesObj = computeRecordBookTypes(await recordBookTypesForState(this.props.session.state?.id, this.props.session.orgId)) as any,
        projectTypes = uniq(Object.keys(projectTypesObj).map(primary => Object.keys(projectTypesObj[primary].subTypes).map(
            (sub: string) => primary + (sub ? ('/' + sub) : '')))
            .reduce((a, b) => a.concat(b), [])
            .concat(this.props.session.settings?.state?.enrollmentProjectTypes || [])
            .concat(this.props.session.settings?.county?.countyProjectTypes || []).sort());
    this.setState({projectTypes, year: "all", sortOption: sortOptionDefault});
  }

  getConditionView(condition: PaymentAdjustmentCondition) {
    if (condition.type === 'County') {
      // if (this.props?.defaultCountyId) {
      //     return <div/>;
      // }
      return <CountySelectFormData id="countyId" stateId={this.props.session.state?.id}/>;
    } else if (condition.type === 'Club') {
      return <GridItem>
        <ClubSelectFormData id="clubId"
                            stateId={this.props.session.state?.id}
                            countyId={this.props?.defaultCountyId}
                            orgId={this.props.session.orgId}/>
        {condition.type === 'Club' ?
            <GridItem xs={11} sm={6}><SingleCheckbox id="isClubPrimary"
                                                     labelText="Is Club Primary"/></GridItem> : ''}
      </GridItem>
    } else if (condition.type === 'HouseholdMember') {
      return <FormListEditor prop="householdMembers" name="Individual"
                             childrenMapper={(hm: any, index: number, onRemove: () => void) => {
                               return <>
                                 <GridItem xs={11}>
                                   <FormDataContext.Consumer>{
                                     ({data, onChange}: { data: any, onChange: (newValue: any) => void }) => {
                                       return <AutoComplete<HouseholdMemberSearchResult>
                                           label="Target Individual"
                                           value={data as HouseholdMemberSearchResult}
                                           onChange={value => onChange({
                                             ...value,
                                             householdMemberId: value?.id
                                           })}
                                           compareOptions={(a, b) => a.id == b.id && a.accountId === b.accountId}
                                           getOptionLabel={option => option.firstName ? `${option.firstName} ${option.lastName}` : ''}
                                           renderOption={option => `${option.firstName} ${option.lastName} - ${option.email}`}
                                           loadOptions={async (query: string) => {
                                             return await this.props.session.backendClient.searchHouseholdMembers({
                                               q: query,
                                               orgId: this.props.session.orgId,
                                               countyId: this.props.defaultCountyId,
                                               stateId: this.props.session.state?.id
                                             });
                                           }}/>;
                                     }
                                   }</FormDataContext.Consumer>
                                 </GridItem>
                                 <GridItem xs={1}>
                                   <Tooltip title="Remove Individual" placement="top">
                                     <Button color="danger" simple justIcon
                                             onClick={onRemove}><Clear/></Button>
                                   </Tooltip>
                                 </GridItem>
                               </>;
                             }} containerProps={{}}/>;
    } else if (condition.type === 'ProjectType') {

      return <div>
        <TransferListFormData id="projectTypes" vertical choices={this.state?.projectTypes}/>
        <SingleCheckbox id="applyToAllProjects" label="Apply to each project type individually"/>
      </div>;
    } else if (condition.type === 'MemberType') {
      return <CustomSelectFormInput id="targetMemberType" label="Target Type" options={[
        {name: this.props.session.settings.state.clubMemberLabel, id: 'ClubMember'},
        {name: 'All ' + pluralize(this.props.session.settings.state.volunteerLabel), id: 'Volunteer'},
        {name: 'New ' + this.props.session.settings.state.volunteerLabel, id: 'New Volunteer'},
        {name: 'Returning ' + this.props.session.settings.state.volunteerLabel, id: 'Returning Volunteer'}
      ]}/>;
    } else if (condition.type === 'DateRange') {
      return <GridContainer>
        <GridItem xs={6}>
          <DateTimeFormInput label="Start Date" id="targetStartDate" dateFormat/>
        </GridItem>
        <GridItem xs={6}>
          <DateTimeFormInput label="End Date" id="targetEndDate" dateFormat/>
        </GridItem>
      </GridContainer>;
    }
    return <div/>;
  }

  async editPaymentAdjustment(title: string, paymentAdjustment: Partial<PaymentAdjustment>, save: (a: PaymentAdjustment, old: PaymentAdjustment) => Promise<void>) {
    const {session} = this.props;
    const YEAR_OPTIONS = Array(4).fill(0).map((e, i) => ({
      name: formatCurrent4HYear(session.settings.state,
          (current4HYear(session.settings.state) + 1) - i), id: (current4HYear(session.settings.state) + 1) - i
    }));
    // Check if conditions have household members, load in the data
    paymentAdjustment.conditions = await Promise.all((paymentAdjustment.conditions || []).map(async (c: PaymentAdjustmentCondition) => c?.type === 'HouseholdMember' ? ({
      ...c,
      householdMembers: await Promise.all((c as HouseholdMemberCondition).householdMembers.map(async (hm) =>
          hm.householdMemberId
              ? this.props.session.backendClient.findHouseholdMember({id: hm.householdMemberId}).then(hm => ({
                ...hm,
                householdMemberId: hm.id
              }), () => null)
              : this.props.session.backendClient.findAccount({id: hm.accountId}).then(a => ({
                ...a,
                accountId: a.id
              }), () => null)))
          .then(members => members.filter(c => Boolean(c)))
    }) : c));

    await runModalForm(({resolve, reject}: any) => {
      return <GridContainer>
        <GridItem xs={12}>Negative Values indicate a discount, while positive values indicate a price
          increase</GridItem>
        <GridItem xs={12}><CustomFormInput id="amount" labelText="Amount"
                                           startAdornment={<span
                                               style={{marginRight: '5px'}}>$</span>}/></GridItem>
        <GridItem xs={12}><CustomFormInput id="description"
                                           labelText="Checkout Line Item Description"/></GridItem>

        <GridItem md={6} xs={12}>
          <CustomSelectFormInput id="feeAdjustmentStartYear" label="Fee Adjustment 4-H Start Year"
                                 options={YEAR_OPTIONS}
          />
        </GridItem>
        <GridItem md={6} xs={12}>
          <CustomSelectFormInput id="feeAdjustmentEndYear" label="Fee Adjustment 4-H End Year"
                                 options={YEAR_OPTIONS}
          />
        </GridItem>
        <GridItem md={12} xs={12}>
          <p style={{fontSize: 12}}><b>
            The settings for "Fee Adjustment 4-H Start and End Year" DO NOT set the fee adjustment to
            only apply for the year(s) selected at the moment. It is ONLY for filtering purposes.
            <br/><br/>

            If you want your fee adjustment to apply for a specific date range, please set a "Date Range"
            condition below .
          </b></p>
        </GridItem>

        <GridItem xs={12}><CustomTextField id="notes" labelText="Notes"/></GridItem>
        <GridItem xs={12}>
          In order for a condition to apply to an individual, they must meet all of the conditions you add
          here.<br/><br/>
          <i>
            Please make sure if you want a fee adjustment to only apply to a member or volunteer that you
            set a condition for that.
          </i>
        </GridItem>
        <GridItem xs={12}>
          <FormListEditor prop="conditions" name="Condition"
                          childrenMapper={(c: PaymentAdjustmentCondition, index: number, onRemove: () => void) => {
                            return <>
                              <GridItem xs={1}>
                                <Tooltip title="Delete Condition" placement="top">
                                  <Button color="danger" simple justIcon
                                          onClick={onRemove}><Clear/></Button>
                                </Tooltip>
                              </GridItem>
                              <GridItem xs={11}>
                                <CustomSelectFormInput id="type" label="Condition" options={[
                                  {
                                    id: 'County',
                                    name: `Apply to ${session.settings.state.countyLabel}`
                                  },
                                  {id: 'Club', name: 'Apply to Club'},
                                  {id: 'HouseholdMember', name: 'Apply to Specific Individual(s)'},
                                  {id: 'ProjectType', name: 'Apply to a Set of Project Types'},
                                  {
                                    id: 'MemberType',
                                    name: `Apply only to ${session.settings.state.clubMemberLabel} or ${session.settings.state.volunteerLabel}`
                                  },
                                  {id: 'DateRange', name: 'Apply to Specific Date Range'}
                                ]}/>
                                {this.getConditionView(c)}
                              </GridItem>
                            </>;
                          }} containerProps={{}}/>
        </GridItem>
      </GridContainer>;
    }, {
      title, session,
      submitLabel: 'Save',
      initialState: paymentAdjustment,
      onSubmit: async (newAdjustment: PaymentAdjustment) => {

        const errors: string[] = [];
        if (!newAdjustment.amount) {
          errors.push('Amount must be a non-zero value');
        }
        if (!newAdjustment.conditions.length) {
          errors.push('At least one condition must be selected');
        }
        if (newAdjustment.conditions.some((c: PaymentAdjustmentCondition) => !c.type)) {
          errors.push('Condition type required');
        }
        if (!(newAdjustment.description || '').trim()) {
          errors.push('Line Item Description required');
        }

        // Test to see if default countyID is set, and put that countyID on any "county" conditions
        // if (this.props.defaultCountyId) {
        //     newAdjustment.conditions = newAdjustment.conditions
        //         .map(c => c.type === 'County'
        //             //? {...c, countyId: this.props.defaultCountyId}
        //             ? c
        //             : c
        //         );
        // }
        if (this.props.defaultCountyId) {
          newAdjustment.conditions = newAdjustment.conditions.map(c => c);
        }

        // filter out any null individuals on the household members conditions
        newAdjustment.conditions = newAdjustment.conditions.map(condition => {
          if (condition.type === 'HouseholdMember') {
            (condition as HouseholdMemberCondition).householdMembers = ((condition as HouseholdMemberCondition).householdMembers || [])
                .filter((member: { accountId: number, householdMemberId: number }) => member.accountId != null);
          }

          return condition;
        });

        if (errors.length) {
          throw new Error(errors.join(', '));
        } else {
          await save(newAdjustment, paymentAdjustment as PaymentAdjustment);
        }
      }
    }).catch(() => {
    });
  }

  async exportAsCSV() {

    const {session: {account}, value} = this.props;
    const {adjustments} = this.state || {};
    let feeAdjustments = (value || adjustments);

    if (this.state.year !== "all") {
      feeAdjustments = feeAdjustments.filter(fa => fa.feeAdjustmentEndYear === this.state.year || fa.feeAdjustmentStartYear === this.state.year)
    }

    feeAdjustments = feeAdjustments.sort((adjustmentA, adjustmentB): any => {
      if (this?.state?.sortOption === 'descriptionAsc') {
        return adjustmentA.description.localeCompare(adjustmentB.description)
      }
      if (this?.state?.sortOption === 'descriptionDesc') {
        return adjustmentB.description.localeCompare(adjustmentA.description)
      }
      if (this?.state?.sortOption === 'createdAtAsc') {
        return adjustmentA.createdAt.localeCompare(adjustmentB.createdAt)
      }
      if (this?.state?.sortOption === 'createdAtDesc') {
        return adjustmentB.createdAt.localeCompare(adjustmentA.createdAt)
      }
    });

    let csvTableHead;
    let csvData;

    // The Region column will be visible if the accountType is 'StateAdmin'.
    // The Region column will not be visible for county admins.

    csvTableHead =
        account?.accountType === 'StateAdmin' ? ['Description', 'Region', 'Notes', 'Amount',]
            : ['Description', 'Notes', 'Amount',];

    csvData = account?.accountType === 'StateAdmin' ? feeAdjustments.map(a => [
          a.description,
          a.region,
          a.notes,
          formatMoney(a.amount),
        ])
        : feeAdjustments.map(a => [
          a.description,
          a.notes,
          formatMoney(a.amount),
        ]);

    // console.log("CSV DATA", csvData);

    await downloadAsCSV([csvTableHead.slice(0, 4), ...csvData.map(row => row.slice(0, 4))], `Payment Adjustments.csv`);

  }

  renderTable() {
    // The Region column will be visible if the accountType is 'StateAdmin'.
    // The Region column will not be visible for county admins.
    const {value, onChange, onAdd, onUpdate, onRemove, session: {account}} = this.props,
        {adjustments, year, sortOption} = this.state || {};
    const pageLink = window.location.href || ''; //THIS VAR GETS THE LINK OF THE PAGE
    const isEvent = pageLink?.includes('events'); //THIS VAR TESTS IF "events" IS IN THE PAGE
    // LINK SO WE CAN TELL IF THEY ARE WORKING ON FEE ADJUSTMENTS FOR AN EVENT OR FOR ENROLLMENT AND IF THEY
    // ARE WORKING ON FEE ADJUSTMENTS FOR EVENTS THEN DON'T SHOW REGION AND ALSO ALLOW THEM TO EDIT THE FEE
    // ADJUSTMENTS REGARDLESS OF REGION

    console.log("VALUE", value);
    console.log("ADJUSTMENTS", adjustments);
    // const adjustmentsSorted = adjustmetns .sort((adjustmentA, adjustmentB): any => {
    //   if (sortOption === 'descriptionAsc') {
    //     return (adjustmentA?.description || '').localeCompare((adjustmentB?.description || ''))
    //   }
    //   if (sortOption === 'descriptionDesc') {
    //     return (adjustmentB?.description || '').localeCompare((adjustmentA?.description || ''))
    //   }
    //   if (sortOption === 'createdAtAsc') {
    //     return (adjustmentA?.createdAt || '').localeCompare((adjustmentB?.createdAt || ''))
    //   }
    //   if (sortOption === 'createdAtDesc') {
    //     return (adjustmentB?.createdAt || '').localeCompare((adjustmentA?.createdAt || ''))
    //   }
    // })


    function sortData(adjustmentA: PaymentAdjustment, adjustmentB: PaymentAdjustment) {
      if (sortOption === 'descriptionAsc') {
        return (adjustmentA?.description || '').localeCompare((adjustmentB?.description || ''))
      }
      if (sortOption === 'descriptionDesc') {
        return (adjustmentB?.description || '').localeCompare((adjustmentA?.description || ''))
      }
      if (sortOption === 'createdAtAsc') {
        return (adjustmentA?.createdAt || '').localeCompare((adjustmentB?.createdAt || ''))
      }
      if (sortOption === 'createdAtDesc') {
        return (adjustmentB?.createdAt || '').localeCompare((adjustmentA?.createdAt || ''))
      }
      return 0
    }


    const tableData = (value || adjustments || []).filter(
        adjustment => {
          // console.log("ADJUSTMENT", adjustment)
          return !year || year === 'all' || (adjustment.feeAdjustmentStartYear <= year && adjustment.feeAdjustmentEndYear >= year)
        }
    )
        .sort(sortData)
        .map((a) => [
          formatMoney(a.amount),
          a.description || '',
          //account?.accountType === 'StateAdmin' ? a.region : null,
          isEvent ? null : a.region,
          a.notes,
          <div style={{display: 'flex', justifyContent: 'space-evenly'}}>

            {((a.region === 'State' && account?.accountType === 'StateAdmin') || isEvent) ?
                <Tooltip title="Edit Adjustment" placement="top">
                  <Button justIcon simple color="info" onClick={() =>
                      this.editPaymentAdjustment('Edit Adjustment', a, async (updatedAdjustment, original) => {
                        if (onChange) {
                          let newValue = value || [];
                          const i = (value || []).indexOf(original);
                          newValue[i] = updatedAdjustment;
                          onChange(newValue);
                        } else {
                          await onUpdate?.(updatedAdjustment);
                          let newAdjustments = adjustments;
                          const i = (adjustments || []).indexOf(original);
                          newAdjustments[i] = updatedAdjustment;
                          this.setState({adjustments: newAdjustments});
                        }
                      })}>
                    <Edit/>
                  </Button>
                </Tooltip> :
                a.region !== 'State' ? <Tooltip title="Edit Adjustment" placement="top">
                  <Button justIcon simple color="info" onClick={() =>
                      this.editPaymentAdjustment('Edit Adjustment', a, async (updatedAdjustment, original) => {
                        if (onChange) {
                          let newValue = value || [];
                          const i = (value || []).indexOf(original);
                          newValue[i] = updatedAdjustment;
                          onChange(newValue);
                        } else {
                          await onUpdate?.(updatedAdjustment);
                          let newAdjustments = adjustments;
                          const i = (adjustments || []).indexOf(original);
                          newAdjustments[i] = updatedAdjustment;
                          this.setState({adjustments: newAdjustments});
                        }
                      })}>
                    <Edit/>
                  </Button>
                </Tooltip> : ''
            }

            {((a.region === 'State' && account?.accountType === 'StateAdmin') || isEvent) ?
                <Tooltip title="Duplicate Adjustment" placement="top">
                  <Button justIcon simple color="github"
                          onClick={() =>
                              this.editPaymentAdjustment('Duplicate Adjustment', {
                                ...a,
                                id: undefined
                              }, async (copiedAdjustment) => {
                                if (onChange) {
                                  onChange((value || []).concat(copiedAdjustment));
                                } else {
                                  copiedAdjustment = await onAdd?.(copiedAdjustment) || copiedAdjustment;
                                  this.setState({adjustments: adjustments.concat(copiedAdjustment)});
                                }
                              })}>
                    <FileCopy/>
                  </Button>
                </Tooltip> :
                a.region !== 'State' ? <Tooltip title="Duplicate Adjustment" placement="top">
                  <Button justIcon simple color="github"
                          onClick={() =>
                              this.editPaymentAdjustment('Duplicate Adjustment', {
                                ...a,
                                id: undefined
                              }, async (copiedAdjustment) => {
                                if (onChange) {
                                  onChange((value || []).concat(copiedAdjustment));
                                } else {
                                  copiedAdjustment = await onAdd?.(copiedAdjustment) || copiedAdjustment;
                                  this.setState({adjustments: adjustments.concat(copiedAdjustment)});
                                }
                              })}>
                    <FileCopy/>
                  </Button>
                </Tooltip> : ''

            }


            {((a.region === 'State' && account?.accountType === 'StateAdmin') || isEvent) ?
                <Tooltip title="Delete Adjustment" placement="top">
                  <Button justIcon simple color="danger"
                          onClick={async () => {
                            if (await confirmModal('Are you sure?', 'Remove Payment Adjustment?', 'danger')) {
                              if (onChange) {
                                onChange((value || []).filter((paymentAdjustment) => a !== paymentAdjustment));
                              } else {
                                onRemove?.(a);
                                this.setState({adjustments: adjustments.filter((paymentAdjustment) => a !== paymentAdjustment)});
                              }
                            }
                          }}>
                    <Clear/>
                  </Button>
                </Tooltip> :

                a.region !== 'State' ? <Tooltip title="Delete Adjustment" placement="top">
                  <Button justIcon simple color="danger"
                          onClick={async () => {
                            if (await confirmModal('Are you sure?', 'Remove Payment Adjustment?', 'danger')) {
                              if (onChange) {
                                onChange((value || []).filter((paymentAdjustment) => a !== paymentAdjustment));
                              } else {
                                onRemove?.(a);
                                this.setState({adjustments: adjustments.filter((paymentAdjustment) => a !== paymentAdjustment)});
                              }
                            }
                          }}>
                    <Clear/>
                  </Button>
                </Tooltip> : ''

            }
          </div>
        ]);
    return <Table
        tableHead={isEvent ? ['Amount', 'Description', '', 'Notes', ''] : ['Amount', 'Description', 'Region', 'Notes', '']}
        tableData={tableData}/>;
  }

  render() {
    const {value = [], onChange, onAdd, session} = this.props;
    const {loading, adjustments = []} = this.state || {};
    const YEAR_OPTIONS = (Array(4).fill(1).map((e, i) => ({
      name: formatCurrent4HYear(session.settings.state,
          (current4HYear(session.settings.state) + 1) - i), id: (current4HYear(session.settings.state) + 1) - i
    })) as { id: number | 'all', name: string }[]).concat([{
      "id": "all",
      "name": "All"
    }]).sort((a, b) => b.name.localeCompare(a.name));

    const SORT_OPTIONS = [
      {id: 'createdAtAsc', name: 'Created At (Asc)'},
      {id: 'createdAtDesc', name: 'Created At (Desc)'},
      {id: 'descriptionAsc', name: 'Description (Asc)'},
      {id: 'descriptionDesc', name: 'Description (Desc)'},
    ]

    if (loading) {
      return <Spinner/>;
    }

    return <div>
      <GridContainer>
        <GridItem md={3} xs={12}>
          <Button color="success" onClick={() =>
              this.editPaymentAdjustment('Add Payment Adjustment', {
                amount: 0, notes: '', conditions: []
              }, async (newAdjustment: PaymentAdjustment) => {
                if (onChange) {
                  onChange(value?.concat(newAdjustment));
                } else {
                  newAdjustment = await onAdd?.(newAdjustment) || newAdjustment;
                  this.setState({adjustments: adjustments.concat(newAdjustment)});
                }
              })}>
            <Add/> Add Payment Adjustment
          </Button>
        </GridItem>

        <GridItem md={2} xs={12} style={{marginTop: -10}}>
          <CustomSelectFormInput id={"feeAdjustmentYearSelection"} label={"Select 4-H Year of Fee Adjustment"}
                                 options={YEAR_OPTIONS}
                                 value={this.state?.year}
                                 onChange={(newYear: number | 'all') => this.setState({year: newYear})}
          />
        </GridItem>
        <GridItem md={3} xs={12} style={{marginTop: -10}}>
          <CustomSelectFormInput id={"feeAdjustmentSortOptions"} label={"Sort Fee Adjustments By"}
                                 options={SORT_OPTIONS}
                                 value={this.state?.sortOption}
                                 onChange={(newSortOption: any) => this.setState({sortOption: newSortOption})}
          />
        </GridItem>

        <GridItem md={4} xs={12} style={{marginTop: -10}}>
          <Button simple color="primary" style={{float: 'right'}}
                  onClick={this.exportAsCSV.bind(this)}>
            <CloudDownload style={{margin: '0 5px 5px 0'}}/>
            Export CSV
          </Button>
        </GridItem>


        <GridItem md={12} lg={12}>
          {this.renderTable()}
        </GridItem>
      </GridContainer>
    </div>;
  }


  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const {loadAdjustments, showAll, queryStr} = this.props;

    if (prevProps.showAll !== this.props.showAll && loadAdjustments) {
      this.setState({loading: true});
      const adjustments = await loadAdjustments({showAll, queryStr});
      this.setState({adjustments, loading: false});
    }
    if (prevProps.queryStr !== this.props.queryStr && loadAdjustments) {
      this.setState({loading: true});
      const adjustments = await loadAdjustments({showAll, queryStr});
      this.setState({adjustments, loading: false});
    }
  }
}

export default withSession(PaymentAdjustmentsTable);