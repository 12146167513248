import React, {Component} from 'react';
import {withSession} from "../session_context";
import {Session} from "./app/types";
import CustomInput from "../../vendor/components/CustomInput/CustomInput";
import Button from "../../vendor/components/CustomButtons/Button";
import {Attachment, Close} from "@material-ui/icons";
import {LinearProgress, Tooltip} from "@material-ui/core";
import {extractError} from "../shared/shared_helpers";
import {runSnackbarAlert} from "./Modal/Modal";

interface Props {
  session: Session
  accept: string[]
  onChange: (url: string | undefined) => void
  value: string
  labelText?: string
  error?: boolean
  disabled?: boolean
  helperText?: string,
  noRemove?: boolean
}

class FileInput extends Component<Props, { loading?: boolean, error?: string }> {

  async addFile(file: any) {
    const {session: {backendClient, account}, onChange, accept} = this.props;
    if(!accept.some(a => a.includes('*')) && !accept.includes(file.type.toLowerCase())) {
      this.setState({error: `Invalid file type ${file.type} selected, please choose ${
        accept.length === 1 ? `a ${accept[0]} file` : `a file with one of the following types ${accept.join(', ')}`}`});
    } else {
      this.setState({loading: true, error: undefined});
      try {
        const {url} = await backendClient.createUpload({accountId: account?.id || 0 as number}, {file});
        onChange((process.env.REACT_APP_BACKEND?.replace('/api', '') || document.location.origin) + url);
      } catch (e) {
        runSnackbarAlert({message: 'Error: ' + await extractError(e, 'Error while uploading file'), color: 'danger'});
        console.error(await extractError(e, 'Error while uploading file'))
      } finally {
        this.setState({loading: false});
      }
    }
  }

  async removeFile(url: string) {
    const {session: {backendClient}, onChange} = this.props;
    this.setState({loading: true});
    try {
      await backendClient.deleteUpload({path: url});
      onChange(undefined);
    } catch (e) {
      runSnackbarAlert(await extractError(e, 'Error while deleting file upload'));
      console.error(await extractError(e, 'Error while deleting file upload'))
    } finally {
      this.setState({loading: false});
    }
  }

  render() {
    const {accept, value: url, labelText, error, disabled, helperText, noRemove} = this.props;
    const {loading, error: stateError} = this.state || {};

    if (loading) {
      return <LinearProgress/>
    }

    if (url) {
      return <CustomInput
        labelText={labelText}
        formControlProps={{fullWidth: true}}
        error={error || !!stateError}
        helperText={stateError || helperText}
        inputProps={{
          value: url.replace(/.*\/\d{4}-\d{2}-\d{2}_\d{2}-\d{2}-\d{2}_/, ''),
          startAdornment: <Tooltip title='View File' placement='top'>
            <Button justIcon simple onClick={() => window.open(url, '_blank')}><Attachment/></Button>
          </Tooltip>,
          endAdornment: noRemove || disabled ? undefined :
            <Tooltip title='Delete File' placement='top'><Button
              justIcon simple
              onClick={this.removeFile.bind(this, url)}>
              <Close/>
            </Button></Tooltip>,
          disabled: true
        }}/>
    }

    return <CustomInput
      labelText={labelText}
      labelProps={{shrink: true}}
      formControlProps={{fullWidth: true}}
      helperText={stateError || helperText}
      error={error || !!stateError}
      inputProps={{
        type: 'file',
        onChange: (e: any) => this.addFile(e.target.files[0]),
        disabled: disabled,
        inputProps: {accept: accept.join(", ")}
      }}/>
  }
}

export default withSession(FileInput);