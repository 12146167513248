import React, { Component } from 'react';
import { grayColor } from '../../../vendor/assets/jss/material-dashboard-pro-react';
import Button from '../../../vendor/components/CustomButtons/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { Cached } from '@material-ui/icons';

const styles = {
  banner: {
    zIndex: 99999999,
    position: 'fixed',
    left: 0,
    height: 68,
    paddingTop: 8,
    width: '100%',
    backgroundColor: grayColor[7],
    textAlign: 'center',
    transition: 'top 1s',
  },
  bannerHide: {
    top: -68,
  },
  bannerShow: {
    top: 0,
  },
};

class ReadyToReload extends Component {
  componentDidMount() {
    window.__on_pwa_ready_to_reload__ = () =>
        this.setState({ready: true});
    if (window.__pwa_ready_to_reload__) {
      window.__on_pwa_ready_to_reload__();
    }
  }

  componentWillUnmount() {
    delete window.__on_pwa_ready_to_reload__;
  }

  render() {
    const {classes} = this.props;
    const {ready} = this.state || {};
    return (
        <div className={classes.banner + ' ' +
        (ready ? classes.bannerShow : classes.bannerHide)}>
          <Button color="rose" onClick={() => window.location.reload()}>
            <Cached/> Update Ready - Click to Reload
          </Button>
        </div>
    );
  }
}

export default withStyles(styles)(ReadyToReload);