import React, { Component } from 'react';
import Spinner from './spinner';
import Paginations from '../../vendor/components/Pagination/Pagination';


interface Props<T> {
    renderPage(params: {
        pageData: Array<T>, pageIndex: number, reloadPage(pageIndex?: number): Promise<void>
    }): React.ReactNode;

    loadPageData(pageIndex: number, pageSize: number, lastElement?: T): Promise<Array<T>>;

    initialPage?: number;
    pageSize?: number;
}

interface State<T> {
    data?: Array<T>;
    pageIndex: number;
    pageSize: number;
    lastPage?: number;
    loading: boolean;
}

class WithPaging<T> extends Component<Props<T>, State<T>> {
    constructor(props: Readonly<Props<T>>) {
        super(props);
        this.state = {pageIndex: 0, pageSize: this.props.pageSize || 5, loading: false};
        this.loadPage = this.loadPage.bind(this);
    }

    async componentDidMount() {
        const {initialPage = 0} = this.props;
        await this.reloadPage(initialPage);
    }

    async componentDidUpdate(prevProps: Props<T>) {
        if (prevProps.loadPageData !== this.props.loadPageData) {
            const {initialPage = 0} = this.props;
            await this.reloadPage(initialPage);
        }
    }

    async loadPage(newPageIndex: number) {
        const {loadPageData} = this.props,
            {data, pageSize, lastPage} = this.state,
            lastOffset = (newPageIndex + 1) * pageSize;

        this.setState({loading: true});

        try {
            if ((!data || lastOffset >= data.length) && newPageIndex !== lastPage) {
                const newData = await loadPageData(newPageIndex, pageSize, data ? data[data.length - 1] : data);
                if (newData.length === 0) {
                    const lastPage = Math.floor((data || []).length / pageSize); // Math.floor gives us a 0-based index
                    this.setState({pageIndex: Math.min(newPageIndex, lastPage), lastPage});
                } else {
                    this.setState({
                        data: (data || []).concat(newData),
                        pageIndex: newPageIndex,
                        lastPage: newData.length < pageSize ? newPageIndex : undefined
                    });
                }
            } else {
                this.setState({pageIndex: newPageIndex});
            }
        } finally {
            this.setState({loading: false});
        }
    }

    async reloadPage(pageIndex: number) {
        const {loadPageData} = this.props,
            {pageSize} = this.state;
        this.setState({
            data: await loadPageData(0, (pageIndex + 1) * pageSize),
            pageIndex, lastPage: undefined
        });
    }

    render() {
        const {renderPage} = this.props,
            {data, pageIndex, pageSize, lastPage, loading} = this.state;

        if (!data) {
            return <Spinner/>;
        }

        const pageData = data.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize),
            isLastPage = lastPage === undefined ? false : pageIndex >= lastPage;

        return (
            <div>
                {loading ? <div style={{minHeight: (pageSize + 1) * 50}}><Spinner/></div> : renderPage({
                    pageData, pageIndex, reloadPage: this.reloadPage.bind(this)
                })}
                <Paginations
                    pages={[{
                        disabled: pageIndex <= 0 || loading,
                        text: 'PREV', onClick: () => {
                            if (pageIndex > 0) {
                                return this.loadPage(pageIndex - 1);
                            }
                        },
                    }, {
                        disabled: false,
                        active: true,
                        text: pageIndex + 1,
                        onClick: () => {
                        }
                    }, {
                        disabled: isLastPage || loading,
                        text: 'NEXT', onClick: () => {
                            if (!isLastPage) {
                                return this.loadPage(pageIndex + 1)
                            }
                        }
                    }] as any}
                    color="info"
                />
            </div>
        );
    }
}

export default WithPaging;