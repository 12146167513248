import React, { ReactNode, Suspense } from 'react';
import { createBrowserHistory, History } from 'history';
import { Link, Route, Router, Switch } from 'react-router-dom';
import SessionContext from '../../session_context';
import Spinner from '../spinner';
import '../../../vendor/assets/scss/material-dashboard-pro-react.scss';
import SessionService from '../../services/session_service';
import { alertModal } from '../Modal/Modal';
import AnalyticsSocketClient from '../../services/analytics_socket_client';
import AccountView from '../account_view';
import PasswordReset from '../../pages/password_reset_confirm';
import HelpCenterView from '../../components/help_center_view';
import './styles.css';
import ReadyToReload from './ready_to_reload';
import GridContainer from '../../../vendor/components/Grid/GridContainer';
import GridItem from '../../../vendor/components/Grid/GridItem';
import Button from '../../../vendor/components/CustomButtons/Button';
import { ArrowBack } from '@material-ui/icons';
import GreenbrierSignup from '../../pages/greenbrier_signup';
import BackendClient from '../../services/backend-client';
import { Session } from './types';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ExternalEventRegistration from '../../../events/components/external_registration/external_event_registration';
import SSOLogin from '../SSOLogin';
import VerifyAccount from '../../pages/verify_account';

interface AppState {
  sessionContext: Session,
  history: History,
  landingPage: ReactNode
}

class App extends React.Component<{}, AppState> {
  constructor(props: {}) {
    super(props);
    const ORG_HOSTS: Record<string, string> = {
      '4-hshootingsportscollege.com': 'nss',
      'www.4-hshootingsportscollege.com': 'nss',
      'fsf.zsuite.org': 'fsf',
      '4h.zsuite.org': '4h',
      'ffa.zsuite.org': 'ffa',
      'zsuite.clover.academy': '4h',
      'app.clover.academy': 'ca_lms',
      'dev.zsuite.org': '4h',
      'dev-zsuite.us-east-2.elasticbeanstalk.com': '4h',
      'staging.zsuite.org': '4h',
      'lms.simplystem.com': 'ss',
      'sassr.zsuite.org': 'sassr',
      'uafext.zsuite.org': 'uafext',
      'okextension.online': 'ext',
    };
    const EXT_STATE_HOSTS: Record<string, string> = {
      'okextension.online': 'US-OK',
    };
    const initialState: AppState = {
      history: createBrowserHistory(),
      landingPage: undefined,
      sessionContext: {
        settings: undefined as any,
        backendClient: undefined as any,
        account: undefined as any,
        token: undefined as any,
        getSessionService: () => new SessionService(this.state.sessionContext),
        orgId: process.env.REACT_APP_ORG || ORG_HOSTS[window.location.host],
        extStateId: process.env.REACT_APP_ORG_STATE || EXT_STATE_HOSTS[window.location.host],
        loadingAccount: true,
        analyticsClient: new AnalyticsSocketClient(),
        scrollToTop: () => {
        },
        updateSession: (session: any) =>
            new Promise(res =>
                this.setState(({sessionContext}) => ({
                  sessionContext: Object.assign({}, sessionContext, session),
                }), res as any),
            ),
        resetSession: (newContext = {}): Promise<void> =>
            new Promise(res =>
                this.setState(({sessionContext}) => ({
                  sessionContext: {
                    ...initialState.sessionContext,
                    backendClient: sessionContext.backendClient,
                    loadingAccount: false, ...newContext,
                  },
                }), res as any),
            ),
      },
    };
    this.state = initialState;
  }

  async componentDidMount() {
    /*const analyticsClient = this.state.sessionContext.analyticsClient;
    await analyticsClient.connect().catch(() => {
    });*/
    // Send an empty message every minute to keep connection alive (a heartbeat)
    /*const heartBeatId = window.setInterval(() => {
      if (analyticsClient.isConnected()) {
        analyticsClient.send('');
      } else {
        window.clearInterval(heartBeatId);
      }
    }, 60000);*/

    this.idleLogout();
    const backendClient = new BackendClient(window.fetch.bind(window), process.env.REACT_APP_BACKEND || '/api');
    await new Promise(res => this.setState(({sessionContext}) => ({
      sessionContext:
          Object.assign({}, sessionContext,
              {backendClient}),
    }), res as any));

    await this.state.sessionContext.getSessionService()
        .reloadSession(undefined)
        .catch(() => {
        });

    await this.state.sessionContext.updateSession({
      loadingAccount: false,
    });

    /*// Do analytics stuff
    const account = this.state.sessionContext.account,
        currentUser = this.state.sessionContext.currentHouseholdMember ||
            account || {firstName: 'Unknown', lastName: 'Unknown'},
        history = this.state.history;
    if (account && account.id) {
        analyticsClient.send({
            action: 'setAccount',
            accountId: account.id,
            name: currentUser.firstName + ' ' + currentUser.lastName,
            page: window.location.pathname,
        });
    }

    history.listen((location) => {
        analyticsClient.send({
            action: 'setPage',
            page: location.pathname,
        });
    });*/

    /*        // If account is already logged in, pre-fill PureChat when ready
            // @ts-ignore
            if (account && window.purechatApi && window.purechatApi.on) {
                // @ts-ignore
                window.purechatApi.on('chatbox:ready', () =>
                    this.state.sessionContext.getSessionService()
                        .updatePureChatInfo(account,{},this.state.sessionContext));
            }*/

    this.setState({
      landingPage: await this.loadLandingPage(this.state.sessionContext.orgId),
    });
  }

  async logout() {
    const {sessionContext: session} = this.state;
    if (!session.account || !session.account.id) {
      return;
    }
    try {
      await session.getSessionService().logout();

      await alertModal('Session Timeout',
          'You have been logged out for inactivity');
    } catch (e) {
      console.error(e);
    }
    this.state.history.push('/');
  }

  idleLogout() {
    let t: number;
    const resetTimer = () => {
      clearTimeout(t);
      t = window.setTimeout(() => {
        if (window.location.pathname.startsWith('/clover-academy/')) {
          return resetTimer();
        } else {
          return this.logout();
        }
      }, process.env.REACT_APP_MAX_IDLE
          ? parseInt(process.env.REACT_APP_MAX_IDLE)
          : 3.6e+6 /* 60 minutes in milliseconds */);
    };
    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;  // catches touchscreen presses as well
    window.ontouchstart = resetTimer; // catches touchscreen swipes as well
    window.onclick = resetTimer;      // catches touchpad clicks as well
    window.onkeypress = resetTimer;
    window.addEventListener('scroll', resetTimer, true); // improved; see comments
  }

  async loadLandingPage(orgId: string) {
    if (orgId === 'ss') {
      return await import('../../pages/simply_stem_landing_page').then(
          m => m.default);
    }
    return await import('../../pages/login_page').then(m => m.default);
  }

  async loadHelpData(orgId: string) {
    if (orgId === 'ss') {
      return await import('../../pages/login_help/ss').then(m => m.default);
    }
    if (orgId === 'nss') {
      return await import('../../pages/login_help/nss').then(m => m.default);
    }
    if (orgId === 'ffa') {
      return await import('../../pages/login_help/ffa').then(m => m.default);
    }
    if (orgId === 'fsf') {
      return await import('../../pages/login_help/fsf').then(m => m.default);
    }
    return await import('../../pages/login_help/4h').then(m => m.default);
  }

  render() {
    const {
          sessionContext, history = undefined, sessionContext: {orgId} = {orgId: ''},
          landingPage: LandingPage = undefined,
        } = this.state || {}, {loadingAccount, account} = sessionContext || {},
        modifiedHistory = {
          push: (path: string) => {
            history && history.push(path);
            this.forceUpdate();
          },
        };

    if (loadingAccount || !LandingPage) {
      return <Spinner/>;
    }

    // @ts-ignore
    let view = <Switch>
      <Route path="/password-reset/:token" component={PasswordReset}/>
      <Route path="/external-event-registration/:eventId" component={ExternalEventRegistration}/>
      <Route path="/login-help"
             component={() => <GridContainer justify="center">
               <GridItem xs md={10} style={{marginTop: '2rem'}}>
                 <Button to="/" component={Link}
                         color="primary"><ArrowBack/> Back to Login</Button>
                 <HelpCenterView baseUrl="/login-help"
                                 loadHelpData={this.loadHelpData.bind(this, orgId)}/>
               </GridItem>
             </GridContainer>}/>
      <Route path="/login-sso/:profileId" component={SSOLogin}/>
      <Route path="/verify-account" component={VerifyAccount}/>
      <Route path="/" component={LandingPage as any}/>
    </Switch>;

    if (account && account.id) {
      view = <AccountView orgId={orgId} account={account}/>;
    }

    // Special case for password reset routes, using <Switch /> causes reloading loop
    if (window.location.pathname.startsWith('/password-reset/')) {
      let result;
      result = window.location.pathname.match(
          /\/password-reset\/(.*)$/);
      return <PasswordReset session={sessionContext}
                            history={modifiedHistory}
                            match={{params: {token: result ? result[1] : ''}}}/>;
    }

    // Special case for sign-up page
    if (window.location.pathname === '/signup') {
      return <GreenbrierSignup session={sessionContext}
                               history={modifiedHistory}/>;
    }
    if (window.location.pathname === '/verify-account') {
      return <SessionContext.Provider value={sessionContext}>
        <Router history={history as any}>
          <VerifyAccount/>
        </Router>
      </SessionContext.Provider>;
    }

    return <SessionContext.Provider value={sessionContext}>
      <ReadyToReload/>
      <Suspense fallback={<Spinner/>}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history as any}>{view}</Router>
        </MuiPickersUtilsProvider>
      </Suspense>
    </SessionContext.Provider>;
  }
}

export default App;
