import React, { Component } from 'react';
import AvatarUpload from './avatar_upload/avatar_upload';
import { withSession } from '../session_context';
import ImageUpload from './CustomUpload/ImageUpload';

class ImageInput extends Component {
  async onChange(newFile) {
    const {session: {backendClient, account}, onChange, asFile} = this.props;
    if (asFile) {
      onChange(newFile);
    } else {
      const {url} = await backendClient.createUpload({accountId: account.id},
          {file: newFile});
      onChange(url);
    }
  }

  render() {
    const {label, value, onChange, avatar, session, alt, onAltChange} = this.props;
    return (
        avatar ? <AvatarUpload label={label} url={value} onChange={onChange} session={session} alt={alt}
                               maxWidth={this.props.maxWidth} onUpload={this.onChange.bind(this)}/>
            : <ImageUpload label={label} url={value} onChange={onChange} maxWidth={this.props.maxWidth}
                           onUpload={this.onChange.bind(this)} alt={alt} onAltChange={onAltChange}/>

    );
  }
}

export default withSession(ImageInput);