import React, { useContext, useEffect, useState } from 'react';
import CardHeader from '../../vendor/components/Card/CardHeader';
import Card from '../../vendor/components/Card/Card';
import CardBody from '../../vendor/components/Card/CardBody';
import CardIcon from '../../vendor/components/Card/CardIcon';
import Check from '@material-ui/icons/Check';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import dashboardStyle from './dashboard/dashboardStyle';
import { FormData } from './form_data';
import CountySelect, { CountySelectFormData } from './region_select/county_select';
import SessionContext from '../session_context';
import { Session } from './app/types';
import CustomFormInput from './form_data/custom_form_input';
import Button from '../../vendor/components/CustomButtons/Button';
import ChevronRight from '@material-ui/icons/ChevronRight';
import SnackbarContent from '../../vendor/components/Snackbar/SnackbarContent';
import { Warning } from '@material-ui/icons';
import { extractError } from '../shared/shared_helpers';

type Props = { match: { params: { profileId: string } } }

const useClasses = makeStyles(dashboardStyle as any);

export default function SSOLogin({match: {params: {profileId}}}: Props) {
  const classes = useClasses(),
      [stateId, setStateId] = useState<string | undefined>(),
      [error, setError] = useState<string | undefined>(),
      {backendClient, getSessionService} = useContext(SessionContext) as Session,
      [formData, setFormData] = useState<any>({});

  useEffect(() => {
    backendClient.getSSOProfile({profileId}).then(profile => {
      const [firstName, lastName] = profile.name.split(' ');
      setFormData({...profile, firstName, lastName});
      setStateId(profile.stateId);
    });
  }, [backendClient, setFormData]);

  return (
      <Container maxWidth="sm">
        <Card>
          <CardHeader icon>
            <CardIcon color="success"><Check/></CardIcon>
            <h3 className={classes.cardIconTitle}>Confirm ZSuite Profile</h3>
          </CardHeader>
          <CardBody>
            <FormData data={formData} onChange={(newFormData: any) => setFormData({...formData, ...newFormData})}>
              <CustomFormInput id="firstName" labelText="First Name"/>
              <CustomFormInput id="lastName" labelText="Last Name"/>
              <CustomFormInput id="email" labelText="Email"/>
              <CustomFormInput id="phone" labelText="Phone"/>
              <CountySelectFormData id="countyId" stateId={stateId} fullWidth/>
            </FormData>
            {error ? <div style={{marginTop: '1rem'}}>
              <SnackbarContent message={error} close
                               onClose={() => setError(undefined)}
                               icon={Warning} color="danger"/></div> : null}
            <div style={{textAlign: 'right'}}>
              <Button color="primary" onClick={async () => {
                if(!formData.countyId) {
                  return setError('County is required');
                }
                try {
                  const response = await backendClient.createSSOAccount({}, {
                    profileId, firstName: formData.firstName, lastName: formData.lastName,
                    email: formData.email, phone: formData.phone, countyId: formData.countyId,
                  });
                  return getSessionService().reloadSession(response);
                } catch (e) {
                  setError(await extractError(e, 'Unknown Error'));
                }
              }}>Continue <ChevronRight/></Button>
            </div>
          </CardBody>
        </Card>
      </Container>
  );
}