import React, { useContext } from 'react';
import CardHeader from '../../vendor/components/Card/CardHeader';
import CardBody from '../../vendor/components/Card/CardBody';
import Card from '../../vendor/components/Card/Card';
import { makeStyles } from '@material-ui/core/styles';
import SessionContext from '../session_context';
import { Session } from '../components/app/types';
import CardIcon from '../../vendor/components/Card/CardIcon';
import Check from '@material-ui/icons/Check';
import CustomFormInput from '../components/form_data/custom_form_input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Email, Lock, Warning } from '@material-ui/icons';
import SnackbarContent from '../../vendor/components/Snackbar/SnackbarContent';
import CardFooter from '../../vendor/components/Card/CardFooter';
import LoadingButton from '../components/loading_button';
import Container from '@mui/material/Container';
// @ts-ignore
import zsuiteAndCloverLogo from '../shared/img/ZS_and_4-H_Combo.png';
import Typography from '@mui/material/Typography';
import { extractError, useQuery } from '../shared/shared_helpers';
import { useLocation } from 'react-router-dom';
import { loginNonce } from '../services/session_service';
import { FormData } from '../components/form_data';

const useStyles = makeStyles({cardIconTitle: {marginTop: 0}, cardIcon: {color: 'white'}} as any);

export default () => {
  const {backendClient, orgId} = useContext(SessionContext) as Session;
  const classes = useStyles();
  const [formData, setFormData] = React.useState<{ email?: string, password?: string }>({});
  const [error, setError] = React.useState<string | undefined>();
  const location = useLocation();
  const token = useQuery(location).get('token');

  React.useEffect(() => {
    if (!token || orgId !== '4h') {
      window.location.href = '/';
    }
  }, [token, orgId]);

  return <Container maxWidth="sm">
    <Card>
      <CardHeader>
        <CardIcon color="success" className={classes.cardIcon}><Check/></CardIcon>
        <h3 className={classes.cardIconTitle}>Login to Continue</h3>
      </CardHeader>
      <CardBody>

        <div style={{textAlign: 'center'}}>
          <img alt="ZSuite Logo" src={zsuiteAndCloverLogo}
               style={{maxHeight: 140, maxWidth: '100%'}}/>
        </div>

        <Typography variant="body2" sx={{margin: '2rem 0'}}>
          Please confirm your 4-H Enrollment Status by logging into your ZSuite account.
        </Typography>
        <FormData data={formData} onChange={(newData: any) => setFormData({...formData, ...newData})}>
          <CustomFormInput id="email" labelText="Email"
                           validate={(a: string) => a && a.length}
                           type="email"
                           inputProps={{autoCapitalize: 'off'}}
                           endAdornment={
                             <InputAdornment position="end">
                               <Email
                                   className={classes.inputAdornmentIcon}/>
                             </InputAdornment>}/>
          <CustomFormInput id="password" labelText="Password"
                           type="password"
                           validate={(a: string) => a && a.length}
                           endAdornment={<InputAdornment
                               position="end">
                             <Lock
                                 className={classes.inputAdornmentIcon}/>
                           </InputAdornment>}/>
          {error && <div style={{marginTop: '1rem'}}>
            <SnackbarContent message={error} close
                             onClose={() => setError(undefined)}
                             icon={Warning} color="danger"/>
          </div>}
        </FormData>
      </CardBody>
      <CardFooter>
        <LoadingButton color="warning" size="lg" block
                       loadingMsg="Logging In..."
                       onClick={async () => {
                         if (!formData.email) {
                           return setError('Email is required');
                         }
                         if (!formData.password) {
                           return setError('Password is required',
                           );
                         }
                         if (!token) {
                           return setError('Missing Token');
                         }
                         try {
                           await backendClient.login({}, {
                             nonce: await loginNonce(`?t=${Date.now()}`), orgId: '4h', email: formData.email, password: formData.password,
                             householdMemberId: undefined as any,
                           });
                           const {returnTo} = await backendClient.verifyAccountLinkToken({}, {linkToken: token});
                           window.location.href = returnTo;
                         } catch (e) {
                           console.error(e);
                           setError(await extractError(e, 'Unknown Error'));
                         }
                       }}>
          Login
        </LoadingButton>
      </CardFooter>
    </Card>
  </Container>;
};