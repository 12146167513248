import React from 'react';
import GridContainer from '../../../vendor/components/Grid/GridContainer';
import Button from '../../../vendor/components/CustomButtons/Button';

export const FormDataContext = React.createContext({
  data: {},
  onChange: () => {
  },
});

/**
 * Allows editing of
 * @param props
 * @param {Object} props.data - object you want to edit the values of individually
 * @param {function} props.onChange - function that takes in a partial object of the parent object.
 * This function is meant to merge together the data it passes in with the original object.
 * @param {Object} [props.validations] - a validation function for each key in the FormData
 * @param {JSX.Element|JSX.Element[]} props.children - children to edit each one of the keys of the
 *
 * @returns {JSX.Element}
 */
export function FormData(props) {
  function onChange(newData) {
    if (props.validations && props.validations.length) {
      newData = {
        ...newData,
        errors: props.validations.map(newData),
      };
    }
    props.onChange(newData);
  }

  return <FormDataContext.Provider value={{data: props.data, onChange}}>
    {props.children}
  </FormDataContext.Provider>;
}

/**
 * Adds a sub-FormData within another FormData for a specific prop.
 * Used for editing a sub-object
 *
 * @param {Object} props
 * @param {string} props.prop
 * @param {JSX.Element|JSX.Element[]} props.children
 *
 * @returns {JSX.Element}
 */
export function WithFormProp(props) {
  const {prop, children} = props;
  return <FormDataContext.Consumer>
    {({data, onChange}) => {
      const value = (data || {})[prop],
          setValue = newValue => Array.isArray(data)
              ? onChange(data.map((d, i) => i === prop ? ({...d, ...newValue}) : d))
              : onChange({
                ...data,
                [prop]: Array.isArray(value)
                    ? newValue
                    : {...(data[prop] || {}), ...newValue},
              });
      return <FormData data={value || {}}
                       onChange={setValue}>
        {children}
      </FormData>;
    }}
  </FormDataContext.Consumer>;
}

/**
 * Allows editing a value that is an array of a key in a parent object
 *
 * @param {Object} props
 * @param {string} props.prop - key of property of parent object you're editing
 * @param {string} props.name - title of each element for the add button
 * @param {function} props.childrenMapper - Maps each index to a specific child
 * @param {Object} [props.containerProps={}]
 *
 * @returns {JSX.Element}
 */
export function FormListEditor(props) {
  const {prop, name, childrenMapper, containerProps = {}} = props;
  return <FormDataContext.Consumer>{
    ({data, onChange}) => {
      const items = (data || {})[prop] || [];
      return <WithFormProp prop={prop}>
        <GridContainer style={{width: '100%'}} alignItems='center' {...containerProps}>
          {items.map((item, i) => {
            return <WithFormProp key={i} prop={i}>
              {childrenMapper?.(item, i, () => onChange({[prop]: [...items.filter((_, _i) => _i !== i)]}))}
            </WithFormProp>;
          })}
        </GridContainer>
        <Button color='success' onClick={() => onChange({[prop]: [...items, {}]})}>+ Add {name}</Button>
      </WithFormProp>;
    }
  }</FormDataContext.Consumer>;
}