import React, { Component } from 'react';
import { withSession } from '../../session_context';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import styles from '../../../vendor/assets/jss/material-dashboard-pro-react/customSelectStyle';
import { withStyles } from '@material-ui/core';
import GridContainer from '../../../vendor/components/Grid/GridContainer';
import GridItem from '../../../vendor/components/Grid/GridItem';
import StateSelect from './state_select';
import { sortBy } from '../../shared/shared_helpers';
import { FormDataContext } from '../form_data/';

class CountySelect extends Component {

  async componentDidMount() {
    await this.componentDidUpdate({}, {}, {});
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {

    if (prevProps.value !== this.props.value) {
      await this.loadFromCounty(this.props.value);
    }
    if (prevProps.stateId !== this.props.stateId || prevProps.districtId !== this.props.districtId) {
      await this.loadFromStateOrDistrict(this.props.stateId, this.props.districtId);
    }
  }

  async loadFromCounty(countyId) {
    const {session: {backendClient, orgId}} = this.props;
    const {newCountyId} = this.state || {};
    if (countyId && newCountyId !== countyId && countyId !== 'all') {
      const county = await backendClient.findCounty({id: countyId});
      const {countyLabel} = await backendClient.getStateSelectSettings(
          {stateId: county.stateId, orgId});
      this.setState({
        counties: sortBy('name', await backendClient.listCounties(
            {stateId: county.stateId})),
        stateId: county.stateId,
        label: countyLabel
      });
    }
  }

  async loadFromStateOrDistrict(stateId, districtId) {
    const {session: {backendClient, orgId}} = this.props;
    if ((stateId && stateId !== 'all') || districtId) {
      const {countyLabel} = await backendClient.getStateSelectSettings(
          {stateId, orgId});
      this.setState({
        counties: sortBy('name', await backendClient.listCounties(
            {districtId, stateId})),
        label: countyLabel,
        stateId
      });
    }
  }


  async changeState(stateId) {
    const {
      session: {backendClient, orgId}, onChange,
      onStateChange = x => null, getStateSettings = x => null
    } = this.props;
    if (stateId) {
      const selectStateSettings = await backendClient.getStateSelectSettings({stateId, orgId});
      getStateSettings(selectStateSettings);
      this.setState({
        counties: sortBy('name',
            await backendClient.listCounties({stateId})),
        stateId,
        label: selectStateSettings.countyLabel
      });
    }
    if (stateId === 'all') {
      this.setState({counties: [], stateId});
    }
    onChange();
    onStateChange(stateId);
  }

  render() {
    const {
          classes, value, onChange, id, districtId, countyFilter,
          stateId, stateLabel, stateFilter, showBlankState, showBlankCounty, showStateSelect = !(stateId ||
          districtId), fullWidth, disabled
        } = this.props,
        {counties = [], label} = this.state || {};
    let countiesWithPossibleBlank = counties;
    if (showBlankCounty) {
      countiesWithPossibleBlank = [{name: 'All Counties', id: 'all'}].concat(
          counties);
    }
    if (countyFilter) {
      countiesWithPossibleBlank = countiesWithPossibleBlank.filter(
          countyFilter);
    }
    return (<GridContainer>
      {showStateSelect ? <GridItem xs={12} sm={12} md={12} lg={12}>
        <StateSelect label={stateLabel} value={(this.state || {}).stateId}
                     showBlank={showBlankState} fullWidth={fullWidth}
                     onChange={this.changeState.bind(this)}
                     filter={stateFilter}/>
      </GridItem> : ''}
      {counties.length ? <GridItem xs={12} sm={12} md={12} lg={12}>
        <FormControl fullWidth={fullWidth} className={classes.selectFormControl}>
          <InputLabel htmlFor={id} className={classes.selectLabel}>
            {label || 'County'}
          </InputLabel>
          <Select
              MenuProps={{className: classes.selectMenu}}
              classes={{select: classes.select}}
              value={value || ''}
              disabled={disabled}
              onChange={e => {
                this.setState({newCountyId: e.target.value});
                onChange(e.target.value);
              }}
              inputProps={{name: id, id}}>
            {countiesWithPossibleBlank.map((county) =>
                <MenuItem value={county.id}
                          key={county.id}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}>{county.name}</MenuItem>)}

          </Select>
        </FormControl>
      </GridItem> : ''}
    </GridContainer>);
  }
}

export default withSession(withStyles(styles)(CountySelect));

export const CountySelectFormData = withSession(withStyles(styles)(props => <FormDataContext.Consumer>{
  ({data, onChange}) =>
      <CountySelect {...props} value={data[props.id]}
                    onChange={id => onChange({...data, [props.id]: id})}/>
}</FormDataContext.Consumer>));