import React, { Component } from 'react';
import { withSession } from '../../session_context';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import styles from '../../../vendor/assets/jss/material-dashboard-pro-react/customSelectStyle';
import { withStyles } from '@material-ui/core';
import { sortBy } from '../../shared/shared_helpers';

class StateSelect extends Component {

  async componentDidMount() {
    const {session: {backendClient}, filter = () => true} = this.props,
        states = (await backendClient.listStates()).filter(filter);
    this.setState({states: sortBy('name', states)});
  }

  render() {
    const {
          classes, label = 'State', value, onChange, id, showBlank,
          fullWidth
        } = this.props,
        {states = []} = this.state || {};
    let statesWithPossibleBlank = states;
    if (showBlank) {
      statesWithPossibleBlank = [{name: 'All States', id: 'all'}].concat(states);
    }
    return (
        <FormControl fullWidth={fullWidth} className={classes.selectFormControl}>
          <InputLabel htmlFor={id} className={classes.selectLabel}>
            {label}
          </InputLabel>
          <Select
              MenuProps={{className: classes.selectMenu}}
              classes={{select: classes.select}}
              value={value || ''}
              onChange={e => onChange(e.target.value)}
              inputProps={{name: id, id}}>
            {statesWithPossibleBlank.map((state) =>
                <MenuItem value={state.id}
                          key={state.id}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}>{state.name}</MenuItem>)}

          </Select>
        </FormControl>
    );
  }
}

export default withSession(withStyles(styles)(StateSelect));