import React from 'react';
import Spinner from './spinner';

import {CloudDownload as DownloadIcon, Email, Warning} from '@material-ui/icons';
import pdfMake from 'pdfmake/build/pdfmake';
import newFonts from '../shared/fonts/vfs_fonts';
import {withSession} from '../session_context';
import {
  blobToArrayPolyfill,
  current4HYear,
  extractError, format4HYear, formatYear,
  loadScriptTag,
  resolveRelativePath,
} from '../shared/shared_helpers';
import Button from '../../vendor/components/CustomButtons/Button';
import {withStyles} from '@material-ui/core';
import moment from 'moment';
import {runSweetAlert} from './Modal/Modal';
import SnackbarContent from '../../vendor/components/Snackbar/SnackbarContent';

const MAX_WIDTH = 680;

const styles = {
  reportViewer: {
    margin: '0 auto',
    maxWidth: 848,
    '& h2': {marginTop: 0, textAlign: 'center'},
    '& #report-viewer-buttons': {
      textAlign: 'right', margin: '1rem 2.25rem',
    },
    '& #report-viewer-pages': {
      textAlign: 'center', overflowX: 'auto',
    },
  },
};

class ReportViewer extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.reportRoot = React.createRef();
  }

  async componentDidMount() {
    this.renderStarted = false;
    await loadScriptTag(resolveRelativePath('/pdfjs/pdf.min.js'));
    this.pdfJS = window.pdfjsLib;
    this.pdfJS.GlobalWorkerOptions.workerSrc = resolveRelativePath(
        '/pdfjs/pdf.worker.min.js');
    pdfMake.vfs = newFonts;
    await Promise.all([this.loadReport(), this.loadSubmission()]);
  }

  async loadReport() {
    const {reportName, report, params, session: {backendClient, orgId}} = this.props;
    this.setState({pdfData: undefined, errors: undefined});
    if (reportName) {
      return await this.renderReport();
    }
    if (report) {
      const reportData = await report.load({orgId, ...params}, backendClient);
      this.setState({reportData});
      await this.renderReport(reportData);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const {report, reportName, params} = prevProps;
    if (report !== this.props.report || params !== this.props.params
        || reportName !== this.props.reportName) {
      this.renderStarted = false;
      await this.componentDidMount();
    }
  }

  async renderReport(reportData) {
    const targetEl = this.reportRoot.current;

    if (!targetEl || this.renderStarted) {
      return;
    }
    this.renderStarted = true;
    targetEl.innerHTML = '';
    let pdfData, fileName, reportTitle;
    const {report, reportName, params} = this.props;

    if (this.props.reportName) {
      const reportServerUrl = process.env.REACT_APP_REPORT_SERVER
          || this.props.session.settings?.reportServer || 'http://localhost:4000';
      const {_pdfData, _filename, _reportTitle} = await fetch(reportServerUrl + '/' + this.props.reportName, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          params: this.props.params,
          token: this.props.session.token,
        }),
      }).then(async (resp) => {
        let contentDisposition = resp.headers.get('Content-Disposition'),
            _filename = contentDisposition.indexOf('attachment; filename=') === 0
                ? contentDisposition.substr('attachment; filename='.length)
                : '', _reportTitle = resp.headers.get('X-Report-Title');
        return {_pdfData: await resp.blob().then(blobToArrayPolyfill), _filename, _reportTitle};
      }).catch(async (e) => {
        console.error('Unable to reach server ', e);
        this.setState({errors: [...(this.state.errors || []), `Error fetching report from server: ${await extractError(e)}`]});
        throw e;
      });
      reportTitle = _reportTitle;
      fileName = _filename;
      pdfData = _pdfData;
    } else if (reportData.pdfUrl) {
      pdfData = await fetch(reportData.pdfUrl)
          .then(resp => resp.blob()).then(blobToArrayPolyfill);
    } else if (reportData) {
      pdfData = await new Promise(res =>
          pdfMake.createPdf(reportData.document).getBuffer(res));
    } else {
      return console.log('no reportName or reportData given!');
    }

    // We check if the report is the same one as the currently set one to make sure we don't
    // accidentally overwrite or append to a newer report
    if (report === this.props.report && params === this.props.params
        && reportName === this.props.reportName) {
      this.setState({pdfData, fileName, reportTitle});

      const divWidth = document.getElementById('report-viewer')?.offsetWidth - 16;

      const pdfJSDoc = await this.pdfJS.getDocument(pdfData).promise;
      await Promise.all(
          new Array(pdfJSDoc.numPages).fill(0).map(async (_, i) => {
            const page = await pdfJSDoc.getPage(i + 1),
                canvas = document.createElement('canvas'),
                viewport = page.getViewport({scale: 2});
            canvas.style.boxShadow = '5px 5px 12px -4px #aaa';
            targetEl.appendChild(canvas);
            const canvasContext = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            //canvas.style.width = (divWidth > MAX_WIDTH ? MAX_WIDTH : divWidth) + 'px';
            canvas.style.width = divWidth + 'px';
            await page.render({canvasContext, viewport}).promise;
          }));
    }
  }

  async downloadReport() {
    if (this.props.reportName) {
      const {pdfData, filename} = this.state || {};
      if (!pdfData) {
        return;
      }
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(
          new Blob([pdfData], {type: 'application/pdf'}),
      );
      link.download = filename || (
          this.props.reportName.replaceAll('/', '') + '.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      const _name = this.state.reportData.filename,
          name = _name.endsWith('.pdf') ? _name : _name + '.pdf';
      if (this.state.reportData && this.state.reportData.pdfUrl) {
        const link = document.createElement('a');
        link.href = this.state.reportData.pdfUrl;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (this.state.reportData && this.state.reportData.document) {
        pdfMake.createPdf(this.state.reportData.document).download(name);
      }
    }
  }

  async loadSubmission() {
    if (!this.props.session.currentRecordBook) return;
    const {session: {backendClient, currentRecordBook: {id: recordBookId}, settings}} = this.props,
        year = current4HYear(settings.state);
    this.setState({
      submission: await backendClient.findRecordBookSubmission({recordBookId, year})
          .catch(() => {
            // This is purposefully empty to avoid throwing errors
            // when the book has not been submitted
            return undefined;
          }),
    });
  }

  //Submit Report
  async submitReport() {
    const {session: {backendClient, currentRecordBook: {id: recordBookId, name, year, endYear}, settings}} = this.props;
    const submissionYear = endYear || year || current4HYear(settings.state);
    //console.log("RECORD BOOK SUBMISSION YEAR", submissionYear);
    const {pdfData} = this.state || {};
    if (!pdfData) {
      return;
    }
    try {
      await backendClient.createOrUpdateRecordBookSubmission(
          {recordBookId, year: submissionYear}, {
            report: new Blob([pdfData], {type: 'application/pdf'}),
          });
      await this.loadSubmission();
      await runSweetAlert({
        success: true, body: `Successfully submitted ${name}!`,
      });
    } catch (e) {
      console.error('ERROR', e);
      this.setState({errors: [...(this.state.errors || []), `Error: ${await extractError(e)}`]});
    }
  }

  render() {
    const {
      report, reportName, classes, submittable, session: {backendClient, orgId, settings: {county}}, params, submitLabel = 'Submit',
      resubmitLabel = 'Re-Submit', spinnerText
    } = this.props, {
      reportData, submission, pdfData,
      reportTitle = '', errors
    } = this.state ||
    {};

    const countyRecordBookSubmissionDeadline =
        //THIS CHECKS IF RECORDBOOK SUBMISSION DEADLINE MONTH DAY AND YEAR ARE SET. IF THEY ARE NOT ALL SET THEN MAKE THE VALUE NULL
        (
            county.recordBookSubmissionDeadlineMonth
            & county.recordBookSubmissionDeadlineDay
            & current4HYear(county.recordBookSubmissionDeadlineYear)
        ) ?
            county.recordBookSubmissionDeadlineMonth + '/' + county.recordBookSubmissionDeadlineDay + '/' + current4HYear(county.recordBookSubmissionDeadlineYear)
            : null;

    const beforeSubmissionDeadline =countyRecordBookSubmissionDeadline !== null ? moment().isBefore(countyRecordBookSubmissionDeadline) : true;
    //console.log("REPORT VIEWER PROPS", this.props);
    //console.log("COUNTY RECORD BOOK SUBMISSION DEADLINE", countyRecordBookSubmissionDeadline);
    //console.log("COUNTY RECORD BOOK SUBMISSION DEADLINE", beforeSubmissionDeadline);
    if (!reportData && !reportName) {
      return <Spinner/>;
    }

    return (
        <div id="report-viewer" className={classes.reportViewer}>
          <h2>{report ? report.name : reportTitle}</h2>
          <div id="report-viewer-buttons">
            {report && report.downloadCSV && <Button color="success"
                                                     size="sm"
                                                     onClick={async () => {
                                                       await report.downloadCSV(
                                                           {orgId, ...params},
                                                           backendClient);
                                                       /* downloadAsCSV(
                                                            [['Primary Type', 'Subtype']].concat(recordBookTableFormat),
                                                            'record_book_types.csv');*/
                                                     }}>Export CSV</Button>}

            <Button size="sm" color="primary"
                    onClick={this.downloadReport.bind(this)}>
              <DownloadIcon/> Download
            </Button>
            {(submittable && beforeSubmissionDeadline !== false) ? <Button size="sm" color="warning"
                                    onClick={this.submitReport.bind(this)}>
              <Email/> {submission ? resubmitLabel : submitLabel}
            </Button> : null}
            <div style={{paddingRight: '1rem'}}>
              {submission &&
              <small>Submitted as of {moment(submission.createdAt)
                  .format('lll')}
                {' '}
                {submission.createdAt !== submission.updatedAt
                    ? <span>(last updated {moment(submission.updatedAt)
                        .format('lll')})</span> : ''}
                {' '}
                <a href={'/api/files/' + submission.path}>View Submission</a>
              </small>}
            </div>
          </div>

          {!pdfData ? <Spinner text={spinnerText}/> : ''}
          {errors?.length > 0 ? <SnackbarContent message={errors.join(', ')} close
                                                 onClose={() => this.setState(
                                                     {errors: undefined})}
                                                 icon={Warning} color="danger"/> : ''}
          <div id="report-viewer-pages" ref={this.reportRoot}/>
        </div>
    );
  }
}

export default withSession(withStyles(styles)(ReportViewer));