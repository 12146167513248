import React, { Component } from 'react';
import {
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  withStyles,
} from '@material-ui/core';
import {
  ArrowBack,
  ArrowUpward,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp
} from '../../../mvc/react/ReactIcons';
import Tooltip from '@material-ui/core/Tooltip';
import { ArrowDownward, ArrowForward } from '@material-ui/icons';

const styles = {
  rootContainer: {
    margin: 'auto',
    width: '100%',
    marginTop: '0.75rem',
    display: 'flex',
    alignItems: 'center',
  },
  listContainer: {
    flexGrow: 1,
    width: '100%',
    // minWidth: '40%'
  },
  paper: {
    // maxWidth: 220,
    // width: '100%',
    height: 200,
    overflow: 'auto',
  },
  button: {
    margin: '5px',
  },
};

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

class TransferList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      right: [],
      left: [],
      checked: [],
      leftChecked: [],
      rightChecked: [],
    };
  }

  componentDidMount() {
    const {choices = [], data = []} = this.props;
    this.setLeft(not(choices, data));
    this.setRight(data);
  }

  setLeft(left) {
    const {noSort} = this.props;
    this.setState({left: noSort ? left : left.sort((a, b) => a < b ? -1 : 1)});
  }

  setRight(right) {
    const {noSort} = this.props;
    right = noSort ? right : right.sort((a, b) => a < b ? -1 : 1);
    this.setState({right}, () => this.props.onChange?.(right));
  }

  setChecked(checked) {
    this.setState({checked});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {data, choices} = this.props;
    if (prevProps.choices !== choices || prevProps.data !== data) {
      this.setLeft(not(choices, data));
      this.setRight(data);
    }
  }

  handleToggle(value) {
    const {checked} = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({checked: newChecked});
  };

  handleAllRight() {
    const {right, left} = this.state;
    this.setRight(right.concat(left));
    this.setLeft([]);
  };

  handleCheckedRight() {
    const {right, left, checked} = this.state;
    const leftChecked = intersection(checked, left);
    this.setRight(right.concat(leftChecked));
    this.setLeft(not(left, leftChecked));
    this.setChecked(not(checked, leftChecked));
  };

  handleCheckedLeft() {
    const {right, left, checked} = this.state;
    const rightChecked = intersection(checked, right);
    this.setLeft(left.concat(rightChecked));
    this.setRight(not(right, rightChecked));
    this.setChecked(not(checked, rightChecked));
  };

  handleAllLeft() {
    const {right, left} = this.state;
    this.setLeft(left.concat(right));
    this.setRight([]);
  };

  customList(items) {
    const {classes} = this.props;
    const {checked} = this.state;
    return <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map(value => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
              <ListItem key={value} role="listitem" button onClick={this.handleToggle.bind(this, value)}>
                <ListItemIcon>
                  <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{'aria-labelledby': labelId}}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value}/>
              </ListItem>
          );
        })}
        <ListItem/>
      </List>
    </Paper>;
  };

  render() {
    const {classes, vertical} = this.props;
    const {right, left, checked} = this.state;
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    return <>
      {!vertical &&
      <div className={classes.rootContainer}>
        <div className={classes.listContainer}>
          <p style={{margin: 0, color: 'rgba(0, 0, 0, 0.54)'}}>Options</p>
          {this.customList(left)}
        </div>
        <div style={{display: 'flex', flexDirection: 'column', alignContent: 'center'}}>
          <Tooltip title="Move All Right">
            {/*The span tags are needed since when the button is disabled it doesn't emit events for the Tooltip component*/}
            <span>
                <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={this.handleAllRight.bind(this)}
                    disabled={left.length === 0}
                    aria-label="move all right"
                >
              <ArrowForward/>
            </Button>
              </span>
          </Tooltip>
          <Tooltip title="Move Selected Right">
              <span><Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={this.handleCheckedRight.bind(this)}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
              >
              <KeyboardArrowRight/>
            </Button></span></Tooltip>
          <Tooltip title="Move Selected Left"><span><Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={this.handleCheckedLeft.bind(this)}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
          >
              <KeyboardArrowLeft/>
            </Button>
            </span>
          </Tooltip>
          <Tooltip title="Move All Left">
              <span><Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={this.handleAllLeft.bind(this)}
                  disabled={right.length === 0}
                  aria-label="move all left"
              >
              <ArrowBack/>
            </Button>
              </span></Tooltip>
        </div>
        <div className={classes.listContainer}>
          <p style={{margin: 0, color: 'rgba(0, 0, 0, 0.54)'}}>Selected</p>
          {this.customList(right)}
        </div>
      </div>}

      {vertical &&
      <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
        <Grid item xs={12}>
          <div style={{maxWidth: 400, margin: 'auto'}}>
            <p style={{margin: 0, color: 'rgba(0, 0, 0, 0.54)'}}>Options</p>
            {this.customList(left)}
          </div>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="center">
            <Tooltip title="Move All Down"><Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={this.handleAllRight.bind(this)}
                disabled={left.length === 0}
                aria-label="move all right"
            >
              <ArrowDownward/>
            </Button></Tooltip>
            <Tooltip title='Move Selected Down'><Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={this.handleCheckedRight.bind(this)}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
            >
              <KeyboardArrowDown/>
            </Button></Tooltip>
            <Tooltip title='Move Selected Up'><Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={this.handleCheckedLeft.bind(this)}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
            >
              <KeyboardArrowUp/>
            </Button></Tooltip>
            <Tooltip title='Move All Up'><Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={this.handleAllLeft.bind(this)}
                disabled={right.length === 0}
                aria-label="move all left"
            >
              <ArrowUpward/>
            </Button></Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div style={{maxWidth: 400, margin: 'auto'}}>
            <p style={{margin: 0, color: 'rgba(0, 0, 0, 0.54)'}}>Selected</p>
            {this.customList(right)}
          </div>
        </Grid>
      </Grid>}
    </>;
  };
}

export default withStyles(styles)(TransferList);