import React from 'react';
// nodejs library that concatenates classes
// @ts-ignore
import classNames from 'classnames';
// nodejs library to set properties for components
// material-ui components
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import styles from '../../assets/jss/material-dashboard-pro-react/components/buttonStyle.js';

const useStyles = makeStyles(styles as any);

const RegularButton = React.forwardRef((props: any, ref: any) => {
  const classes = useStyles();
  const {
    color,
    round,
    children,
    fullWidth,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    muiClasses,
    ...rest
  } = props;
  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className,
  });
  return (
      <Button {...rest} ref={ref as any} classes={muiClasses} className={btnClasses}>
        {children}
      </Button>
  );
});

export default RegularButton;
