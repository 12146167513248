import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import MenuItem from '@material-ui/core/MenuItem';
import { FormDataContext } from './index';

import styles from '../../../vendor/assets/jss/material-dashboard-pro-react/customSelectStyle';
import { withStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

function CustomSelectFormInput(props) {
  const options = props.options,
      label = props.label || props.labelText;

  return <FormDataContext.Consumer>
    {({data, onChange}) => {
      const onChangeCb = (value) => {
        if (onChange) {
          onChange({[props.id]: value});
        }
        if (props.onChange) {
          props.onChange(value);
        }
      };
      // console.log(data, data[props.id], props.options) ||
      return <FormControl className={(props.fullWidth ? props.classes.selectFormControlFullWidth + ' ' : '')
          + props.classes.selectFormControl}
                          fullWidth={props.fullWidth}
                          required={props.required}
                          error={props.error}>
        <InputLabel htmlFor={label}
                    error={props.error}
                    className={props.classes.selectLabel}>
          {label}
        </InputLabel>
        <Select
            MenuProps={{className: props.classes.selectMenu}}
            error={props.error}
            value={(data[props.id] === undefined
                ? props.value
                : data[props.id]) || ''}
            onChange={e => onChangeCb(e.target.value)}
            inputProps={{
              name: props.id,
              id: props.id,
              readOnly: props.readOnly,
            }}
            disabled={props.disabled}
            endAdornment={
              (!props.disabled && !props.readOnly) ?
                  <InputAdornment position="end" style={{marginRight: '10px'}}>
                    {data[props.id] && (!props.noClear)
                        ? <IconButton onClick={() => onChangeCb(undefined)} title="Clear">
                          <ClearIcon/>
                        </IconButton> : undefined}
                  </InputAdornment> : ''}>
          {options.map((option) => (typeof option !== 'string' && option.subHeader) ?
              <ListSubheader>{option.label || option.name}</ListSubheader>
              : <MenuItem value={typeof option === 'string'
                  ? option
                  : (option.value || option.id)}
                          key={typeof option === 'string'
                              ? option
                              : (option.value || option.id)}
                          classes={{
                            root: props.classes.selectMenuItem,
                            selected: props.classes.selectMenuItemSelected,
                          }}
                          disabled={option.disabled}>
                {typeof option === 'string' ? option : (option.label || option.name)}
              </MenuItem>)}

        </Select>
        {props.helperText ? <FormHelperText>{props.helperText}</FormHelperText> : ''}
      </FormControl>;
    }}
  </FormDataContext.Consumer>;
}

export default withStyles(styles)(CustomSelectFormInput);
