import React, { Component } from 'react';
import { withSession } from '../../session_context';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import styles from '../../../vendor/assets/jss/material-dashboard-pro-react/customSelectStyle';
import { withStyles } from '@material-ui/core';
import DistrictSelect from './district_select';
import GridContainer from '../../../vendor/components/Grid/GridContainer';
import GridItem from '../../../vendor/components/Grid/GridItem';
import Checkbox from '@material-ui/core/Checkbox';
import { FormDataContext } from '../form_data/';

class CountySelectMultiple extends Component {

  async componentDidMount() {
    const {session: {backendClient}, value, districtId} = this.props;
    if (districtId) {
      await this.changeDistrict(districtId);
    } else if (value) {
      const county = await backendClient.findCounty({id: value[0]});
      this.setState({
        counties: await backendClient.listCounties(
            {districtId: county.districtId}),
        districtId: county.districtId,
      });
    }
  }

  async changeDistrict(districtId) {
    const {session: {backendClient}, onChange} = this.props;
    if (districtId) {
      this.setState(
          {counties: await backendClient.listCounties({districtId}), districtId});
    }
    onChange();
  }

  handleChange(values) {
    const {onChange} = this.props;
    onChange(values);
    this.setState(values);
  }

  render() {
    const {classes, label = 'County', stateId, value = [], id} = this.props,
        {counties = [], districtId} = this.state || {};
    return (<GridContainer>
      {this.props.districtId ? '' : <GridItem xs={12} sm={12} md={12} lg={12}>
        <DistrictSelect stateId={stateId} value={districtId}
                        onChange={this.changeDistrict.bind(this)}/>
      </GridItem>}
      {districtId && <GridItem xs={12} sm={12} md={12} lg={12}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor={id} className={classes.selectLabel}>
            {label}
          </InputLabel>
          <Select
              multiple
              MenuProps={{className: classes.selectMenu}}
              classes={{select: classes.select}}
              value={value || ''}
              onChange={(e) => this.handleChange(e.target.value)}
              renderValue={selected => (selected.map(
                  id => counties.find(c => c.id === id).name)).join(', ')}
              inputProps={{name: id, id}}>
            {counties.map((county) =>
                <MenuItem value={county.id}
                          key={county.id}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}> <Checkbox
                    checked={value.indexOf(county.id) > -1}/>
                  {county.name}</MenuItem>)}

          </Select>
        </FormControl>
      </GridItem>}
    </GridContainer>);
  }
}

export default withSession(withStyles(styles)(CountySelectMultiple));

export const CountySelectMultipleFormData = withSession(withStyles(styles)((props) => <FormDataContext.Consumer>{
  ({data, onChange}) =>
      <CountySelectMultiple
          {...props} value={data[props.id]}
          onChange={ids => onChange({...data, [props.id]: ids})}/>
}</FormDataContext.Consumer>));