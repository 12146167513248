import React from 'react';
import ImageInput from './../image_input';
import { FormDataContext } from './index';

const ImageFormInput = (props) =>
    <FormDataContext.Consumer>
      {({data, onChange}) => <div
          style={{maxWidth: props.maxWidth, margin: 'auto', ...props.style}}>
        <label style={{
          display: 'block',
          marginBottom: '1rem',
          fontSize: '90%',
        }}>{props.labelText}</label>
        <ImageInput value={data[props.id]} onChange={newVal => onChange({
          [props.id]: newVal,
        })} onAltChange={props.altId ? (alt) => {
            onChange({[props.altId]: alt});
        } : undefined} alt={props.altId ? data[props.altId] : undefined} {...props} />
      </div>}
    </FormDataContext.Consumer>;

export default ImageFormInput;