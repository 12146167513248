import { lazy } from 'react';

export const Home = lazy(() => import('@material-ui/icons/Home'));
export const AccountBox = lazy(() => import('@material-ui/icons/AccountBox'));
export const Book = lazy(() => import('@material-ui/icons/Book'));
export const Close = lazy(() => import('@material-ui/icons/Close'));
export const Check = lazy(() => import('@material-ui/icons/Check'));
export const Add = lazy(() => import('@material-ui/icons/Add'));
export const FiberManualRecord = lazy(
    () => import('@material-ui/icons/FiberManualRecord'));
export const Apps = lazy(() => import('@material-ui/icons/Apps'));
export const DateRange = lazy(() => import('@material-ui/icons/DateRange'));
export const GridOn = lazy(() => import('@material-ui/icons/GridOn'));
export const Image = lazy(() => import('@material-ui/icons/Image'));
export const Place = lazy(() => import('@material-ui/icons/Place'));
export const Timeline = lazy(() => import('@material-ui/icons/Timeline'));
export const Widgets = lazy(() => import('@material-ui/icons/Widgets'));
export const ExpandMore = lazy(() => import('@material-ui/icons/ExpandMore'));
export const Menu = lazy(() => import('@material-ui/icons/Menu'));
export const MoreVert = lazy(() => import('@material-ui/icons/MoreVert'));
export const ViewList = lazy(() => import('@material-ui/icons/ViewList'));
export const Person = lazy(() => import('@material-ui/icons/Person'));
export const Notifications = lazy(
    () => import('@material-ui/icons/Notifications'));
export const Dashboard = lazy(() => import('@material-ui/icons/Dashboard'));
export const Search = lazy(() => import('@material-ui/icons/Search'));
export const PersonAdd = lazy(() => import('@material-ui/icons/PersonAdd'));
export const Fingerprint = lazy(() => import('@material-ui/icons/Fingerprint'));
export const LockOpen = lazy(() => import('@material-ui/icons/LockOpen'));
export const MonetizationOn = lazy(
    () => import('@material-ui/icons/MonetizationOn'));
export const Edit = lazy(() => import('@material-ui/icons/Edit'));
export const CardTravel = lazy(() => import('@material-ui/icons/CardTravel'));
export const Extension = lazy(() => import('@material-ui/icons/Extension'));
export const FlightLand = lazy(() => import('@material-ui/icons/FlightLand'));
export const Build = lazy(() => import('@material-ui/icons/Build'));
export const KeyboardArrowLeft = lazy(
    () => import('@material-ui/icons/KeyboardArrowLeft'));
export const KeyboardArrowRight = lazy(
    () => import('@material-ui/icons/KeyboardArrowRight'));
export const PriorityHigh = lazy(
    () => import('@material-ui/icons/PriorityHigh'));
export const Warning = lazy(() => import('@material-ui/icons/Warning'));
export const Favorite = lazy(() => import('@material-ui/icons/Favorite'));
export const AddAlert = lazy(() => import('@material-ui/icons/AddAlert'));
export const Schedule = lazy(() => import('@material-ui/icons/Schedule'));
export const Info = lazy(() => import('@material-ui/icons/Info'));
export const LocationOn = lazy(() => import('@material-ui/icons/LocationOn'));
export const Gavel = lazy(() => import('@material-ui/icons/Gavel'));
export const HelpOutline = lazy(() => import('@material-ui/icons/HelpOutline'));
export const Store = lazy(() => import('@material-ui/icons/Store'));
export const LocalOffer = lazy(() => import('@material-ui/icons/LocalOffer'));
export const Update = lazy(() => import('@material-ui/icons/Update'));
export const ArrowUpward = lazy(() => import('@material-ui/icons/ArrowUpward'));
export const AccessTime = lazy(() => import('@material-ui/icons/AccessTime'));
export const Refresh = lazy(() => import('@material-ui/icons/Refresh'));
export const ArtTrack = lazy(() => import('@material-ui/icons/ArtTrack'));
export const Language = lazy(() => import('@material-ui/icons/Language'));
export const Today = lazy(() => import('@material-ui/icons/Today'));
export const LibraryBooks = lazy(
    () => import('@material-ui/icons/LibraryBooks'));
export const AvTimer = lazy(() => import('@material-ui/icons/AvTimer'));
export const MailOutline = lazy(() => import('@material-ui/icons/MailOutline'));
export const Contacts = lazy(() => import('@material-ui/icons/Contacts'));
export const Face = lazy(() => import('@material-ui/icons/Face'));
export const RecordVoiceOver = lazy(
    () => import('@material-ui/icons/RecordVoiceOver'));
export const Email = lazy(() => import('@material-ui/icons/Email'));
export const Map = lazy(() => import('@material-ui/icons/Map'));
export const AddLocation = lazy(() => import('@material-ui/icons/AddLocation'));
export const Business = lazy(() => import('@material-ui/icons/Business'));
export const AccountBalance = lazy(
    () => import('@material-ui/icons/AccountBalance'));
export const Code = lazy(() => import('@material-ui/icons/Code'));
export const Group = lazy(() => import('@material-ui/icons/Group'));
export const BugReport = lazy(() => import('@material-ui/icons/BugReport'));
export const Cloud = lazy(() => import('@material-ui/icons/Cloud'));
export const FormatQuote = lazy(() => import('@material-ui/icons/FormatQuote'));
export const PermIdentity = lazy(
    () => import('@material-ui/icons/PermIdentity'));
export const Assignment = lazy(() => import('@material-ui/icons/Assignment'));
export const Remove = lazy(() => import('@material-ui/icons/Remove'));
export const SupervisorAccount = lazy(
    () => import('@material-ui/icons/SupervisorAccount'));
export const Description = lazy(() => import('@material-ui/icons/Description'));
export const AttachMoney = lazy(() => import('@material-ui/icons/AttachMoney'));
export const School = lazy(() => import('@material-ui/icons/School'));
export const ExitToApp = lazy(() => import('@material-ui/icons/ExitToApp'));
export const EmojiPeople = lazy(() => import('@material-ui/icons/EmojiPeople'));
export const Help = lazy(() => import('@material-ui/icons/Help'));
export const ListAlt = lazy(() => import('@material-ui/icons/ListAlt'));
export const ImportExport = lazy(
    () => import('@material-ui/icons/ImportExport'));
export const People = lazy(() => import('@material-ui/icons/People'));
export const ShoppingCart = lazy(
    () => import('@material-ui/icons/ShoppingCart'));
export const Forum = lazy(() => import('@material-ui/icons/Forum'));
export const Movie = lazy(() => import('@material-ui/icons/Movie'));
export const CloudUpload = lazy(() => import('@material-ui/icons/CloudUpload'));
export const Flag = lazy(() => import('@material-ui/icons/Flag'));
export const Lock = lazy(() => import('@material-ui/icons/Lock'));
export const Visibility = lazy(() => import('@material-ui/icons/Visibility'));
export const House = lazy(() => import('@material-ui/icons/House'));
export const List = lazy(() => import('@material-ui/icons/List'));
export const Clear = lazy(() => import('@material-ui/icons/Clear'));
export const AttachFile = lazy(() => import('@material-ui/icons/AttachFile'));
export const Autorenew = lazy(() => import('@material-ui/icons/Autorenew'));
export const Phone = lazy(() => import('@material-ui/icons/Phone'));
export const Redeem = lazy(() => import('@material-ui/icons/Redeem'));
export const ArrowBack = lazy(() => import('@material-ui/icons/ArrowBack'));
export const Comment = lazy(() => import('@material-ui/icons/Comment'));
export const ArrowLeft = lazy(() => import('@material-ui/icons/ArrowLeft'));
export const HowToReg = lazy(() => import('@material-ui/icons/HowToReg'));
export const Save = lazy(() => import('@material-ui/icons/Save'));
export const NotificationImportant = lazy(
    () => import('@material-ui/icons/NotificationImportant'));
export const Cached = lazy(() => import('@material-ui/icons/Cached'));
export const Send = lazy(() => import('@material-ui/icons/Send'));
export const TrackChanges = lazy(
    () => import('@material-ui/icons/TrackChanges'));
export const AssignmentInd = lazy(
    () => import('@material-ui/icons/AssignmentInd'));
export const Create = lazy(() => import('@material-ui/icons/Create'));
export const Settings = lazy(() => import('@material-ui/icons/Settings'));
export const SupervisedUserCircle = lazy(
    () => import('@material-ui/icons/SupervisedUserCircle'));
export const CheckCircle = lazy(() => import('@material-ui/icons/CheckCircle'));
export const Launch = lazy(() => import('@material-ui/icons/Launch'));
export const Chat = lazy(() => import('@material-ui/icons/Chat'));
export const Mail = lazy(() => import('@material-ui/icons/Mail'));
export const Delete = lazy(() => import('@material-ui/icons/Delete'));
export const CheckCircleOutline = lazy(
    () => import('@material-ui/icons/CheckCircleOutline'));
export const CloudDownload = lazy(
    () => import('@material-ui/icons/CloudDownload'));
export const FileCopy = lazy(() => import('@material-ui/icons/FileCopy'));
export const DoneOutline = lazy(() => import('@material-ui/icons/DoneOutline'));
export const Link = lazy(() => import('@material-ui/icons/Link'));
export const Fullscreen = lazy(() => import('@material-ui/icons/Fullscreen'));
export const Beenhere = lazy(() => import('@material-ui/icons/Beenhere'));
export const HourglassEmptyOutlined = lazy(
    () => import('@material-ui/icons/HourglassEmptyOutlined'));
export const PieChart = lazy(() => import('@material-ui/icons/PieChart'));
export const Archive = lazy(() => import('@material-ui/icons/Archive'));
export const Unarchive = lazy(() => import('@material-ui/icons/Unarchive'));
export const KeyboardArrowDown = lazy(
    () => import('@material-ui/icons/KeyboardArrowDown'));
export const KeyboardArrowUp = lazy(
    () => import('@material-ui/icons/KeyboardArrowUp'));