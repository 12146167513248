import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import {addHttpToUrl, formatMoney} from "../../../../core/shared/shared_helpers";
import SnackbarContent from "../../../../vendor/components/Snackbar/SnackbarContent";
import CardHeader from "../../../../vendor/components/Card/CardHeader";
import eventsPlaceholder from "../../../../core/shared/img/events-placeholder.png";
import CardBody from "../../../../vendor/components/Card/CardBody";
import Button from "../../../../vendor/components/CustomButtons/Button";
import {
  AttachFile,
  ChildCare,
  Contacts,
  Email,
  Event as EventIcon,
  Group,
  Link,
  Payment,
  Phone,
  Place,
  Warning
} from "@material-ui/icons";
import {formatEventDate} from "../../event_editor";
import {showContactModal} from "../../../../core/components/Modal/Modal";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "../../../../vendor/components/Card/Card";
import dashboardStyle from "../../../../core/components/dashboard/dashboardStyle";
import {grayColor} from "../../../../vendor/assets/jss/material-dashboard-pro-react";
import moment from "moment";

const styles = {
  ...dashboardStyle,
  eventCard: {
    textAlign: 'center',
    '& img': {maxHeight: 240, maxWidth: '100%', width: 'auto'},
  },
  eventCardBody: {
    textAlign: 'center'
  },
  eventDetail: {
    marginTop: '1rem',
    color: grayColor[1],
    '& svg': {verticalAlign: 'middle'},
  },
};

class Step0EventInfo extends Component<any, any> {
  sendState() {
    return this.state;
  }

  render() {
    const {event, classes, error} = this.props;
    return <Card product plain>
      <CardHeader image className={classes.eventCard}>
        <img src={event.imageUrl || eventsPlaceholder} alt={event.imageAlt || event.name}/>
      </CardHeader>
      <CardBody className={classes.eventCardBody}>
        <h4 className={classes.cardProductTitle} style={{marginBottom: '1rem'}}>
          {event.status === 'Cancelled' ? 'Cancelled: ' + event.name : event.name}
        </h4>

        <div className='fr-view' style={{maxHeight: 500, overflowY: 'auto'}}
             dangerouslySetInnerHTML={{__html: event.desc}}/>

        {event.externalRegistrationLink &&
        <Button size="sm" color="rose"
                component="a"
                href={addHttpToUrl(event.externalRegistrationLink)}
                target="_blank">
          <Link/> Register
        </Button>}

        <p className={classes.eventDetail}>
          <EventIcon/> {' '}
          {formatEventDate(event as any)}
        </p>
        {event.remainingClubMemberSpace !== undefined ? <p
          className={classes.eventDetail}>
          <Group/> {event.remainingClubMemberSpace} Remaining Club Member {
          event.remainingClubMemberSpace === 1 ? 'Seat' : 'Seats'}
        </p> : ''}
        {event.remainingVolunteerSpace !== undefined ? <p
          className={classes.eventDetail}>
          <Group/> {event.remainingVolunteerSpace} Remaining Volunteer {
          event.remainingVolunteerSpace === 1 ? 'Seat' : 'Seats'}
        </p> : ''}
        {event.regRequired && event.fee ? <p
          className={classes.eventDetail}>
          <Payment/> {formatMoney(event.fee || 0)}
        </p> : ''}
        {event.location ? <p className={classes.eventDetail}>
          <Place/> {event.location}
        </p> : ''}
        {event.minAge ? <p className={classes.eventDetail}>
          <ChildCare/> {"Minimum Age: " + event.minAge}
        </p> : ''}
        {event.maxAge ? <p className={classes.eventDetail}>
          <ChildCare/> {"Maximum Age: " + event.maxAge}
        </p> : ''}
        {event.minGrade ? <p className={classes.eventDetail}>
          <ChildCare/> {"Minimum Grade: " + event.minGrade}
        </p> : ''}
        {event.contactName ? <p className={classes.eventDetail}>
          <Contacts/> {event.contactName + '  '}
          {event.contactEmail ?
            <span>
                    <a title={event.contactEmail} href={`mailto://${event.contactEmail}`}
                       onClick={async (e) => {
                         e.preventDefault();
                         await showContactModal({name: event.contactName, email: event.contactEmail})
                       }}>
                      <Email className={classes.profileIcon}/></a>
                  </span> : ''}
          {event.contactPhone ? <span>
                <a title={event.contactPhone} href={`tel://${event.contactPhone}`}
                   onClick={async (e) => {
                     e.preventDefault();
                     await showContactModal({name: event.contactName, phone: event.contactPhone});
                   }}>
                  <Phone className={classes.profileIcon}/></a>
              </span> : ''}
        </p> : ''}
        {event.fileAttachments.length ?
          <div>
            <p>Download Attachments
              {event.fileAttachments.map(({name, url}: any) =>
                <Tooltip
                  key={name}
                  id={name.replace(/\s/g, '-') +
                  '-attachment'}
                  title={name}
                  placement="bottom"
                  classes={{tooltip: classes.tooltip}}><Button
                  component="a" href={url} target="_blank"
                  color="transparent" simple justIcon>
                  <AttachFile/>
                </Button></Tooltip>,
              )}</p>
          </div> : ''}
        {event.registrationClose ? <SnackbarContent icon={Warning} color='warning'
                                                    message={`Registration Closes: ${moment(event.registrationClose).format("LLL")}`}/> : ''}
        {error ? <div style={{marginTop: '1rem'}}>
          <SnackbarContent message={error} icon={Warning} color="danger"/>
        </div> : ''}
      </CardBody>
    </Card>
  }
}

export default withStyles(styles as any)(Step0EventInfo);