import React from 'react';
import SelectMultiple from '../select_multiple';
import { FormDataContext } from './index';

interface Props {
  id: string,
  labelText: string,
  endAdornment?: any,
  options: Array<string | { name: string, id: any, disabled?: boolean }>
  noSort?: boolean,
  required?: boolean,
  readOnly?: boolean
  error?: boolean
  helpText?: string
  fullWidth?: boolean
}

export default function MultiSelect(props: Props) {
  const {
    id, labelText, endAdornment, options, noSort, required, readOnly,
    error, helpText, fullWidth
  } = props;
  return <FormDataContext.Consumer>
    {({data, onChange}: { data: any, onChange: any }) =>
        <SelectMultiple
            error={error}
            readOnly={readOnly}
            label={labelText}
            endAdornment={endAdornment}
            options={options}
            required={required}
            value={data[id]}
            noSort={noSort}
            onChange={(value: any) => onChange({[id]: value})}
            helperText={helpText}
            fullWidth={fullWidth}/>
    }</FormDataContext.Consumer>;
}