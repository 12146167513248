import React, {Component} from 'react';
import GridItem from '../../vendor/components/Grid/GridItem';

import {withSession} from '../session_context';
import dashboardStyle from './dashboard/dashboardStyle';
import taskStyle
    from '../../vendor/assets/jss/material-dashboard-pro-react/components/tasksStyle';

import {
    defaultFont,
    grayColor,
} from '../../vendor/assets/jss/material-dashboard-pro-react';

import TextField from '@material-ui/core/TextField/TextField';

import CountySelect from './region_select/county_select';
import Button from '../../vendor/components/CustomButtons/Button';
import GridContainer from '../../vendor/components/Grid/GridContainer';
import SnackbarContent from '../../vendor/components/Snackbar/SnackbarContent';

const styles = {
    ...dashboardStyle,
    ...taskStyle,
    eventCard: {
        textAlign: 'center',
        '& img': {maxHeight: 240, maxWidth: '100%', width: 'auto'},
    },
    eventDetail: {
        marginTop: '1rem',
        color: grayColor[1],
        '& svg': {verticalAlign: 'middle'},
    },
    tableCellOnly: {
        ...defaultFont,
        border: 'none',
        lineHeight: '1.42857143',
        fontSize: '16px',
        width: '100%',
    },
};

class update_county_id_and_pin_modal extends Component {

    async updatePinAndCountyId() {
        const {resolve, reject, session: {backendClient}, account, oldPin, oldCountyId, orgId} = this.props,
            {newPin, newCountyId} = this.state || {};
        let pin = oldPin, countyId = oldCountyId;
        if (!pin) {
            pin = newPin;
        }
        if (!countyId) {
            countyId = newCountyId
        }
        if ((!pin && !orgId) || !countyId) {
            if (!pin && !orgId) {
                this.setState({infoError: "Pin is required"})
            }
            if (!countyId) {
                this.setState({infoError: "State and County is required"})
            }
        } else {
            try {
                await backendClient.updateAccount({id: account.id}, {...account, pin: newPin, countyId: newCountyId});
                resolve({countyId, pin})
            } catch (e) {
                reject();
            }
        }
    }


    render() {
        const {oldPin, oldCountyId, session, reject, orgId} = this.props;
        const {newPin, newCountyId, infoError} = this.state || {};

        return (
            <div>
                <h5>This organization requires more information</h5>
                <GridContainer justify='center'>
                    {(!oldPin && !orgId) && <GridItem xs={12} md={12}>
                        <p>
                            Your account is currently missing a PIN. Please create your PIN below.
                            <br/><br/>
                            <i>
                                The PIN is used for account security for the primary/parent profile(s), so that the
                                password  may be shared between household members.
                            </i>
                            </p>
                        <TextField
                            id="standard-name"
                            label="Please create your PIN here"
                            //className={classes.textField}
                            value={newPin || ''}
                            onChange={(e) => this.setState({newPin: e.target.value})}
                            fullWidth
                            margin="normal"
                        />
                    </GridItem>}
                    {!oldCountyId && <GridItem xs={12} md={12} lg={12}>

                        <CountySelect value={newCountyId} session={session}
                                      stateLabel={"Please choose a State"}
                                      onChange={(newCountyId) => this.setState({newCountyId: newCountyId})}
                        />
                    </GridItem>}
                    <Button onClick={reject}>Logout</Button>
                    <Button color="info" onClick={() => this.updatePinAndCountyId()}>Ok</Button>
                </GridContainer>
                {infoError && <SnackbarContent
                    message={infoError}
                    onClose={() => this.setState({infoError: false})}
                    close
                    color="danger"
                />}
            </div>

        );
    }
}

export default withSession(update_county_id_and_pin_modal);