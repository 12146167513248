import React from 'react';
import TextField from '@material-ui/core/TextField';
import { FormDataContext } from './index';

export default function CustomTextField(props) {
  return (
      <FormDataContext.Consumer>
        {({data, onChange}) =>
            props.json
                ? <TextField style={{marginTop: '1.5rem', marginBottom: '1rem'}}
                             onChange={e => onChange({[props.id]: JSON.parse(e.target.value)})}
                             id={props.id}
                             label={props.labelText || props.label}
                             multiline
                             variant="outlined"
                             disabled={props.disabled || false}
                             fullWidth
                             rows={props.rows || 4}
                             value={JSON.stringify(data[props.id] || '', null, 2) || ''}
                />
                : <TextField style={{marginTop: '1.5rem', marginBottom: '1rem'}}
                             onChange={e => onChange({
                               [props.id]: e.target.value,
                             })}
                             id={props.id}
                             label={props.labelText || props.label}
                             multiline
                             variant="outlined"
                             disabled={props.disabled || false}
                             fullWidth
                             rows={props.rows || 4}
                             value={data[props.id] || ''}/>
        }
      </FormDataContext.Consumer>
  );
}