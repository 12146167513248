import React, {Component} from 'react';
import Spinner from './spinner';

class AccountView extends Component {

  async componentDidMount() {
    if (this.props.account) {
      this.setState(
          {view: await this.loadAccountComponent(this.props.account)});
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.account !== this.props.account) {
      this.setState(
          {view: await this.loadAccountComponent(this.props.account)});
    }
  }

// Load the account views asynchronously to reduce the initial load time of the app
  // and prevent loading unnecessary modules
  async loadAccountComponent(account) {
    const {orgId} = this.props;

    if (orgId === 'ss') {
      if (account.accountType === 'Household') {
        return await import('../account_types/ss/household')
            .then(m => m.default);
      }
    }

    if (orgId === 'nss') {
      switch (account.accountType) {
        case 'Household':
          return await import('../account_types/nss/household')
              .then(m => m.default);
        case 'StateAdmin':
          return await import('../account_types/nss/state_admin')
              .then(m => m.default);
        case 'CountyAdmin':
          return await import('../account_types/nss/county_admin')
              .then(m => m.default);
        default:
          // No default case - fall back to 4h
      }
    }

    if (orgId === 'ca_lms') {
      switch (account.accountType) {
        case 'Household':
          return await import('../account_types/ca_lms/household')
              .then(m => m.default);
        case 'SysAdmin':
          break;
        default:
          throw new Error('Account type view not found');
      }
    }

    if (orgId === 'uafext') {
      switch (account.accountType) {
        case 'Household':
          return await import('../account_types/uafext/household')
              .then(m => m.default);
        case 'StateAdmin':
          return await import('../account_types/uafext/state_admin')
              .then(m => m.default);
        case 'CountyAdmin':
          return await import('../account_types/uafext/county_admin')
              .then(m => m.default);
        default:
          // No default case - fall back to 4h
      }
    }

    if (orgId === 'ext') {
      switch (account.accountType) {
        case 'Household':
          return import('../account_types/ext/household').then(m => m.default);
        case 'SysAdmin':
          return import('../account_types/sys_admin').then(m => m.default);
        case 'StateAdmin':
          return import('../account_types/ext/state_admin').then(m => m.default);
        case 'StateUser':
          return import('../account_types/ext/state_user').then(m => m.default);
        case 'DistrictAdmin':
          return import('../account_types/ext/district_admin').then(m => m.default);
        case 'CountyAdmin':
          return import('../account_types/ext/county_admin').then(m => m.default);
        case 'NationalAdmin':
          return import('../account_types/nss/national_admin').then(m => m.default);
        case 'ShootingSportsVolunteer':
          return import('../account_types/nss/household').then(m => m.default);
        default:
          // No default case - fall back to 4h
      }
    }

    switch (account.accountType) {
      case 'Household':
        return await import('../account_types/4h/household')
            .then(m => m.default);
      case 'SysAdmin':
        return await import('../account_types/sys_admin')
            .then(m => m.default);
      case 'StateAdmin':
        return await import('../account_types/4h/state_admin')
            .then(m => m.default);
      case 'StateUser':
        return await import('../account_types/4h/state_user')
            .then(m => m.default);
      case 'DistrictAdmin':
        return await import('../account_types/4h/district_admin')
            .then(m => m.default);
      case 'CountyAdmin':
        return await import('../account_types/4h/county_admin')
            .then(m => m.default);
      case 'NationalAdmin':
        return await import('../account_types/nss/national_admin')
            .then(m => m.default);
      case 'ShootingSportsVolunteer':
        return await import('../account_types/nss/household')
            .then(m => m.default);
      default:
        return () => <p>Unknown account type {account.accountType}</p>;
    }
  }

  render() {
    const {view: View = Spinner} = this.state || {};
    return <View/>;
  }
}

export default AccountView;