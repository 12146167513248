import {
  current4HYear,
  leftPad,
  randomProfileUrl,
} from '../shared/shared_helpers';
import {runModal, runModalForm, runSweetAlert} from '../components/Modal/Modal';
import React from 'react';
import UpdateCountyIdAndPin from '../components/update_county_id_and_pin_modal';
import SessionContext from '../session_context';
import CustomFormInput from '../components/form_data/custom_form_input';

export default class SessionService {
  constructor(sessionContext) {
    this.sessionContext = sessionContext;
  }

  async login(email, password) {
    const backend = this.sessionContext.backendClient,
        {orgId} = this.sessionContext;
    let result, account;
    try {
      result = await backend.login({}, {email, password, orgId, nonce: await loginNonce(backend.getSettingsUrl())});

      if (result.mfaRequired) {
        const {token} = await runModalForm(() => <>
          <p>{result.mfaRequired}</p>
          <CustomFormInput id="token" labelText="Login Code"/>
        </>, {
          title: 'Login Code Required',
          notClosable: true,
        });
        result = await backend.mfaTokenLogin({}, {token});
      }

      if (result.pinRequired) {
        const {pin} = await runModalForm(() => <>
          <p>{result.pinRequired}</p>
          <CustomFormInput id="pin" labelText="PIN" type="password" autoFocus/>
        </>, {
          title: 'PIN Required',
          notCloseable: true,
        });
        result = await backend.pinLogin({}, {pin, email, password});
      }

      account = result.account;

      // Add org association to household if it doesn't exist
      if (account.accountType === 'Household' &&
          (account.orgAssociations || [orgId]).indexOf(orgId) < 0) {
        await backend.addHouseholdOrgAssociation({id: account.id, orgId});
        account.orgAssociations.push(orgId);
      }
      /*analyticsClient.send({
        action: 'setAccount',
        accountId: account.id,
        name: account.firstName + ' ' + account.lastName,
        page: window.location.pathname,
      });*/

    } catch (e) {
      // if (e && e.json) {
      //   const body = await e.json().catch(() => ({}));
      //   throw new Error(body.message || 'Invalid email/password');
      //
      // } else {
      //   throw new Error('Invalid email/password');
      // }
      throw new Error('Invalid email and/or password. If you do not remember your password press the "Forgot Password" button below to send yourself a password reset email.');
    }

    await this.reloadSession(result);
  }

  async claimImportedAccount(email, countyId, password) {
    const {backendClient, orgId} = this.sessionContext;
    const loginResponse = await backendClient.claimImportedAccount({}, {
      email, countyId, password,
    }), {account} = loginResponse;

    // Add org association to household if it doesn't exist
    if (account.accountType === 'Household' &&
        (account.orgAssociations || [orgId]).indexOf(orgId) < 0) {
      await backendClient.addHouseholdOrgAssociation({id: account.id, orgId});
      account.orgAssociations.push(orgId);
    }

    await this.reloadSession(loginResponse);
  }

  // Loads session data for an account
  // loginData is set when logging in with a username/password but is
  // undefined when viewingAs as a SysAdmin
  async getSessionData(_account, loginData = {}) {
    let {backendClient, orgId} = this.sessionContext, sessionData = {},
        account = _account || {},
        settings = {
          ...loginData.accountSettings, county: loginData.countySettings || {},
          state: {
            ...loginData.stateSettings,
            countyLabel: loginData.stateSettings?.countyLabel || 'County',
            districtLabel: loginData.stateSettings?.districtLabel || 'District',
            clubLabel: loginData.stateSettings?.clubLabel || 'Club',
          },
        };

    // Check for county in case loginData is empty (Viewing as a sysadmin)
    if (loginData.county && account.accountType === 'Household') {
      // sessionData.householdMembers = await this.getHouseholdMembers(account);
      sessionData.householdMember = loginData.householdMember;
      sessionData.householdMembers = [
        {
          firstName: account.firstName,
          lastName: account.lastName,
          photoUrl: account.photoUrl,
          memberType: 'Primary',
          accountId: account.id,
        }].concat(loginData.householdMembers);
      sessionData.county = loginData.county;
      sessionData.state = loginData.state;
      sessionData.settings = settings;
    } else if (loginData.county && account.accountType === 'CountyAdmin') {
      sessionData.county = loginData.county;
      sessionData.state = loginData.state;
      sessionData.settings = settings;
    } else if (loginData.state && account.accountType === 'StateAdmin') {
      sessionData.state = loginData.state;
      sessionData.settings = settings;
    } else {
      // This is the legacy/fallback/slower method of loading in the settings for an account
      // Prefer to load using loginData, but if not available load using calls below
      if (account.countyId) {
        sessionData.county = await backendClient.findCounty({id: account.countyId});
        sessionData.state = await backendClient.findState({id: sessionData.county.stateId});
      } else if (account.districtId) {
        sessionData.district = await backendClient.findDistrict({id: account.districtId});
        sessionData.state = await backendClient.findState(
            {id: sessionData.district.stateId});
      } else if (account.stateId) {
        sessionData.state = await backendClient.findState(
            {id: account.stateId});
      }
      sessionData.settings = await this.loadSettings(account, {},
          account.countyId, sessionData.state && sessionData.state.id);
    }

    sessionData.account = account;
    sessionData.orgId = orgId;

    return sessionData;
  }

  // Gets the session data and calls updateSession
  async reloadSession(loginResponse) {
    let {
      currentHouseholdMember: oldHouseholdMember,
      backendClient, orgId,
    } = this.sessionContext;

    try {

      const {account, token, ...loginData} = loginResponse ||
      await backendClient.currentSessionAccount({orgId, householdMemberId: null});

      const newSessionData = await this.getSessionData(account, loginData),
          {settings: {state: stateSettings = {}}} = newSessionData;
      newSessionData.token = token;

      const caCountyIds = [ //THIS IS A LIST OF ALL THE COUNTY IDS FOR CA AND CA DEMO
        4481, 4482, 4483, 635, 636, 637, 638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 648, 649,
        650, 651, 652, 653, 654, 655, 656, 657, 658, 659, 660, 661, 662, 663, 664, 665, 666, 667, 668, 669, 670, 671, 673,
        674, 675, 676, 677, 678, 679, 680, 681, 682, 683, 684, 685, 686, 687, 688, 689, 4484, 4491, 690, 691, 692, 4485,
        4486, 4489, 4487, 4490, 4488, 3985, 4500, 4501, 4502, 4463, 4402, 672, 4436, 4437, 4438, 4439, 4440, 4441, 4442,
        4443, 4444, 4445, 4446, 4447, 4448, 4449, 4450, 4451, 4452, 4453, 4454, 4455, 4456, 4457, 4458, 4459, 4460, 4461,
        4462, 4464, 4465, 4466, 4467, 4468, 4469, 4470, 4471, 4472, 4473, 4474, 4475, 4476, 4477, 4478, 4479, 4480
      ];

      const pattern = new RegExp( //THIS IS VARIABLE THAT MAKES A PATTERN TO COMPARE THE PASSWORD TO TO MAKE SURE IT HAS AN UPPER CASE, LOWERCASE, NUMBER AND SPECIAL CHARACTER
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
      );


      // Change password if required
      if (account && account.passwordChangeRequired) {
        await runModalForm(() => <div>
          <p>Please choose a new password for your account</p>
          <CustomFormInput id="password" type="password"
                           labelText="New Password"/>
          <CustomFormInput id="passwordConfirmation" type="password"
                           labelText="Confirm New Password"/>
        </div>, {
          title: 'A Password Change is Required',
          onSubmit: async ({password, passwordConfirmation}) => {
            console.log("PASSWORD RESET ACCOUNT", account);

            if(!password){
              throw new Error('"New Password" cannot be blank')
            }
            if (password !== passwordConfirmation) {
              throw new Error('Passwords don\'t match');
            }
            if(caCountyIds.includes(account.countyId) && password && password.length <12){ //THIS IS CHECKING THAT THE LENGTH OF THE PASSWORD IS AT LEAST 12 CHARACTER
              throw new Error('Password must have at least 12 characters');
            }

            if(caCountyIds.includes(account.countyId) && password &&  !pattern.test(password) ){ //THIS IS CHECKING THAT THE PASSWORD HAS A LOWERCASE, UPPERCASE, NUMBER AND SPECIAL CHARACTER
              throw new Error('Password must have at least one uppercase, one lowercase, one number and one special character');
            }

            await backendClient.updateAccountWithPassword({id: account.id},
                {newPassword: password, oldPassword: null});
          },
        }).then(() => runSweetAlert({
          success: true,
          title: 'Success!',
          body: 'Thank you, your password has been updated',
        })).catch(() => this.logout());
      }

      if (account.accountType === 'Household') {
        if (orgId === '4h' || orgId === 'fsf' || orgId === 'ffa') {
          if (!account.countyId || (!account.pin && !stateSettings.noPin)) {

            await runModal(({resolve, reject}) =>
                <SessionContext.Provider value={this.sessionContext}>
                  <UpdateCountyIdAndPin
                      oldPin={account.pin}
                      oldCountyId={account.countyId}
                      resolve={resolve}
                      reject={reject} account={account}/>
                </SessionContext.Provider>)
                .then(({countyId, pin}) => {
                  account.countyId = countyId;
                  account.pin = pin;
                }, async (ignored) => {
                  await this.logout();
                  window.location.reload();
                });
          }
        }
        if (orgId === 'nss') {
          if (!(account && account.countyId)) {

            await runModal(({resolve, reject}) =>
                <SessionContext.Provider value={this.sessionContext}>
                  <UpdateCountyIdAndPin
                      orgId={orgId}
                      oldCountyId={account.countyId}
                      resolve={resolve}
                      reject={reject} account={account}/>
                </SessionContext.Provider>)
                .then(({countyId}) => {
                  account.countyId = countyId;
                }, async () => {
                  await this.logout();
                  window.location.reload();
                });
          }
        }
      }

      if ((account.accountType === 'StateAdmin' || account.accountType === 'CountyAdmin' || account.accountType === 'StateUser' || account.accountType === 'DistrictAdmin') &&
          stateSettings?.adminPinRequired && !account.pin) {
        const {pin} = await runModalForm(() => <>
          <p>A PIN is required by your state for security purposes, but your account does not have one set up yet.
            Please enter a new PIN to create one.</p>
          <CustomFormInput id="pin" labelText="PIN" type="password" autoFocus/>
        </>).catch(async (ignored) => {
          await this.logout();
          window.location.reload();
        });
        account.pin = pin;
        await backendClient.updateAccount({id: account.id}, {...account});
      }

      if(account.accountType.endsWith('Admin')
          && (!newSessionData.settings.stateAdminChatOnly || account.accountType === 'StateAdmin')) {
        if(window.purechatApi) {
          // eslint-disable-next-line no-undef
          if(purechatApi.set) {
            await this.updatePureChatInfo(account, {}, newSessionData);
            // eslint-disable-next-line no-undef
            purechatApi.set('chatbox.visible', true);
          } else {
            // eslint-disable-next-line no-undef
            purechatApi.on('chatbox:ready', async () => {
              await this.updatePureChatInfo(account, {}, newSessionData);
              // eslint-disable-next-line no-undef
              purechatApi.set('chatbox.visible', true);
            });
          }
        }
      }

      if (account.accountType === 'Household' ||
          account.accountType === 'ShootingSportsVolunteer') {
        const householdMembers = newSessionData.householdMembers,
            lastSelectedMemberStr = window.sessionStorage.getItem(
                'currentHouseholdMember');
        if (!oldHouseholdMember && lastSelectedMemberStr) {
          oldHouseholdMember = JSON.parse(lastSelectedMemberStr);
        }
        if (oldHouseholdMember) {
          let newHouseholdMember;
          if (oldHouseholdMember.memberType === 'Primary') {
            newHouseholdMember = householdMembers.find(
                m => m.memberType === oldHouseholdMember.memberType);
          } else {
            newHouseholdMember = householdMembers.find(
                m => m.id === oldHouseholdMember.id);
          }
          if (newHouseholdMember) {
            await this.selectHouseholdMember(newHouseholdMember, account, newSessionData);
          }
        } else if (stateSettings.noPin) {
          await this.selectHouseholdMember(
              householdMembers.find(member => member.memberType === 'Primary'),
              account, newSessionData);
        } else if (householdMembers.length === 1) {
          // if only a single household member, select it
          await this.selectHouseholdMember(householdMembers[0], account, newSessionData);
        }
      }

      await this.sessionContext.updateSession(newSessionData);
    } catch (e) {
      console.error('Error while loading session', e);
    }
  }

  async signUp(firstName, lastName, email, password, pin,
               countyId, phone, address, optInSMS, phoneSMS) {
    const backend = this.sessionContext.backendClient,
        {orgId} = this.sessionContext;
    try {
      await backend.registerAccount({}, {
        firstName, lastName, email, password, photoUrl: randomProfileUrl(),
        pin, countyId, phone, address, orgId, optInSMS, phoneSMS,
      });
      await this.login(email, password);

    } catch (e) {
      const {errors} = await e.json();
      throw errors;
    }
  }

  async logout() {
    const {analyticsClient} = this.sessionContext;
    if (this.sessionContext.backendClient) {
      await this.sessionContext.backendClient.logout();
    }
    analyticsClient.send({
      action: 'setAccount',
      accountId: null,
      name: null,
      page: window.location.pathname,
    });
    await this.sessionContext.resetSession({
      account: {}, state: null, county: null, settings: {},
    });
    const value = window.localStorage.getItem('autoSave');
    window.localStorage.clear();
    window.sessionStorage.clear();
    if (value) {
      window.localStorage.setItem('autoSave', value);
    }
    // eslint-disable-next-line no-undef
    purechatApi.set('chatbox.visible', false);
  }

  // get the household members for a given account
  async getHouseholdMembers(account) {
    const {backendClient} = this.sessionContext,
        householdMembers = (await backendClient.listHouseholdMembers({
          accountId: account.id,
        })).sort((a, b) => {
          if (!a.id) {
            return 1;
          }
          if (!b.id) {
            return -1;
          }
          const result = a.lastName.toLowerCase()
              .localeCompare(b.lastName.toLowerCase());
          if (result === 0) {
            return a.firstName.toLowerCase()
                .localeCompare(b.firstName.toLowerCase());
          } else {
            return result;
          }
        });

    return [
      {
        firstName: account.firstName,
        lastName: account.lastName,
        photoUrl: account.photoUrl,
        memberType: 'Primary',
        accountId: account.id,
      }].concat(householdMembers);
  }

  // gets the household members and calls updateSession
  async reloadHouseholdMembers(account) {
    const {updateSession} = this.sessionContext;
    const householdMembers = await this.getHouseholdMembers(
        account || this.sessionContext.account);
    updateSession({householdMembers});
    return householdMembers;
  }

  async selectHouseholdMember(currentHouseholdMember, defAccount, sessionData) {
    const {updateSession, backendClient, orgId} = this.sessionContext;
    if (!currentHouseholdMember) {
      window.sessionStorage.removeItem('currentHouseholdMember');
      document.cookie = 'hmId=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'; // Used for OAuth and LMS integration
      return await updateSession({currentRecordBook: null, currentHouseholdMember: null});
    } else {
      window.sessionStorage.setItem('currentHouseholdMember', JSON.stringify(currentHouseholdMember));
      document.cookie = 'hmId=' + currentHouseholdMember.id + '; Path=/;'; // Used for OAuth and LMS integration
    }
    const {
      enrollment, clubRoleAssignments, projectLeaderAssignment, householdMember,
      accountSettings, countySettings, stateSettings
    } = await backendClient.currentSessionAccount({orgId, householdMemberId: currentHouseholdMember.id});

    await updateSession({
      currentRecordBook: null,
      currentHouseholdMember: currentHouseholdMember.memberType === 'Primary' ? currentHouseholdMember : householdMember,
      settings: {...accountSettings, county: countySettings, state: stateSettings},
      enrollment: enrollment !== null ? enrollment : null,
      clubRoleAssignments, projectLeaderTypes: projectLeaderAssignment?.projectTypes || []
    });
  }

  async loadCurrentEnrollment(currentHouseholdMember) {
    const {updateSession, backendClient, settings} = this.sessionContext;
    if (!currentHouseholdMember) {
      window.sessionStorage.removeItem('currentEnrollment');
    } else {
      const enrollments = await backendClient.listEnrollments(
          {
            accountId: currentHouseholdMember.accountId,
            householdMemberId: currentHouseholdMember.id,
          });
      for (let i = 0; i < enrollments.length; i++) {
        if (enrollments[i].status === 'Active' &&
            enrollments[i].year === current4HYear(settings?.state) &&
            (enrollments[i].householdMemberId === currentHouseholdMember.id ||
                (!enrollments[i].householdMemberId &&
                    !currentHouseholdMember.id))) {
          const clubEnrollments = await Promise.all(
              enrollments[i].clubEnrollments.map(async ce => {
                return {
                  ...ce,
                  club: await backendClient.findClub({id: ce.clubId}),
                };
              }));
          await updateSession(
              {currentEnrollment: {...enrollments[i], clubEnrollments}});
        }
      }
    }
  }

  async loadProjectLeaderAssignments(currentHouseholdMember, {state: stateSettings}) {
    const {updateSession, backendClient} = this.sessionContext;
    if (!currentHouseholdMember) {
      await updateSession({projectLeaderTypes: []});
    } else {
      await updateSession({
        projectLeaderTypes: (await backendClient.getProjectLeaderAssignment({
          accountId: currentHouseholdMember.accountId,
          householdMemberId: currentHouseholdMember.id,
          year: current4HYear(stateSettings),
        }).catch(() => ({})))?.projectTypes,
      });
    }
  }

  async loadClubRoleAssignments(currentHouseholdMember) {
    const {updateSession, backendClient, orgId} = this.sessionContext;
    if (!currentHouseholdMember) {
      await updateSession({clubRoleAssignments: []});
    } else {
      await updateSession({
        clubRoleAssignments: await backendClient.listClubRoleAssignments({
          accountId: currentHouseholdMember.accountId,
          householdMemberId: currentHouseholdMember.id,
          orgId,
        }),
      });
    }
  }

  async loadSettings(account, householdMember = {}, countyId, stateId) {
    const {backendClient, orgId} = this.sessionContext,
        accountSettings = await backendClient.getSettings({
          accountId: account.id, householdMemberId: householdMember.id, orgId,
        });
    let countySettings = {}, stateSettings = {};
    if (countyId) {
      countySettings = await backendClient.getCountySettings({countyId, orgId});
    }
    if (stateId) {
      stateSettings = await backendClient.getStateSettings({stateId, orgId});
    }

    return {
      ...accountSettings, county: countySettings,
      state: {
        ...stateSettings,
        countyLabel: stateSettings.countyLabel || 'County',
        districtLabel: stateSettings.districtLabel || 'District',
        clubLabel: stateSettings.clubLabel || 'Club',
      },
    };
  }

  async updatePureChatInfo(account, householdMember = {}, sessionData) {
    const firstName = householdMember.firstName || account.firstName,
        lastName = householdMember.lastName || account.lastName;
    const {orgId, state = {}, county = {}} = sessionData || {};


    if (account && window.purechatApi && window.purechatApi.set) {
      window.purechatApi.set('visitor.name', `${firstName} ${lastName}`);
      window.purechatApi.set('visitor.email', account.email);
      window.purechatApi.set('visitor.company',
          `Acct #: Z${leftPad(account.id, 5)}   Org: ${orgId}   Type: ${account.accountType}`);
      //console.log('Acct #: ' + 'Z' + leftPad(account.id, 5) + '   ' + 'Org: ' + orgId + '   ' + 'Type: ' + `${account.accountType}`);

      if (account.accountType === 'Household') {
        window.purechatApi.set('visitor.phoneNumber',
            `State: ${state.name}   County: ${county.name} ${householdMember.memberType}`);
        // console.log('State: ' + `${state.name}` + '   ' + 'County: ' + `${county.name}` + ' ' + `${householdMember.memberType}`);
      } else if (account.countyId) {
        window.purechatApi.set('visitor.phoneNumber',
            `State: ${state.name}   County: ${county.name}`);
        //console.log('State: ' + `${state.name}` + '   ' + 'County: ' + `${county.name}`);
      } else if (account.stateId) {
        window.purechatApi.set('visitor.phoneNumber',
            `State: ${state.name}`);
        //console.log('State: ' + `${state.name}`);
      } else {
        window.purechatApi.set('visitor.phoneNumber', 'Sys Admin Acct');
        //console.log('Sys Admin Acct');
      }
    }
  }

  verifyPin(pin) {
    return pin === this.sessionContext.account.pin;
  }

  async requiresPinVerification(householdMember, stateId) {
    const {backendClient, orgId} = this.sessionContext;
    const stateSettings = await backendClient.getStateSettings(
        {stateId, orgId});
    return (this.sessionContext.account.pin && householdMember.memberType !== 'ClubMember') && !stateSettings.noPin;
  }
}

export async function loginNonce(url) {
  const date = await fetch(url).then(resp => new Date(resp.headers.get('date')));
  return expand(swap(swap(swap(btoa(date.getTime().toString()), 5), 3), 1));
}

function expand(str) {
  let result = '';
  for(let i = 0; i < str.length; i++) {
    result += str.charCodeAt(i).toString(16);
  }
  return result;
}

function swap(str, size) {
  let result = '';
  let i;
  for (i = 0; i <= (str.length - (size * 2)); i += (size * 2)) {
    result += str.substring(i + size, i + (size * 2));
    result += str.substring(i, i + size);
  }
  return result + str.substring(i);
}