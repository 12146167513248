// Shared helper functions
import moment from 'moment';
import {QuillDeltaToHtmlConverter} from 'quill-delta-to-html';

/**
 * Flattens a multi-level array. We use this instead of the Arrays.flatMap() function because this works on Edge
 *
 * @param {*[]} array - Array to be flattened
 * @returns {*[]}
 */
export function flatMap(array) {
  if (Array.isArray(array)) {
    return array.reduce((t, a) => t.concat(flatMap(a)), []);
  } else {
    return array;
  }
}

/**
 * Gets the 4H year based on the given date and the start of the year
 *
 * @param {string|Date|number|moment.Moment} date
 * @param {string|Date|number|moment.Moment?} [startOfYear='08/02']
 * @returns {number}
 */
export function get4HYear(date, startOfYear) {
  const mDate = moment(date).startOf('day');
  const startOf4HYear = startOfYear || moment(date).month(8).day(1);
  if (mDate.isBefore(startOf4HYear)) {
    return mDate.add(-1, 'year').year();
  } else {
    return mDate.year();
  }
}

/**
 * Returns the current 4H year based on the state settings
 *
 * @param {StateSettings?} stateSettings
 * @returns {number}
 */
export function current4HYear(stateSettings) {
  if (stateSettings) {
    return get4HYear(moment.now(), moment([
      moment().year(),
      stateSettings.yearStartMonth, stateSettings.yearStartDay]));
  } else {
    return get4HYear(moment.now());
  }
}

export function formatEnrollmentAge(stateSettings, enrollmentYear) {
  const fourHAgeMonth = stateSettings.fourHAgeAsOfMonth || "08",
      fourHAgeDay = stateSettings.fourHAgeAsOfDay || "01";
  const fourHAgeYear = enrollmentYear + (stateSettings.fourHAgeAsOfYear || 0);
  return fourHAgeMonth + "/" + fourHAgeDay + "/" + fourHAgeYear
}

export function formatBoolean(value) {
  switch (value) {
    case true:
      value = 'Yes';
      break;
    case false:
      value = 'No';
      break;
    default:
      break;
  }
  return value
}

export function formatBooleanTrueFalse(value) {
  switch (value) {
    case 't':
    case true:
      value = 'True';
      break;

    case 'f':
    case false:
      value = 'False';
      break;
    default:
      break;
  }
  return value
}

/* Gets the status and formats it to proper text */
export function formatEnrollmentStatus(status) {
  console.log("STATUS", status)
  switch (status) {
    case 'PendingStateApproval':
      status = 'Pending State Approval';
      break;

    case 'InProgress':
      status = 'In Progress';
      break;

    case 'PendingApproval':
      status = 'Pending Approval';
      break;

    case 'PendingAdminApproval':
      status = 'Pending Admin Approval';
      break;

    case 'Active':
      status = 'Active';
      break;

    case 'PendingPayment':
      status = 'Pending Payment';
      break;

    case 'Canceled':
      status = 'Canceled';
      break;

    case 'Returned':
      status = 'Returned';
      break;

    case 'Ineligible':
      status = 'Ineligible';
      break;

    case 'NotParticipating':
      status = 'Not Participating';
      break;

    default:
      status = status;
      break;

  }
  return status

}

/* Gets the member type and formats it to proper text */
export function formatMemberType(memberType) {
  switch ((memberType || '').toUpperCase()) {
    case 'CLUBMEMBER':
      memberType = 'Club Member';
      break;
    case 'VOLUNTEER':
      memberType = 'Volunteer';
      break;
    case 'CLUBLEADER':
      memberType = 'Club Leader';
      break;
    case 'PROJECTLEADER':
      memberType = 'Project Leader';
      break;
    case 'VETTEDVOLUNTEER':
      memberType = 'Vetted Volunteer';
      break;
    case 'RETURNINGVOLUNTEER':
      memberType = 'Returning Volunteer';
      break;
    case 'STATEADMIN':
      memberType = 'State Admin';
      break;
    case 'COUNTYADMIN':
      memberType = 'County Admin';
      break;
    case 'DISTRICTADMIN':
      memberType = 'County Admin';
      break;
    case 'SYSADMIN':
      memberType = 'System Admin';
      break;
    default:
      break;
  }
  return memberType;
}


/* Gets the organization and formats it to proper text */
export function formatOrganization(organization) {
  switch ((organization || '').toUpperCase()) {
    case '4H':
      organization = '4-H';
      break;
    case 'FFA':
      organization = 'FFA';
      break;
    case 'NSS':
      organization = 'National Shooting Sports';
      break;
    case 'FSF':
      organization = 'Florida State Fair';
      break;
    case 'SASSR':
      organization = 'San Antonio Stock Show and Rodeo';
      break;
    default:
      break;
  }
  return organization;
}

/**
 * Formats a year into a year span for showing a 4H year
 *
 * @param {string|number} year - 4H year
 * @returns {string}
 *
 * @example
 * format4HYear('2019')
 * // returns '2019-2020'
 */
export function format4HYear(year) {
  year = parseInt(year);
  return year + ' - ' + (year + 1);
}

/**
 * Returns a formatted current4HYear
 *
 * @param {StateSettings?} stateSettings
 * @param {number?} [year=undefined]
 * @returns {string}
 */
export function formatCurrent4HYear(stateSettings, year = undefined) {
  year = year || current4HYear(stateSettings);
  return year + ' - ' + moment().year(year).add(1, 'year').year();
}

/**
 * Gets a local URL for a random avatar
 *
 * @returns {string}
 */
export function randomProfileUrl() {
  const num = 1 + Math.floor(Math.random() * Math.floor(25));
  return `/avatars/av${num}.jpg`;
}

/**
 * Returns an object based on an array of label/value pairs
 *
 * @param {{label: string, value: *}[]} [arr=[]]
 * @returns {Object}
 *
 * @example
 * labelValueArrayToObj([{label: 'JO', value: 12}])
 * // returns {'12': 'Jo'}
 */
export function labelValueArrayToObj(arr = []) {
  return arr.reduce((o, lv) => ({...o, [lv.value]: lv.label}), {});
}

/**
 * Takes an array of strings and returns an object with each label/value being a string
 *
 * @param {string[]} arr - Array of label/values
 * @returns {Object}
 *
 * @example
 * labelArrayToObj(['one'])
 * // returns {'one': 'one'}
 */
export function labelArrayToObj(arr = []) {
  return arr.reduce((o, l) => ({...o, [l]: l}), {});
}

/**
 * Joins two Paths/URLs together
 *
 * @param {string} a - First Path/URL
 * @param {string} b - Second Path/URL
 * @returns {string}
 *
 * @example
 * joinPath('/a/', '/b/')
 * // returns '/a/b/'
 */
export function joinPath(a, b) {
  if (!a && a !== 0) return b;
  if (!b && b !== 0) return a;
  return (a + '/' + b).replace(/\/+/g, '/');
}

export async function getImageAsDataUrl(url) {
  if (url && url.startsWith('data:')) {
    return url;
  }
  if (/\.(heic|pdf|mov|pptx?)$/i.test(url)) {
    return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
  }
  const blob = await fetch(url).then(r => {
    if (!r.ok) {
      throw new Error('Not Found: ' + url);
    } else {
      return r.blob();
    }
  });
  return await new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      res(reader.result);
    };
    reader.onerror = rej;
    reader.readAsDataURL(blob);
  });
}

/**
 * Loads a script from a URL
 *
 * @param {string} script - The URL of the script
 * @param {?boolean} replaceExisting - checks if script already exists, and deletes it if it does
 * @returns {Promise<*>}
 */
export function loadScriptTag(script, replaceExisting = false) {
  return new Promise((res, rej) => {
    const scriptEl = document.head.querySelector(
        'script[src="' + script + '"]');
    if (scriptEl && replaceExisting) {
      scriptEl.remove();
    } else if (scriptEl) {
      return window.setTimeout(res, 1000);
    }
    const ele = document.createElement('script');
    ele.setAttribute('type', 'text/javascript');
    ele.setAttribute('src', script);
    ele.onload = res;
    ele.onerror = rej;
    document.head.appendChild(ele);
  });
}

/**
 * Loads a stylesheet from a URL
 *
 * @param {string} style - The URL of the stylesheet
 */
export function loadStyleTag(style) {

  const styleEl = document.head.querySelector(
      'link[href="' + style + '"]');
  if (styleEl) {
    return;
  }
  const ele = document.createElement('link');
  ele.setAttribute('rel', 'stylesheet');
  ele.setAttribute('type', 'text/css');
  ele.setAttribute('src', style);
  document.head.appendChild(ele);
}

export function resolveRelativePath(path) {
  const prefix = process.env.REACT_APP_ROOT_PATH;
  return ((prefix ? (prefix + '/') : '') + path).replace(/(\/+)/g, '/');
}

/**
 * Sums a numeric property from objects in the array
 *
 * @param {T[]} array
 * @param {string} field
 * @returns {T}
 */
export function sum(array, field) {
  return (array || []).reduce(
      (acc, element) => acc + (parseFloat(element[field]) || 0), 0);
}

/**
 * Partitions an array into smaller arrays based on the size
 *
 * @param {T[]} array
 * @param {number} size
 * @param {?any} emptyValue
 * @returns {T[][]}
 *
 * @example
 * partition([1, 2, 3, 4, 5, 6, 7], 3)
 * // returns [[1,2,3], [4,5,6], [7,null,null]]
 */
export function partition(array, size, emptyValue = null) {
  const newArray = [];
  let newGroup = [];
  for (let i = 0; i < array.length; i++) {
    if (newGroup.length >= size) {
      newArray.push(newGroup);
      newGroup = [];
    }
    newGroup.push(array[i]);
  }
  if (newGroup.length) {
    for (let i = 0; i < size; i++) {
      newGroup[i] = newGroup[i] || emptyValue;
    }
    newArray.push(newGroup);
  }
  return newArray;
}

/**
 * formats a string or number into a money string
 *
 * @param {number|string|undefined|null} x
 * @returns {string}
 */
export function formatMoney(x) {
  if (x === null || x === undefined) {
    x = 0;
  }
  if (typeof x === 'string') {
    x = parseFloat(x);
  }
  return !isNaN(x) ? `$${x.toFixed(2)}` : '';
}

/**
 * formats a string into a date
 *
 * @param {string|Date} x
 * @returns {string}
 */
export function formatDate(x) {
  if (!x) {
    return '';
  }
  return moment(x).format('MM-DD-YYYY');
}

/**
 * formats a string into a datetime
 *
 * @param {string|Date} x
 * @returns {string}
 */
export function formatDateTime(x) {
  if (!x) {
    return '';
  }
  return moment(x).format('M/D/YYYY hh:mm A');
}

/**
 * formats a string into a year
 *
 * @param {string|Date} x
 * @returns {string}
 */
export function formatYear(x) {
  if (!x) {
    return '';
  }
  return moment(x).format('YYYY');
}

/**
 * Formats a number into a weight and adds 'lbs' to the end
 * Strings are attempted to parse into a number
 *
 * @param {number|string} x
 * @returns {string}
 */
export function formatWeight(x) {
  if (typeof x === 'string') {
    x = parseFloat(x);
  }
  return !isNaN(x) ? `${x.toFixed(2)} lbs` : '';
}

// field = string (name of field)
// arr = array of rows ex: inventoryitem
// opts = object of desc and date
/**
 * Sorts an array of objects by a specified field
 *
 * @param {T[]} array - The array of objects to be sorted
 * @param {string} field - The name of the field
 * @param {Object} [opts={}] - Optional options
 * @param {boolean} [opts.date] - Determines whether or not the field values are dates
 * @param {boolean} [opts.desc] - Determines if the sorting order is descending or not
 * @returns {*}
 */
export function sortBy(field, array, opts = {}) {
  const {date, desc} = opts;
  return array.sort(function (_a, _b) {
    let a = _a, b = _b;
    if (desc) {
      a = _b;
      b = _a;
    }
    if (date) {
      return moment(a[field]).diff(moment(b[field]), 'minutes');
    }
    return a[field].toString().localeCompare(b[field].toString());
  });
}

/**
 * Calculates the amount of depreciation given the number of years and initial cost
 *
 * @param {number} years - The number of years to calculate
 * @param {number} cost - The initial cost
 * @returns {number}
 */
function calcDep(years, cost) {
  return Math.max(0, years * cost * 0.1);
}

// Compute inventory depreciation given a project and
// an array of inventoryItems
export function computeDepreciation(project) {
  return inventoryItems =>
      sortBy('acquiredOn', inventoryItems.map(inventoryItem => {
        const cost = inventoryItem.purchaseCost || 0,
            acquiredY = moment(inventoryItem.acquiredOn).year(),
            startY = moment(project.startsOn || undefined).year(),
            endY = Math.max(moment(project.endsOn || undefined).year(),
                startY + 1),
            startValue = cost - calcDep(startY - acquiredY - 1, cost),
            dep = calcDep(endY - startY, cost),
            endValue = startValue - dep,
            currentValue = endValue;

        return Object.assign({}, inventoryItem,
            {dep, startValue, endValue, currentValue});
      }, {date: true}));
}

export function breakeven(project) {
  return inventoryItems =>
      sortBy('acquiredOn', inventoryItems.map(inventoryItem => {
        const totalExpenses = inventoryItem.totalExpenses || 0,
            finalWeight = inventoryItem.finalWeight || 0,
            breakeven = totalExpenses / finalWeight;
        return Object.assign({}, inventoryItem, {breakeven});
      }, {date: true}));
}

export function formatDateRange(start, end) {
  const mStart = moment(start), mEnd = moment(end);
  if (mStart.diff(end, 'days') === 0) {
    return mStart.format('MMM Do h:mma') + ' - ' + mEnd.format('h:mma');
  }
  return mStart.format('MMM Do h:mma') + ' - ' + mEnd.format('MMM Do h:mma');
}

/**
 * Returns unique elements in an array
 *
 * @param {T[]} a - Array of elements
 * @returns {T[]}
 */
export function uniq(a = []) {
  const seen = {};
  return a.filter(function (item) {
    return seen.hasOwnProperty(item) ? false : (seen[item] = true);
  });
}

/**
 * Turns an input string into camelCase
 *
 * @param {string} str - String to replace
 * @returns {string}
 */
export function toCamelCase(str) {
  return str?.toString()?.toLowerCase()
      .replace(/[^a-z0-9 ]/ig, '')
      .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
        if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? (match || '').toLowerCase() : (match || '').toUpperCase();
      });
}

/**
 * Turns an input string into kebab-case
 *
 * @param {string} str - String to replace
 * @returns {string}
 */
export function toKebabCase(str) {
  return str.trim().toLowerCase().replace(/\s+/g, '-')
      .replace(/-+/g, '-');
}

/**
 * Loads a stripe script instance into the HTML DOM
 *
 * @param {BackendClient} backendClient
 * @returns {Promise<*|void>}
 */
export function loadStripeInstance(backendClient) {
  return new Promise((resolve, reject) => {
    if (window.stripeInstance) {
      resolve(window.stripeInstance);
    } else {
      backendClient.getStripePublicKey().then(stripePublicKey => {
        const el = document.querySelector('#stripe-js');
        if (!el) {
          const newScript = document.createElement('script');
          newScript.id = 'stripe-js';
          newScript.src = 'https://js.stripe.com/v3/';
          newScript.onload = () => {
            // Create Stripe instance once Stripe.js loads
            window.stripeInstance = window.Stripe(stripePublicKey);
            resolve(window.stripeInstance);
          };
          newScript.onerror = reject;
          document.body.append(newScript);
        }
      }, reject);
    }
  });
}

export function computeStripeFee(amount) {
  // Use multiple rounds to ensure accuracy (tested up to 200k before decreasing)
  const amountWithFee1 = amount + ((0.029 * amount) + 0.65),
      amountWithFee2 = amount + ((0.029 * amountWithFee1) + 0.65),
      amountWithFee3 = amount + ((0.029 * amountWithFee2) + 0.65),
      fee = ((0.029 * amountWithFee3) + 0.65);

  // Round up to two decimal places
  return Math.round(100 * fee) / 100;
}

/**
 * Wraps a string with newLines based on a specified width
 *
 * @param {string} str
 * @param {number} maxWidth
 * @returns {string}
 */
export function wordWrap(str, maxWidth) {
  var newLineStr = '\n', res = '';

  function testWhite(x) {
    var white = new RegExp(/^\s$/);
    return white.test(x.charAt(0));
  }

  while (str.length > maxWidth) {
    let found = false;
    // Inserts new line at first whitespace of the line
    for (let i = maxWidth - 1; i >= 0; i--) {
      if (testWhite(str.charAt(i))) {
        res = res + [str.slice(0, i), newLineStr].join('');
        str = str.slice(i + 1);
        found = true;
        break;
      }
    }
    // Inserts new line at maxWidth position, the word is too long to wrap
    if (!found) {
      res += [str.slice(0, maxWidth), newLineStr].join('');
      str = str.slice(maxWidth);
    }
  }

  return res + str;
}

/**
 * Pads a number with a character based on a specified number of digits needed
 *
 * @param {number} num - Number to pad with the character
 * @param {number} len - Number of digits needed
 * @param {string} [c='0'] - Padding character
 * @returns {string}
 */
export function leftPad(num, len, c = '0') {
  if (num !== 0 && !num) return '';
  let s = num.toString();
  while (s.length < len) s = c + s;
  return s;
}

/**
 * Pluralizes a few different strings
 *
 * @param {string} str
 * @returns {string}
 */
export function pluralize(str) {
  let finalString;
  switch (str) {
    case 'County':
      finalString = 'Counties';
      break;
    case 'Youth':
      return 'Youth';
    default:
      finalString = str + 's';
  }

  return finalString;
}

/**
 * creates a function that will wait the call
 * to the passed function until after a given
 * amount of time (msDelay). If the function
 * is called again before the delay time then
 * the function is not called, but rescheduled.
 *
 * copied from lodash (https://github.com/lodash/lodash)
 * @param {Function} func - function to call
 * @param {number} wait - delay time in ms
 * @param {?Object} [_this=undefined] - this to apply to function
 * @returns {Function}
 */
export function debounce(func, wait, _this = undefined) {
  var lastArgs,
      maxWait,
      result,
      timerId,
      lastCallTime,
      lastInvokeTime = 0,
      leading = false,
      maxing = false,
      trailing = true;

  if (typeof func != 'function') {
    throw new TypeError(func + ' is not a function');
  }
  wait = parseInt(wait) || 0;

  /*if (isObject(options)) {
    leading = !!options.leading;
    maxing = 'maxWait' in options;
    maxWait = maxing ? nativeMax(toNumber(options.maxWait) || 0, wait) : maxWait;
    trailing = 'trailing' in options ? !!options.trailing : trailing;
  }*/

  function invokeFunc(time) {
    var args = lastArgs;

    lastArgs = undefined;
    lastInvokeTime = time;
    result = func.apply(_this, args);
    return result;
  }

  function leadingEdge(time) {
    // Reset any `maxWait` timer.
    lastInvokeTime = time;
    // Start the timer for the trailing edge.
    timerId = setTimeout(timerExpired, wait);
    // Invoke the leading edge.
    return leading ? invokeFunc(time) : result;
  }

  function remainingWait(time) {
    var timeSinceLastCall = time - lastCallTime,
        timeSinceLastInvoke = time - lastInvokeTime,
        timeWaiting = wait - timeSinceLastCall;

    return maxing
        ? Math.min(timeWaiting, maxWait - timeSinceLastInvoke)
        : timeWaiting;
  }

  function shouldInvoke(time) {
    var timeSinceLastCall = time - lastCallTime,
        timeSinceLastInvoke = time - lastInvokeTime;

    // Either this is the first call, activity has stopped and we're at the
    // trailing edge, the system time has gone backwards and we're treating
    // it as the trailing edge, or we've hit the `maxWait` limit.
    return (lastCallTime === undefined || (timeSinceLastCall >= wait) ||
        (timeSinceLastCall < 0) || (maxing && timeSinceLastInvoke >= maxWait));
  }

  function timerExpired() {
    var time = Date.now();
    if (shouldInvoke(time)) {
      return trailingEdge(time);
    }
    // Restart the timer.
    timerId = setTimeout(timerExpired, remainingWait(time));
  }

  function trailingEdge(time) {
    timerId = undefined;

    // Only invoke if we have `lastArgs` which means `func` has been
    // debounced at least once.
    if (trailing && lastArgs) {
      return invokeFunc(time);
    }
    lastArgs = undefined;
    return result;
  }

  function cancel() {
    if (timerId !== undefined) {
      clearTimeout(timerId);
    }
    lastInvokeTime = 0;
    lastArgs = lastCallTime = timerId = undefined;
  }

  function flush() {
    return timerId === undefined ? result : trailingEdge(Date.now());
  }

  function debounced() {
    var time = Date.now(),
        isInvoking = shouldInvoke(time);

    lastArgs = arguments;
    lastCallTime = time;

    if (isInvoking) {
      if (timerId === undefined) {
        return leadingEdge(lastCallTime);
      }
      if (maxing) {
        // Handle invocations in a tight loop.
        timerId = setTimeout(timerExpired, wait);
        return invokeFunc(lastCallTime);
      }
    }
    if (timerId === undefined) {
      timerId = setTimeout(timerExpired, wait);
    }
    return result;
  }

  debounced.cancel = cancel;
  debounced.flush = flush;
  return debounced;
}

/**
 * Does the same thing debounce does, but it returns a value
 * @param {function(): T} fn - function to call
 * @param {number} msDelay - delay time in ms
 * @param {?Object} [_this=undefined] - this to apply to function
 * @returns {function(): Promise<T>}
 */
export function debouncePromise(fn, msDelay, _this = undefined) {
  let timeout;
  return function () {
    return new Promise((resolve, reject) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => fn.apply(_this, arguments).then(resolve, reject), msDelay);
    });
  };
}

/**
 * Extract the error message from a thrown response
 * or return a default message
 * @param e - Error Object
 * @param defaultMessage - a default message in case of no errors
 * @returns {Promise<String>}
 */
export async function extractError(e, defaultMessage) {
  if (e && e.json) {
    const body = await e.json(),
        message = body ?
            body.message || body.error ||
            (body.errors ? body.errors.join(', ') : null)
            : null;
    return message || defaultMessage;
  } else if (e?.message) {
    return e.message;
  } else {
    return defaultMessage;
  }
}

export function randomString(
    length,
    chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ') {
  let result = '';
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

/**
 * Formats and downloads an array of arrays as a text/csv file
 * @param rows - an array of arrays
 * @param fileName - a filename for the downloaded file
 */
export function downloadAsCSV(rows, fileName) {
  const csvContent = rows.map(row =>
      row.map(r => '"' + (r ? r.toString() : '')
              .replace(/"/g, '""')
              .replace(/<p>/g, "")
              .replace(/<\/p>/g, "")
              .replace(/<em>/g, "")
              .replace(/<\/em>/g, "")
              .replace(/<b>/g, "")
              .replace(/<\/b>/g, "")
              .replace(/<i>/g, "")
              .replace(/<\/i>/g, "")
              .replace(/<u>/g, "")
              .replace(/<\/u>/g, "")
              .replace(/<strong>/g, "")
              .replace(/<\/strong>/g, "")
              .replace(/<s>/g, "")
              .replace(/<\/s>/g, "")
              .replace(/<sub>/g, "")
              .replace(/<\/sub>/g, "")
          + '"',
      ).join(','),
  ).join('\r\n');

  const csvBlob = new Blob([csvContent], {type: 'text/csv;charset=utf-8'}),
      csvUrl = URL.createObjectURL(csvBlob);

  const link = document.createElement('a');
  link.href = csvUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * returns the correct suffix of a number, e.g. in 1st, 2nd, 3rd etc
 * @param i - number to get suffix of
 * @returns {string}
 */
export function ordinalSuffixOf(i) {
  let j = i % 10,
      k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
}

export function formatAddress(address, lineBreak = '\n') {
  const {
    street1 = '', street2 = '', city = '', state = '', zip = '',
  } = address || {};

  return street1 + (street2 ? (lineBreak + street2) : '')
      + lineBreak + city + (state ? (', ' + state) : '') + ' ' + zip;
}

export function formatState(state) {
  switch ((state || '').toUpperCase()) {
    case 'ALABAMA':
      state = 'AL';
      break;
    case 'ALASKA':
      state = 'AL';
      break;
    case 'ARIZONA':
      state = 'AZ';
      break;
    case 'ARKANSAS':
      state = 'AR';
      break;
    case 'CALIFORNIA':
      state = 'CA';
      break;
    case 'COLORADO':
      state = 'CO';
      break;
    case 'CONNECTICUT':
      state = 'CT';
      break;
    case 'DELAWARE':
      state = 'DE';
      break;
    case 'FLORIDA':
      state = 'FL';
      break;
    case 'GEORGIA':
      state = 'GA';
      break;
    case 'HAWAII':
      state = 'HI';
      break;
    case 'IDAHO':
      state = 'ID';
      break;
    case 'ILLINOIS':
      state = 'IL';
      break;
    case 'INDIANA':
      state = 'IN';
      break;
    case 'IOWA':
      state = 'IA';
      break;
    case 'KANSAS':
      state = 'KS';
      break;
    case 'KENTUCKY':
      state = 'KY';
      break;
    case 'LOUISIANA':
      state = 'LA';
      break;
    case 'MAINE':
      state = 'ME';
      break;
    case 'MARYLAND':
      state = 'MD';
      break;
    case 'MASSACHUSETTS':
      state = 'MA';
      break;
    case 'MICHIGAN':
      state = 'MI';
      break;
    case 'MINNESOTA':
      state = 'MN';
      break;
    case 'MISSISSIPPI':
      state = 'MS';
      break;
    case 'MISSOURI':
      state = 'MO';
      break;
    case 'MONTANA':
      state = 'MT';
      break;
    case 'NEBRASKA':
      state = 'NE';
      break;
    case 'NEVADA':
      state = 'NV';
      break;
    case 'NEW HAMPSHIRE':
      state = 'NH';
      break;
    case 'NEW JERSEY':
      state = 'NJ';
      break;
    case 'NEW MEXICO':
      state = 'NM';
      break;
    case 'NEW YORK':
      state = 'NY';
      break;
    case 'NORTH CAROLINA':
      state = 'NC';
      break;
    case 'NORTH DAKOTA':
      state = 'ND';
      break;
    case 'OHIO':
      state = 'OH';
      break;
    case 'OKLAHOMA':
      state = 'OK';
      break;
    case 'OREGON':
      state = 'OR';
      break;
    case 'PENNSYLVANIA':
      state = 'PA';
      break;
    case 'RHODE ISLAND':
      state = 'RI';
      break;
    case 'SOUTH CAROLINA':
      state = 'SC';
      break;
    case 'SOUTH DAKOTA':
      state = 'SD';
      break;
    case 'TENNESSEE':
      state = 'TN';
      break;
    case 'TEXAS':
      state = 'TX';
      break;
    case 'UTAH':
      state = 'UT';
      break;
    case 'VERMONT':
      state = 'VT';
      break;
    case 'VIRGINIA':
      state = 'VA';
      break;
    case 'WASHINGTON':
      state = 'WA';
      break;
    case 'WEST VIRGINIA':
      state = 'WV';
      break;
    case 'WISCONSIN':
      state = 'WI';
      break;
    case 'WYOMING':
      state = 'WY';
      break;
    case 'DISTRICT OF COLUMBIA':
      state = 'DC';
      break;

    default:
      state = (state || '').toUpperCase();
      break;
  }
  return state;
}

export function checkEmail(state, email) {
  // Added formatted section to manage filter and edited report to utilize the formatted version
  // console.log('Filter State: ', state)
  // console.log('Filter Email: ', email)
  if ((state === 'US-CA') || (state === 'US-CA-DEMO')) {
    if (email.includes('@zingbooks.com')) {
      // console.log('filter')
      return '';
    } else {
      return email;
    }
  } else {
    return email;
  }
};


export function formatPhone(phone) {
  //Filter only numbers from the input
  let cleaned = ('' + phone).replace(/\D/g, '');

  let match = [];


  if (cleaned.length === 11) {
    match = cleaned.match(/^(\d)(\d{3})(\d{3})(\d{4})$/);
    return match[1] + '-' + match[2] + '-' + match[3] + '-' + match[4];

  }

  if (cleaned.length === 10) {
    match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match[1] + '-' + match[2] + '-' + match[3];

  }

  if (cleaned.length === 7) {
    match = cleaned.match(/^(\d{3})(\d{4})$/);
    return match[1] + '-' + match[2];

  }


  //Check if the input is of correct length

  // if (match) {
  //   return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  // }

  return null;
}

export function createURL(base, params) {
  const query = Object.entries(params).map(([k, v]) =>
      encodeURIComponent(k) + '=' + encodeURIComponent(v)).join('&');
  return query ? (base + '?' + query) : base;
}

export function addHttpToUrl(url) {
  const test = /^https?:\/\//;
  if (!test.test(url)) {
    return 'http://' + url;
  }

  return url;
}

export function getJSONLocalStorage(key) {
  try {
    return JSON.parse(window.localStorage.getItem(key) || '{}');
  } catch (ignored) {
    return {};
  }
}

export function setJSONLocalStorage(key, value) {
  const oldValue = getJSONLocalStorage(key);
  window.localStorage.setItem(key, JSON.stringify({...oldValue, ...value}));
}

export function useQuery(location) {
  return new URLSearchParams(location.search);
}

export function optionallyConvertQuillToHTML(deltaOps) {
  if (isJson(deltaOps)) {
    return new QuillDeltaToHtmlConverter(JSON.parse(deltaOps)['ops'], {inlineStyles: true}).convert();
  }

  // If it's not JSON, we assume it's been converted to HTML already
  return deltaOps;
}

export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function meetsMinAgeRequirement(minAge, birthday, stateSettings) {
  const stateAgeDate = moment(stateSettings.fourHAgeAsOfMonth + "/" + stateSettings.fourHAgeAsOfDay + "/" + (current4HYear(stateSettings) + (stateSettings.fourHAgeAsOfYear || 0)));
  // const age = moment()
  //     .day(stateSettings.ageAsOfDay)
  //     .month(stateSettings.ageAsOfMonth - 1)
  //     .diff(birthday, 'years');
  //     .year(current4HYear(stateSettings) + (stateSettings.ageAsOfYear || 0))
  const age = stateAgeDate.diff(birthday, "y", false);
  return age >= minAge;
}


export function meetsMinAgeRequirementWithAsOfDate(minAge, birthday, ageAsOfDate) {
  const age = moment(ageAsOfDate).diff(birthday, 'years');
  return age >= minAge;
}

export function meetsMaxAgeRequirement(maxAge, birthday, stateSettings) {
  const stateAgeDate = moment(stateSettings.fourHAgeAsOfMonth + "/" + stateSettings.fourHAgeAsOfDay + "/" + (current4HYear(stateSettings) + (stateSettings.fourHAgeAsOfYear || 0)));
  // const age = moment()
  //     .day(stateSettings.ageAsOfDay)
  //     .month(stateSettings.ageAsOfMonth - 1)
  //     .year(current4HYear(stateSettings) + (stateSettings.ageAsOfYear || 0))
  //     .diff(birthday, 'years');
  const age = stateAgeDate.diff(birthday, "y", false);
  return age <= maxAge;
}


export function meetsEnrollmentMinAgeRequirement(minAge, birthday, stateSettings) {

  const ageAsOfMonthSet = !!(stateSettings.ageAsOfMonth && stateSettings.ageAsOfDay);
  console.log("MEETS ENROLLMENT MIN AGE REQUIREMENT AGE AS OF MONTH SET", ageAsOfMonthSet);

  const stateAgeDate = ageAsOfMonthSet ?
      moment(stateSettings.ageAsOfMonth + "/" + stateSettings.ageAsOfDay + "/" + (current4HYear(stateSettings) + (stateSettings.ageAsOfYear || 0))) :
      moment(stateSettings.fourHAgeAsOfMonth + "/" + stateSettings.fourHAgeAsOfDay + "/" + (current4HYear(stateSettings) + (stateSettings.fourHAgeAsOfYear || 0)))

  ;
  // const age = moment()
  //     .day(stateSettings.ageAsOfDay)
  //     .month(stateSettings.ageAsOfMonth - 1)
  //     .diff(birthday, 'years');
  //     .year(current4HYear(stateSettings) + (stateSettings.ageAsOfYear || 0))
  const age = stateAgeDate.diff(birthday, "y", false);
  return age >= minAge;
}

export function meetsEnrollmentMaxAgeRequirement(maxAge, birthday, stateSettings) {
  const ageAsOfMonthSet = !!(stateSettings.ageAsOfMonth && stateSettings.ageAsOfDay);
  console.log("MEETS ENROLLMENT MAX AGE REQUIREMENT AGE AS OF MONTH SET", ageAsOfMonthSet);


  const stateAgeDate = ageAsOfMonthSet ?
      moment(stateSettings.ageAsOfMonth + "/" + stateSettings.ageAsOfDay + "/" + (current4HYear(stateSettings) + (stateSettings.ageAsOfYear || 0))) :
      moment(stateSettings.fourHAgeAsOfMonth + "/" + stateSettings.fourHAgeAsOfDay + "/" + (current4HYear(stateSettings) + (stateSettings.fourHAgeAsOfYear || 0)))
  ;
  // const age = moment()
  //     .day(stateSettings.ageAsOfDay)
  //     .month(stateSettings.ageAsOfMonth - 1)
  //     .year(current4HYear(stateSettings) + (stateSettings.ageAsOfYear || 0))
  //     .diff(birthday, 'years');
  const age = stateAgeDate.diff(birthday, "y", false);
  return age <= maxAge;
}


export function meetsWVAgeRequirement(birthday, stateSettings) {

  const juneDate = moment("06" + "/" + "30" + "/" + current4HYear(stateSettings));
  //const septemberDate = moment("09"+"/"+"30"+"/"+current4HYear(stateSettings));

  const ageAsOfJune = juneDate.diff(birthday, "y", false);
  //const ageAsOfSeptember = septemberDate.diff(birthday, "y", false);

  if (ageAsOfJune >= 8) {
    return false
  } else if (ageAsOfJune >= 5) {
    return true
  }

}

export function meetsWVSignupAgeRequirement(birthday, stateSettings) {

  const juneDate = moment("06" + "/" + "30" + "/" + current4HYear(stateSettings));
  const septemberDate = moment("09" + "/" + "30" + "/" + (current4HYear(stateSettings) + 1));
  //const septemberDate = moment("09"+"/"+"30"+"/"+current4HYear(stateSettings));

  const ageAsOfJune = juneDate.diff(birthday, "y", false);
  const ageAsOfSeptember = septemberDate.diff(birthday, "y", false);

  // console.log("JUNE DATE", juneDate);
  // console.log("SEPTEMBER DATE", septemberDate);
  // console.log("AGE AS OF JUNE", ageAsOfJune);
  // console.log("AGE AS OF SEPTEMBER", ageAsOfSeptember);


  return ageAsOfJune >= 5 && ageAsOfSeptember <= 21;

}


// export function meetsWVMaxAgeRequirement(maxAge, birthday, stateSettings) {
//   console.log("WV MAX AGE REQUIREMENT STATE SETTINGS", stateSettings);
//
//   const juneDate = moment("06"+"/"+"30"+"/"+current4HYear(stateSettings));
//   const septemberDate = moment("09"+"/"+"30"+"/"+current4HYear(stateSettings));
//   console.log("JUNE DATE", juneDate);
//   console.log("SEPTEMBER DATE", septemberDate);
//   const ageAsOfJune = juneDate.diff(birthday, "y", false);
//   const ageAsOfSeptember = septemberDate.diff(birthday, "y", false);
//   console.log("AGE AS OF JUNE", ageAsOfJune);
//   console.log("AGE AS OF SEPTEMBER", ageAsOfSeptember);
//   if(ageAsOfJune >= 5 && ageAsOfSeptember <=8){
//     return ageAsOfJune < 8
//   }
//   if(ageAsOfJune >= 8 && ageAsOfSeptember <=8){
//     return ageAsOfSeptember <= maxAge
//   }
//
// }

export function meetsMaxAgeRequirementWithAsOfDate(maxAge, birthday, ageAsOfDate) {
  const age = moment(ageAsOfDate).diff(birthday, 'years');
  return age <= maxAge;
}

export function validateNumber(input) {
  return !input || /^\d+$/g.test(input);
}

export function validateDate(input) {
  const mDate = moment(input);
  return (mDate.isValid() && mDate.year() > 1800);
}

export function validateEmail(input) {
  return /^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/gm.test(input);
}

export function validatePhoneNumber(input) {
  const phone_number_with_area = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/gm;
  const phone_number = /^\d{3}-\d{4}$/gm;
  return phone_number_with_area.test(input) || phone_number.test(input);
}

export function validateSMSNumber(input) {
  if (!/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/gm.test(input)) {
    return false;
  }
  const length = input.replace(/^\d/g, '').length;
  return length === 10 || length === 11;
}

/**
 * polyfill for browsers that don't support Blob.arrayBuffer()
 *
 * @param {Blob} blob
 * @returns {Promise<ArrayBuffer>}
 */
export async function blobToArrayPolyfill(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('loadend', () => resolve(reader.result));
    reader.addEventListener('error', () => reject(reader.error));
    reader.readAsArrayBuffer(blob);
  });
}

/**
 * Gets a nested element of an object based off of a list of string keys
 *
 * @param {Array<string>} path
 * @param {Object} obj
 *
 * @return {any} - if the nested object is defined and found
 * @return {undefined} - if the nested object doesn't exist
 */
export function getNestedElementByPath(path, obj) {
  return path.reduce((xs, x) => xs?.[x], obj);
}

export function round(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

/**
 * Combine keys in an object based on key aliases
 * @param {Record<string, number>} object
 * @param {Record<string, string>} aliases
 * @returns {Record<string, number>}
 */
export function sumKeyAliases(object, aliases = {}) {
  let result = {};
  for (let key of Object.keys(object || {})) {
    if (aliases[key]) {
      result[aliases[key]] = (result[aliases[key]] || 0) + object[key];
    } else {
      result[key] = (result[key] || 0) + object[key];
    }
  }
  return result;
}

/**
 * Merge demographic object with any aliases, e.g. raceSelect and race
 * @param {string} key - key in the demographic info you want to merge things into
 * @param {Record<string, number>} object - demographic info
 * @param {Record<string, string>} aliases - aliases for the key
 * @returns {Record<string, number>}
 */
export function mergeWithAliases(key, object, aliases = {}) {
  return [
    object[key], ...Object.keys(aliases).filter(k => aliases[k] === key).map(k => object[k])
  ].reduce((result, obj) => mergeObjects(result, obj));
}

/**
 * Merges two objects, summing keys that are the same on both objects
 * @param {Record<string, number>} object1
 * @param {Record<string, number>} object2
 * @returns {Record<string, number>}
 */
export function mergeObjects(object1 = {}, object2 = {}) {
  let result = {...object1};
  for (let key of Object.keys(object2)) {
    if (result[key]) {
      result[key] = result[key] + object2[key];
    } else {
      result[key] = object2[key];
    }
  }
  return result;
}

/**
 * Adds two numbers together with a fixed precision of 2 decimal points.
 * i.e. fixedAdd(0.1, 0.2) returns 0.3 not 0.30000000000000004 (like 0.1 + 0.2 does)
 * @param {number} a
 * @param {number} b
 * @returns {number} a + b
 */
export function fixedAdd(a, b) {
  return (((a * 1000) | 0) + ((b * 1000) | 0)) / 1000;
}

/**
 * formats a number with commas, i.e. 1000 becomes 1,000
 * @param {number} value
 * @returns {string}
 */
export function numberWithCommas(value) {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export function arCountyCostCenterCodes(countyId, stateId) {
  let costCenter = [];

  //EXAMPLE COUNTY COST CENTER CODE TEST (USES THE COST CENTER VALUES FOR ARKANSAS COUNTY DO NOT USE)
  // if(countyId === 4002){
  //   costCenter = [{id:"CC013007", label:"CC013007"}]
  // }

  if (stateId === 'US-AR' || countyId === 4002) {
    costCenter = [
      {id: "CC013046", label: "CC013046"},
      {id: "CC013048", label: "CC013048"},
      {id: "CC013054", label: "CC013054"},
      {id: "CC013055", label: "CC013055"},
      {id: "CC013058", label: "CC013058"}
    ]
  }

  //ARKANSAS COUNTY
  if (countyId === 560) {
    costCenter = [{id: "CC013007", label: "CC013007"}]
  }

  //ASHLEY COUNTY
  if (countyId === 561) {
    costCenter = [{id: "CC013009", label: "CC013009"}]
  }

  //BAXTER COUNTY
  if (countyId === 562) {
    costCenter = [{id: "CC012964", label: "CC012964"}]
  }

  //BENTON COUNTY
  if (countyId === 563) {
    costCenter = [{id: "CC012965", label: "CC012965"}]
  }

  //BOONE COUNTY
  if (countyId === 564) {
    costCenter = [{id: "CC012966", label: "CC012966"}]
  }

  //BRADLEY COUNTY
  if (countyId === 565) {
    costCenter = [{id: "CC013021", label: "CC013021"}]
  }

  //CALHOUN COUNTY
  if (countyId === 566) {
    costCenter = [{id: "CC013026", label: "CC013026"}]
  }

  //CARROLL COUNTY
  if (countyId === 567) {
    costCenter = [{id: "CC012967", label: "CC012967"}]
  }

  //CHICOT COUNTY
  if (countyId === 568) {
    costCenter = [{id: "CC013010", label: "CC013010"}]
  }

  //CLARK COUNTY
  if (countyId === 569) {
    costCenter = [{id: "CC013027", label: "CC013027"}]
  }

  //CLAY COUNTY
  if (countyId === 569) {
    costCenter = [{id: "CC013027", label: "CC013027"}]
  }

  //CLEBURNE COUNTY
  if (countyId === 570) {
    costCenter = [{id: "CC012992", label: "CC012992"}]
  }

  //CLEVELAND COUNTY
  if (countyId === 571) {
    costCenter = [{id: "CC012986", label: "CC012986"}]
  }

  //COLUMBIA COUNTY
  if (countyId === 572) {
    costCenter = [{id: "CC013022", label: "CC013022"}]
  }

  //CRAIGHEAD COUNTY
  if (countyId === 573) {
    costCenter = [{id: "CC013028", label: "CC013028"}]
  }

  //CONWAY COUNTY
  if (countyId === 574) {
    costCenter = [{id: "CC012968", label: "CC012968"}]
  }

  //CRAIGHEAD COUNTY
  if (countyId === 575) {
    costCenter = [{id: "CC012994", label: "CC012994"}]
  }

  //CRAWFORD COUNTY
  if (countyId === 576) {
    costCenter = [{id: "CC012969", label: "CC012969"}]
  }

  //CRITTENDEN COUNTY
  if (countyId === 577) {
    costCenter = [{id: "CC012995", label: "CC012995"}]
  }

  //CROSS COUNTY
  if (countyId === 578) {
    costCenter = [{id: "CC012996", label: "CC012996"}]
  }

  //DALLAS COUNTY
  if (countyId === 579) {
    costCenter = [{id: "CC013029", label: "CC013029"}]
  }

  //DESHA COUNTY
  if (countyId === 580) {
    costCenter = [{id: "CC013011", label: "CC013011"}]
  }

  //DREW COUNTY
  if (countyId === 581) {
    costCenter = [{id: "CC013012", label: "CC013012"}]
  }

  //FAULKNER COUNTY
  if (countyId === 582) {
    costCenter = [{id: "CC012970", label: "CC012970"}]
  }

  //FRANKLIN COUNTY
  if (countyId === 583) {
    costCenter = [{id: "CC012971", label: "CC012971"}]
  }

  //FULTON COUNTY
  if (countyId === 584) {
    costCenter = [{id: "CC012987", label: "CC012987"}]
  }

  //GARLAND COUNTY
  if (countyId === 585) {
    costCenter = [{id: "CC013030", label: "CC013030"}]
  }

  //GRANT COUNTY
  if (countyId === 586) {
    costCenter = [{id: "CC013023", label: "CC013023"}]
  }

  //GREENE COUNTY
  if (countyId === 587) {
    costCenter = [{id: "CC012997", label: "CC012997"}]
  }

  //HEMPSTEAD COUNTY
  if (countyId === 588) {
    costCenter = [{id: "CC013031", label: "CC013031"}]
  }

  //HOT SPRING COUNTY
  if (countyId === 589) {
    costCenter = [{id: "CC013032", label: "CC013032"}]
  }

  //HOWARD COUNTY
  if (countyId === 590) {
    costCenter = [{id: "CC013033", label: "CC013033"}]
  }

  //INDEPENDENCE COUNTY
  if (countyId === 591) {
    costCenter = [{id: "CC012988", label: "CC012988"}]
  }

  //IZARD COUNTY
  if (countyId === 592) {
    costCenter = [{id: "CC012989", label: "CC012989"}]
  }

  //JACKSON COUNTY
  if (countyId === 593) {
    costCenter = [{id: "CC012998", label: "CC012998"}]
  }

  //JEFFERSON COUNTY
  if (countyId === 594) {
    costCenter = [{id: "CC013013", label: "CC013013"}]
  }

  //JOHNSON COUNTY
  if (countyId === 595) {
    costCenter = [{id: "CC012972", label: "CC012972"}]
  }

  //LAFAYETTE COUNTY
  if (countyId === 596) {
    costCenter = [{id: "CC013034", label: "CC013034"}]
  }

  //LAWRENCE COUNTY
  if (countyId === 597) {
    costCenter = [{id: "CC012999", label: "CC012999"}]
  }

  //LEE COUNTY
  if (countyId === 598) {
    costCenter = [{id: "CC013014", label: "CC013014"}]
  }

  //LINCOLN COUNTY
  if (countyId === 599) {
    costCenter = [{id: "CC013015", label: "CC013015"}]
  }

  //LITTLE RIVER COUNTY
  if (countyId === 600) {
    costCenter = [{id: "CC013035", label: "CC013035"}]
  }

  //LOGAN COUNTY
  if (countyId === 601) {
    costCenter = [{id: "CC012973", label: "CC012973"}]
  }

  //LONOKE COUNTY
  if (countyId === 602) {
    costCenter = [{id: "CC013016", label: "CC013016"}]
  }

  //MADISON COUNTY
  if (countyId === 603) {
    costCenter = [{id: "CC012975", label: "CC012975"}]
  }

  //MARION COUNTY
  if (countyId === 604) {
    costCenter = [{id: "CC012976", label: "CC012976"}]
  }

  //MILLER COUNTY
  if (countyId === 605) {
    costCenter = [{id: "CC013036", label: "CC013036"}]
  }

  //MISSISSIPPI COUNTY
  if (countyId === 606) {
    costCenter = [{id: "CC013000", label: "CC013000"}]
  }

  //MONROE COUNTY
  if (countyId === 607) {
    costCenter = [{id: "CC013017", label: "CC013017"}]
  }

  //MONTGOMERY COUNTY
  if (countyId === 608) {
    costCenter = [{id: "CC013037", label: "CC013037"}]
  }

  //NEVADA COUNTY
  if (countyId === 609) {
    costCenter = [{id: "CC013038", label: "CC013038"}]
  }

  //NEWTON COUNTY
  if (countyId === 610) {
    costCenter = [{id: "CC012977", label: "CC012977"}]
  }

  //OUACHITA COUNTY
  if (countyId === 611) {
    costCenter = [{id: "CC013039", label: "CC013039"}]
  }

  //PERRY COUNTY
  if (countyId === 612) {
    costCenter = [{id: "CC013020", label: "CC013020"}]
  }

  //PHILLIPS COUNTY
  if (countyId === 613) {
    costCenter = [{id: "CC013018", label: "CC013018"}]
  }

  //PIKE COUNTY
  if (countyId === 614) {
    costCenter = [{id: "CC013040", label: "CC013040"}]
  }

  //POINSETT COUNTY
  if (countyId === 615) {
    costCenter = [{id: "CC013001", label: "CC013001"}]
  }

  //POLK COUNTY
  if (countyId === 616) {
    costCenter = [{id: "CC013041", label: "CC013041"}]
  }

  //POPE COUNTY
  if (countyId === 617) {
    costCenter = [{id: "CC012978", label: "CC012978"}]
  }

  //PRAIRIE COUNTY
  if (countyId === 618) {
    costCenter = [{id: "CC013019", label: "CC013019"}]
  }

  //PULASKI COUNTY
  if (countyId === 619) {
    costCenter = [{id: "CC013024", label: "CC013024"}]
  }

  //RANDOLPH COUNTY
  if (countyId === 620) {
    costCenter = [{id: "CC013002", label: "CC013002"}]
  }

  //SALINE COUNTY
  if (countyId === 622) {
    costCenter = [{id: "CC013042", label: "CC013042"}]
  }

  //SCOTT COUNTY
  if (countyId === 623) {
    costCenter = [{id: "CC013043", label: "CC013043"}]
  }

  //SEARCY COUNTY
  if (countyId === 624) {
    costCenter = [{id: "CC012979", label: "CC012979"}]
  }

  //SEBASTIAN COUNTY
  if (countyId === 625) {
    costCenter = [{id: "CC012980", label: "CC012980"}]
  }

  //SEVIER COUNTY
  if (countyId === 626) {
    costCenter = [{id: "CC013044", label: "CC013044"}]
  }

  //SHARP COUNTY
  if (countyId === 627) {
    costCenter = [{id: "CC012990", label: "CC012990"}]
  }

  //ST. FRANCIS COUNTY
  if (countyId === 621) {
    costCenter = [{id: "CC013003", label: "CC013003"}]
  }

  //STONE COUNTY
  if (countyId === 628) {
    costCenter = [{id: "CC012991", label: "CC012991"}]
  }

  //UNION COUNTY
  if (countyId === 629) {
    costCenter = [{id: "CC013045", label: "CC013045"}]
  }

  //VAN BUREN COUNTY
  if (countyId === 630) {
    costCenter = [{id: "CC012981", label: "CC012981"}]
  }

  //WASHINGTON COUNTY
  if (countyId === 631) {
    costCenter = [{id: "CC012982", label: "CC012982"}]
  }

  //WHITE COUNTY
  if (countyId === 632) {
    costCenter = [{id: "CC013004", label: "CC013004"}]
  }

  //WOODRUFF COUNTY
  if (countyId === 633) {
    costCenter = [{id: "CC013005", label: "CC013005"}]
  }

  //YELL COUNTY
  if (countyId === 634) {
    costCenter = [{id: "CC012983", label: "CC012983"}]
  }


  return costCenter
}

export function arCountyDesignationNumOptions(costCenterCode) {
  let designationNumOptions = [];

  //EXAMPLE COUNTY COST CENTER CODE TEST (USES THE COST CENTER VALUES FOR ARKANSAS COUNTY DO NOT USE)
  // if(costCenterCode === "CC013007"){
  //   designationNumOptions = [
  //       {id:"DS04528", label:"DS04528"},
  //       {id:"DS04556", label:"DS04556"},
  //       {id:"DS04557", label:"DS04557"},
  //       {id:"DS04570", label:"DS04570"},
  //       {id:"DS04599", label:"DS04599"},
  //       {id:"DS04641", label:"DS04641"},
  //       {id:"DS04643", label:"DS04643"},
  //       {id:"DS04648", label:"DS04648"},
  //       {id:"DS04655", label:"DS04655"},
  //       {id:"DS04658", label:"DS04658"}
  //   ]
  // }
  //

  //ARKANSAS STATE CODES
  if (costCenterCode === 'CC013046') {
    designationNumOptions = [
      {id: "DS07304", label: "DS07304"},
    ]
  }

  if (costCenterCode === 'CC013048') {
    designationNumOptions = [
      {id: "DS03412", label: "DS03412"},
      {id: "DS03517", label: "DS03517"},
      {id: "DS03607", label: "DS03607"},
      {id: "DS03645", label: "DS03645"},
      {id: "DS03648", label: "DS03648"},
      {id: "DS03663", label: "DS03663"},
      {id: "DS18935", label: "DS18935"},
      {id: "DS18936", label: "DS18936"},
      {id: "DS18937", label: "DS18937"},
      {id: "DS18939", label: "DS18939"},
      {id: "DS18940", label: "DS18940"},
      {id: "DS18941", label: "DS18941"},
      {id: "DS18943", label: "DS18943"},
      {id: "DS18944", label: "DS18944"},
      {id: "DS18945", label: "DS18945"},
      {id: "DS18946", label: "DS18946"},
      {id: "DS18947", label: "DS18947"},
      {id: "DS78220", label: "DS78220"},
    ]
  }

  if (costCenterCode === 'CC013054') {
    designationNumOptions = [
      {id: "DS03647", label: "DS03647"},
      {id: "DS03650", label: "DS03650"},
      {id: "DS03651", label: "DS03651"},
    ]
  }

  if (costCenterCode === 'CC013055') {
    designationNumOptions = [
      {id: "DS03644", label: "DS03644"},
    ]
  }

  if (costCenterCode === 'CC013058') {
    designationNumOptions = [
      {id: "DS03639", label: "DS03639"},
    ]
  }

  //ARKANSAS COUNTY
  if (costCenterCode === "CC013007") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04556", label: "DS04556"},
      {id: "DS04557", label: "DS04557"},
      {id: "DS04570", label: "DS04570"},
      {id: "DS04599", label: "DS04599"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04643", label: "DS04643"},
      {id: "DS04648", label: "DS04648"},
      {id: "DS04655", label: "DS04655"},
      {id: "DS04658", label: "DS04658"}
    ]
  }


  //ASHLEY COUNTY
  if (costCenterCode === "CC013009") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04586", label: "DS04586"},
      {id: "DS04641", label: "DS04641"}
    ]
  }

  //BAXTER COUNTY
  if (costCenterCode === "CC012964") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04644", label: "DS04644"},
      {id: "DS07241", label: "DS07241"},
      {id: "DS07254", label: "DS07254"},
      {id: "DS07270", label: "DS07270"},
      {id: "DS15280", label: "DS15280"},
    ]
  }

  //BENTON COUNTY
  if (costCenterCode === "CC012965") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04542", label: "DS04542"},
      {id: "DS04551", label: "DS04551"},
      {id: "DS04566", label: "DS04566"},
      {id: "DS04578", label: "DS04578"},
      {id: "DS04588", label: "DS04588"},
      {id: "DS04597", label: "DS04597"},
      {id: "DS04599", label: "DS04599"},
      {id: "DS04617", label: "DS04617"},
      {id: "DS04640", label: "DS04640"},
      {id: "DS04658", label: "DS04658"},
      {id: "DS04697", label: "DS04697"},
      {id: "DS07249", label: "DS07249"},
      {id: "DS07273", label: "DS07273"},
      {id: "DS16396", label: "DS16396"},
      {id: "DS18461", label: "DS18461"},
      {id: "DS18909", label: "DS18909"},
      {id: "DS76939", label: "DS76939"},
      {id: "DS77329", label: "DS77329"},
      {id: "DS78179", label: "DS78179"},
      {id: "DS78181", label: "DS78181"},
    ]
  }

  //BOONE COUNTY
  if (costCenterCode === "CC012966") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04586", label: "DS04586"},
      {id: "DS04636", label: "DS04636"},
      {id: "DS04644", label: "DS04644"},
      {id: "DS04660", label: "DS04660"},
      {id: "DS07260", label: "DS07260"},
      {id: "DS07261", label: "DS07261"},
      {id: "DS07271", label: "DS07271"},
      {id: "DS07273", label: "DS07273"},
      {id: "DS76956", label: "DS76956"},
      {id: "DS77245", label: "DS77245"},
    ]
  }

  //BRADLEY COUNTY
  if (costCenterCode === "CC013021") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04641", label: "DS04641"},
    ]
  }

  //CALHOUN COUNTY
  if (costCenterCode === "CC013026") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04686", label: "DS04686"},
    ]
  }

  //CARROLL COUNTY
  if (costCenterCode === "CC012967") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04540", label: "DS04540"},
      {id: "DS04577", label: "DS04577"},
      {id: "DS04613", label: "DS04613"},
      {id: "DS04637", label: "DS04637"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04658", label: "DS04658"},
      {id: "DS18787", label: "DS18787"},
    ]
  }

  //CHICOT COUNTY
  if (costCenterCode === "CC013010") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
    ]
  }

  //CHICOT COUNTY
  if (costCenterCode === "CC013010") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
    ]
  }

  //CLARK COUNTY
  if (costCenterCode === "CC013027") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS07271", label: "DS07271"},
    ]
  }

  //CLAY COUNTY
  if (costCenterCode === "CC012992") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04658", label: "DS04658"},
      {id: "DS18878", label: "DS18878"},
    ]
  }

  //CLEBURNE COUNTY
  if (costCenterCode === "CC012986") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04554", label: "DS04554"},
      {id: "DS04581", label: "DS04581"},
      {id: "DS04586", label: "DS04586"},
      {id: "DS04626", label: "DS04626"},
      {id: "DS04653", label: "DS04653"},
    ]
  }

  //CLEBURNE COUNTY
  if (costCenterCode === "CC012986") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04554", label: "DS04554"},
      {id: "DS04581", label: "DS04581"},
      {id: "DS04586", label: "DS04586"},
      {id: "DS04626", label: "DS04626"},
      {id: "DS04653", label: "DS04653"},
    ]
  }

  //CLEVELAND COUNTY
  if (costCenterCode === "CC013022") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
    ]
  }

  //COLUMBIA COUNTY
  if (costCenterCode === "CC013028") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS07271", label: "DS07271"},
    ]
  }

  //CONWAY COUNTY
  if (costCenterCode === "CC012968") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04561", label: "DS04561"},
    ]
  }

  //CRAIGHEAD COUNTY
  if (costCenterCode === "CC012994") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04562", label: "DS04562"},
      {id: "DS04627", label: "DS04627"},
      {id: "DS04648", label: "DS04648"},
      {id: "DS04697", label: "DS04697"},
      {id: "DS07248", label: "DS07248"},
      {id: "DS07259", label: "DS07259"},
      {id: "DS17907", label: "DS17907"},
    ]
  }

  //CRAWFORD COUNTY
  if (costCenterCode === "CC012969") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04533", label: "DS04533"},
      {id: "DS04552", label: "DS04552"},
      {id: "DS04644", label: "DS04644"},
      {id: "DS04648", label: "DS04648"},
    ]
  }

  //CRITTENDEN COUNTY
  if (costCenterCode === "CC012995") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04658", label: "DS04658"},
    ]
  }

  //CROSS COUNTY
  if (costCenterCode === "CC012996") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04697", label: "DS04697"},
    ]
  }

  //DALLAS COUNTY
  if (costCenterCode === "CC013029") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
    ]
  }

  //DESHA COUNTY
  if (costCenterCode === "CC013011") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04658", label: "DS04658"},
    ]
  }

  //DREW COUNTY
  if (costCenterCode === "CC013012") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04580", label: "DS04580"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04648", label: "DS04648"},
      {id: "DS04658", label: "DS04658"},
    ]
  }

  //FAULKNER COUNTY
  if (costCenterCode === "CC012970") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04557", label: "DS04557"},
      {id: "DS04558", label: "DS04558"},
      {id: "DS04559", label: "DS04559"},
      {id: "DS04585", label: "DS04585"},
      {id: "DS04593", label: "DS04593"},
      {id: "DS04602", label: "DS04602"},
      {id: "DS04633", label: "DS04633"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04648", label: "DS04648"},
      {id: "DS04700", label: "DS04700"},
      {id: "DS07242", label: "DS07242"},
      {id: "DS07256", label: "DS07256"},
      {id: "DS07265", label: "DS07265"},
      {id: "DS07267", label: "DS07267"},
      {id: "DS13803", label: "DS13803"},
      {id: "DS13804", label: "DS13804"},
      {id: "DS13805", label: "DS13805"},
      {id: "DS19443", label: "DS19443"},
    ]
  }

  //FRANKLIN COUNTY
  if (costCenterCode === "CC012971") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04573", label: "DS04573"},
      {id: "DS04629", label: "DS04629"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04644", label: "DS04644"},
      {id: "DS04648", label: "DS04648"},
      {id: "DS07264", label: "DS07264"},
    ]
  }

  //GARLAND COUNTY
  if (costCenterCode === "CC013030") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04531", label: "DS04531"},
      {id: "DS04537", label: "DS04537"},
      {id: "DS04555", label: "DS04555"},
      {id: "DS04565", label: "DS04565"},
      {id: "DS04575", label: "DS04575"},
      {id: "DS04620", label: "DS04620"},
      {id: "DS04642", label: "DS04642"},
      {id: "DS04648", label: "DS04648"},
      {id: "DS04697", label: "DS04697"},
      {id: "DS07238", label: "DS07238"},
    ]
  }

  //GRANT COUNTY
  if (costCenterCode === "CC013023") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04644", label: "DS04644"},
      {id: "DS04675", label: "DS04675"},
      {id: "DS13807", label: "DS13807"},
    ]
  }

  //GREENE COUNTY
  if (costCenterCode === "CC012997") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04557", label: "DS04557"},
    ]
  }

  //HEMPSTEAD COUNTY
  if (costCenterCode === "CC013031") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04599", label: "DS04599"},
      {id: "DS04632", label: "DS04632"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04651", label: "DS04651"},
      {id: "DS04658", label: "DS04658"},
      {id: "DS07271", label: "DS07271"},
      {id: "DS13819", label: "DS13819"},
    ]
  }

  //HOT SPRING COUNTY
  if (costCenterCode === "CC013032") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04545", label: "DS04545"},
      {id: "DS04616", label: "DS04616"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04648", label: "DS04648"},
      {id: "DS07274", label: "DS07274"},
      {id: "DS13820", label: "DS13820"},
    ]
  }

  //HOWARD COUNTY
  if (costCenterCode === "CC013033") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04557", label: "DS04557"},
      {id: "DS04599", label: "DS04599"},
      {id: "DS04636", label: "DS04636"},
      {id: "DS04644", label: "DS04644"},
      {id: "DS04646", label: "DS04646"},
      {id: "DS04648", label: "DS04648"},
      {id: "DS04650", label: "DS04650"},
      {id: "DS07245", label: "DS07245"},
      {id: "DS13802", label: "DS13802"},
    ]
  }

  //INDEPENDENCE COUNTY
  if (costCenterCode === "CC012988") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS19139", label: "DS19139"},
      {id: "DS19140", label: "DS19140"},
    ]
  }

  //IZARD COUNTY
  if (costCenterCode === "CC012989") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04572", label: "DS04572"},
      {id: "DS04584", label: "DS04584"},
      {id: "DS04586", label: "DS04586"},
      {id: "DS04600", label: "DS04600"},
      {id: "DS04605", label: "DS04605"},
      {id: "DS04654", label: "DS04654"},
      {id: "DS04701", label: "DS04701"},
      {id: "DS07250", label: "DS07250"},
      {id: "DS77346", label: "DS77346"},
    ]
  }

  //JACKSON COUNTY
  if (costCenterCode === "CC012998") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04602", label: "DS04602"},
      {id: "DS04607", label: "DS04607"},
      {id: "DS04697", label: "DS04697"},
      {id: "DS07253", label: "DS07253"},
      {id: "DS04686", label: "DS04686"},
    ]
  }

  //JEFFERSON COUNTY
  if (costCenterCode === "CC013013") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04658", label: "DS04658"},
    ]
  }

  //JOHNSON COUNTY
  if (costCenterCode === "CC012972") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04599", label: "DS04599"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04658", label: "DS04658"},
    ]
  }

  //LAFAYETTE COUNTY
  if (costCenterCode === "CC013034") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS07240", label: "DS07240"},
    ]
  }

  //LAWRENCE COUNTY
  if (costCenterCode === "CC012999") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04535", label: "DS04535"},
      {id: "DS04538", label: "DS04538"},
      {id: "DS04557", label: "DS04557"},
      {id: "DS04599", label: "DS04599"},
      {id: "DS04622", label: "DS04622"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04658", label: "DS04658"},
      {id: "DS04697", label: "DS04697"},
      {id: "DS04686", label: "DS04686"},
    ]
  }

  //LEE COUNTY
  if (costCenterCode === "CC013014") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04658", label: "DS04658"},
    ]
  }

  //LINCOLN COUNTY
  if (costCenterCode === "CC013015") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04658", label: "DS04658"},
      {id: "DS04702", label: "DS04702"},
    ]
  }

  //LITTLE RIVER COUNTY
  if (costCenterCode === "CC013035") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
    ]
  }

  //LOGAN COUNTY
  if (costCenterCode === "CC012973") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04531", label: "DS04531"},
      {id: "DS04546", label: "DS04546"},
      {id: "DS77538", label: "DS77538"},
    ]
  }

  //LONOKE COUNTY
  if (costCenterCode === "CC013016") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04601", label: "DS04601"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04644", label: "DS04644"},
      {id: "DS04648", label: "DS04648"},
      {id: "DS04655", label: "DS04655"},
      {id: "DS04658", label: "DS04658"},
      {id: "DS16393", label: "DS16393"},
      {id: "DS16394", label: "DS16394"},
      {id: "DS16395", label: "DS16395"},
      {id: "DS16396", label: "DS16396"},
    ]
  }

  //MADISON COUNTY
  if (costCenterCode === "CC012975") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04648", label: "DS04648"},
    ]
  }

  //MARION COUNTY
  if (costCenterCode === "CC012976") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04599", label: "DS04599"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS07269", label: "DS07269"},
    ]
  }

  //MILLER COUNTY
  if (costCenterCode === "CC013036") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
    ]
  }

  //MISSISSIPPI COUNTY
  if (costCenterCode === "CC013000") {
    designationNumOptions = [
      {id: "DS03525", label: "DS03525"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04536", label: "DS04536"},
      {id: "DS04560", label: "DS04560"},
      {id: "DS04594", label: "DS04594"},
      {id: "DS04603", label: "DS04603"},
      {id: "DS07275", label: "DS07275"},
      {id: "DS13822", label: "DS13822"},
    ]
  }

  //MONROE COUNTY
  if (costCenterCode === "CC013017") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04608", label: "DS04608"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS07237", label: "DS07237"},
    ]
  }

  //MONTGOMERY COUNTY
  if (costCenterCode === "CC013037") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04549", label: "DS04549"},
      {id: "DS04611", label: "DS04611"},
      {id: "DS04615", label: "DS04615"},
      {id: "DS04622", label: "DS04622"},
      {id: "DS04625", label: "DS04625"},
      {id: "DS07274", label: "DS07274"},
      {id: "DS07276", label: "DS07276"},
      {id: "DS04686", label: "DS04686"},
    ]
  }

  //NEVADA COUNTY
  if (costCenterCode === "CC013038") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
    ]
  }

  //NEWTON COUNTY
  if (costCenterCode === "CC012977") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04529", label: "DS04529"},
    ]
  }

  //OUACHITA COUNTY
  if (costCenterCode === "CC013039") {
    designationNumOptions = [
      {id: "DS04616", label: "DS04616"},
      {id: "DS04658", label: "DS04658"},
    ]
  }

  //PERRY COUNTY
  if (costCenterCode === "CC013020") {
    designationNumOptions = [
      {id: "DS04618", label: "DS04618"},
      {id: "DS04619", label: "DS04619"},
      {id: "DS04631", label: "DS04631"},
      {id: "DS19141", label: "DS19141"},
    ]
  }

  //PHILLIPS COUNTY
  if (costCenterCode === "CC013018") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04658", label: "DS04658"},
    ]
  }

  //PIKE COUNTY
  if (costCenterCode === "CC013040") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
    ]
  }

  //POINSETT COUNTY
  if (costCenterCode === "CC013001") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04579", label: "DS04579"},
      {id: "DS04641", label: "DS04641"},
    ]
  }

  //POLK COUNTY
  if (costCenterCode === "CC013041") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04529", label: "DS04529"},
      {id: "DS04532", label: "DS04532"},
      {id: "DS04583", label: "DS04583"},
      {id: "DS04590", label: "DS04590"},
      {id: "DS04596", label: "DS04596"},
      {id: "DS04599", label: "DS04599"},
      {id: "DS04606", label: "DS04606"},
      {id: "DS04609", label: "DS04609"},
      {id: "DS04610", label: "DS04610"},
      {id: "DS04645", label: "DS04645"},
      {id: "DS04673", label: "DS04673"},
    ]
  }

  //POPE COUNTY
  if (costCenterCode === "CC012978") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04529", label: "DS04529"},
      {id: "DS04535", label: "DS04535"},
      {id: "DS04543", label: "DS04543"},
      {id: "DS04564", label: "DS04564"},
      {id: "DS04582", label: "DS04582"},
      {id: "DS04586", label: "DS04586"},
      {id: "DS04591", label: "DS04591"},
      {id: "DS04595", label: "DS04595"},
      {id: "DS04622", label: "DS04622"},
      {id: "DS04630", label: "DS04630"},
      {id: "DS04635", label: "DS04635"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04656", label: "DS04656"},
      {id: "DS04658", label: "DS04658"},
      {id: "DS04697", label: "DS04697"},
      {id: "DS07255", label: "DS07255"},
      {id: "DS07263", label: "DS07263"},
      {id: "DS07274", label: "DS07274"},
      {id: "DS16437", label: "DS16437"},
      {id: "DS16481", label: "DS16481"},
      {id: "DS18471", label: "DS18471"},
      {id: "DS77290", label: "DS77290"},
      {id: "DS77345", label: "DS77345"},
    ]
  }

  //PRAIRIE COUNTY
  if (costCenterCode === "CC013019") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04658", label: "DS04658"},
    ]
  }

  //PULASKI COUNTY
  if (costCenterCode === "CC013024") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04697", label: "DS04697"},
      {id: "DS07246", label: "DS07246"},
    ]
  }

  //RANDOLPH COUNTY
  if (costCenterCode === "CC013002") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04538", label: "DS04538"},
      {id: "DS04557", label: "DS04557"},
      {id: "DS04599", label: "DS04599"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04648", label: "DS04648"},
      {id: "DS04652", label: "DS04652"},
      {id: "DS04658", label: "DS04658"},
      {id: "DS13821", label: "DS13821"},
    ]
  }

  //SALINE COUNTY
  if (costCenterCode === "CC013042") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04547", label: "DS04547"},
      {id: "DS04569", label: "DS04569"},
      {id: "DS04598", label: "DS04598"},
      {id: "DS04599", label: "DS04599"},
      {id: "DS04634", label: "DS04634"},
      {id: "DS04638", label: "DS04638"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04648", label: "DS04648"},
      {id: "DS04658", label: "DS04658"},
      {id: "DS07266", label: "DS07266"},
      {id: "DS19547", label: "DS19547"},
      {id: "DS76852", label: "DS76852"},
    ]
  }

  //SCOTT COUNTY
  if (costCenterCode === "CC013043") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
    ]
  }

  //SEARCY COUNTY
  if (costCenterCode === "CC012979") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS07243", label: "DS07243"},
      {id: "DS18607", label: "DS18607"},
    ]
  }

  //SEBASTIAN COUNTY
  if (costCenterCode === "CC012980") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS07251", label: "DS07251"},
    ]
  }

  //SEVIER COUNTY
  if (costCenterCode === "CC013044") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04529", label: "DS04529"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04648", label: "DS04648"},
      {id: "DS04697", label: "DS04697"},
      {id: "DS07244", label: "DS07244"},
      {id: "DS07247", label: "DS07247"},
      {id: "DS07254", label: "DS07254"},
      {id: "DS15238", label: "DS15238"},
      {id: "DS19682", label: "DS19682"},
    ]
  }

  //SEVIER COUNTY
  if (costCenterCode === "CC013044") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04529", label: "DS04529"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04648", label: "DS04648"},
      {id: "DS04697", label: "DS04697"},
      {id: "DS07244", label: "DS07244"},
      {id: "DS07247", label: "DS07247"},
      {id: "DS07254", label: "DS07254"},
      {id: "DS15238", label: "DS15238"},
      {id: "DS19682", label: "DS19682"},
    ]
  }

  //SHARP COUNTY
  if (costCenterCode === "CC012990") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
    ]
  }

  //ST. FRANCIS COUNTY
  if (costCenterCode === "CC013003") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04586", label: "DS04586"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS07238", label: "DS07238"},
    ]
  }

  //STONE COUNTY
  if (costCenterCode === "CC012991") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04529", label: "DS04529"},
      {id: "DS04541", label: "DS04541"},
      {id: "DS04562", label: "DS04562"},
      {id: "DS04599", label: "DS04599"},
      {id: "DS04612", label: "DS04612"},
      {id: "DS04630", label: "DS04630"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04649", label: "DS04649"},
      {id: "DS07275", label: "DS07275"},
    ]
  }

  //UNION COUNTY
  if (costCenterCode === "CC013045") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
    ]
  }

  //VAN BUREN COUNTY
  if (costCenterCode === "CC012981") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04604", label: "DS04604"},
      {id: "DS04639", label: "DS04639"},
      {id: "DS04658", label: "DS04658"},
      {id: "DS04697", label: "DS04697"},
    ]
  }

  //WASHINGTON COUNTY
  if (costCenterCode === "CC012982") {
    designationNumOptions = [
      {id: "DS04527", label: "DS04527"},
      {id: "DS04528", label: "DS04528"},
      {id: "DS04529", label: "DS04529"},
      {id: "DS04574", label: "DS04574"},
      {id: "DS04614", label: "DS04614"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04644", label: "DS04644"},
      {id: "DS04658", label: "DS04658"},
      {id: "DS04662", label: "DS04662"},
      {id: "DS04697", label: "DS04697"},
      {id: "DS07247", label: "DS07247"},
      {id: "DS07252", label: "DS07252"},
      {id: "DS07253", label: "DS07253"},
      {id: "DS07262", label: "DS07262"},
      {id: "DS07268", label: "DS07268"},
      {id: "DS07274", label: "DS07274"},
      {id: "DS18604", label: "DS18604"},
      {id: "DS18605", label: "DS18605"},
      {id: "DS18606", label: "DS18606"},
      {id: "DS19539", label: "DS19539"},
      {id: "DS19565", label: "DS19565"},
      {id: "DS76848", label: "DS76848"},
      {id: "DS76849", label: "DS76849"},
      {id: "DS78266", label: "DS78266"},
      {id: "DS78267", label: "DS78267"},
      {id: "DS78268", label: "DS78268"},
      {id: "DS78269", label: "DS78269"},
    ]
  }

  //WHITE COUNTY
  if (costCenterCode === "CC013004") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04531", label: "DS04531"},
      {id: "DS04539", label: "DS04539"},
      {id: "DS04658", label: "DS04658"},
    ]
  }

  //WOODRUFF COUNTY
  if (costCenterCode === "CC013005") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04641", label: "DS04641"},
      {id: "DS04658", label: "DS04658"},
    ]
  }

  //YELL COUNTY
  if (costCenterCode === "CC012983") {
    designationNumOptions = [
      {id: "DS04528", label: "DS04528"},
      {id: "DS04658", label: "DS04658"},
      {id: "DS07271", label: "DS07271"},
      {id: "DS07637", label: "DS07637"},
      {id: "DS76953", label: "DS76953"},
    ]
  }


  return designationNumOptions

}

/**
 * Create an array based on a given range
 * @param start {number} - start value, inclusive
 * @param end {number} - end value, inclusive
 * @returns {number[]}
 */
export function range(start, end) {
  const array = [];
  for (let i = start; i <= end; i++) {
    array.push(i);
  }
  return array;
}

export const MAX_PAGE_SIZE = 999999999;