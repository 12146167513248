import React, {Component} from 'react';
import GridItem from '../../../../vendor/components/Grid/GridItem';
import GridContainer from '../../../../vendor/components/Grid/GridContainer';
import {FormData} from '../../../../core/components/form_data';
import withStyles from '@material-ui/core/styles/withStyles';
import RenderCustomForm, {validateCustomFormData} from '../../../../core/components/custom_form/render_custom_form';
import Accordion from '../../../../vendor/components/Accordion/Accordion';
import WaiverView, {validateWaiverData} from '../../../../enrollments/components/waivers/waiver_view';
import {
  Event,
  EventRegistration,
  ExternalEventParticipant,
  StateSettings
} from '../../../../core/services/backend-client';
import {Session} from '../../../../core/components/app/types';
import {
  meetsMaxAgeRequirement,
  meetsMaxAgeRequirementWithAsOfDate,
  meetsMinAgeRequirement,
  meetsMinAgeRequirementWithAsOfDate,
} from '../../../../core/shared/shared_helpers';
import SnackbarContent from '../../../../vendor/components/Snackbar/SnackbarContent';
import moment from 'moment';
import {applyEventLimitedOptions} from '../../event_editor';

//Backend Used (known backend uses)
// Event Limited Option Counts - EventRegistrationRepository.java (getEventLimitedOptions)

interface Props {
  classes: any,
  event: Event,
  participant: ExternalEventParticipant,
  registration: EventRegistration,
  session: Session,
  settings: {
    state: StateSettings
  },
  update: (participant: Partial<ExternalEventParticipant>, registration: Partial<EventRegistration>) => void
}

class Step2Waivers extends Component<Props, any> {
  async componentDidMount() {
    const {session: {backendClient}, registration, event} = this.props;
    const limitedOptions = registration.type ? await backendClient.getEventLimitedOptions({
      eventId: event.id, eventRegistrationId: registration.id, memberType: registration.type as any
    }) : [];
    this.setState({limitedOptions});
    this.getEventWaivers();
  }

  sendState() {
    return this.state;
  }

  getConfirmableWaivers() {
    const {registration, settings: {state: {enrollmentSteps}}} = this.props;
    return registration.type === 'ClubMember'
        ? enrollmentSteps.waivers.clubMember
        : enrollmentSteps.waivers.volunteer;
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<any>, snapshot?: any) {
    const {event, registration, participant, session: {backendClient}} = this.props;
    if (registration.type !== prevProps.registration.type || participant.birthday !== prevProps.participant.birthday) {
      this.getEventWaivers();
    }
    if (registration.type !== prevProps.registration.type) {
      this.setState({
        limitedOptions: await backendClient.getEventLimitedOptions({
          eventId: event.id, eventRegistrationId: registration.id, memberType: registration.type as any,
        }),
      });
    }
  }

  getEventWaivers() {
    const {participant, registration, event, settings: {state: stateSettings}, update} = this.props,
        confirmableWaivers = this.getConfirmableWaivers();
    // console.log("CONFIRMABLE WAIVERS", confirmableWaivers);
    // console.log("PARTICIPANT", participant);
    // console.log("REGISTRATION", registration);
    // console.log("EVENT", event);

    const birthday = participant.birthday,
        waivers = registration.type === 'ClubMember'
            ? (event.participantWaivers || []).filter((waiver: any) => {
              waiver = (waiver.type === 'confirm' ? confirmableWaivers.find((w: any) => w.key === waiver.confirmWaiverKey) : waiver);
              if (birthday) {
                return (waiver?.minAge ? (
                        waiver?.minAgeAsOf === 'currentDate'
                            ? meetsMinAgeRequirementWithAsOfDate(waiver.minAge, birthday, moment().format('YYYY-MM-DD'))
                            : meetsMinAgeRequirement(waiver.minAge, participant.birthday, stateSettings)) : true)
                    && (waiver?.maxAge ? (
                        waiver?.minAgeAsOf === 'currentDate'
                            ? meetsMaxAgeRequirementWithAsOfDate(waiver.maxAge, birthday, moment().format('YYYY-MM-DD'))
                            : meetsMaxAgeRequirement(waiver.maxAge, participant.birthday, stateSettings)
                    ) : true);
              }

              return true;
            })
            : event.volunteerWaivers,
        waiverData = (registration?.waiverData || {}),
        initialWaiverData = waivers.reduce((o: any, w: any) => {
          if (!w.type || w.type === 'text' || w.type === 'upload') {
            if (registration.type === 'ClubMember') {
              return {
                ...o, [w.key]: {
                  studentDate: moment().format('YYYY-MM-DD'),
                  parentDate: moment().format('YYYY-MM-DD')
                }
              };
            } else {
              return {
                ...o,
                [w.key]: {parentDate: moment().format('YYYY-MM-DD')}
              };
            }
          }
          return o;
        }, {});
    update({}, {...registration, waiverData: {...initialWaiverData, ...waiverData}});
    this.setState({waivers});
  }

  isValidated(): boolean {
    const {event, participant, registration} = this.props,
        {regData = {}, waiverData = {}} = registration,
        {waivers} = this.state,
        fields = registration.type === 'ClubMember'
            ? event.participantRegFields
            : event.volunteerRegFields,
        confirmableWaivers = this.getConfirmableWaivers(),
        registrationType = registration.type === 'ClubMember' ? 'clubMember' : 'volunteer';

    const errors = waivers.map((w: any) => {
      const confirmWaiver = w.type === 'confirm'
          ? confirmableWaivers.find((cw: any) => cw.key === w.confirmWaiverKey)
          : undefined;
      if (confirmWaiver) {
        return validateWaiverData(confirmWaiver, participant.enrollmentData?.waiverData?.[confirmWaiver.key] || {}, registrationType, this.props)
      }

      return validateWaiverData(w, waiverData[w.key] || {}, registrationType, this.props);
      // @ts-ignore
    }).concat(validateCustomFormData(fields, regData))
        .reduce((a: string[], b: string[]) => a.concat(b), []);

    if (errors.length) {
      this.setState({errors});
      return false;
    }

    return true;
  }


  render() {
    // @ts-ignore
    const {
      classes,
      event,
      participant,
      registration,
      session: {scrollToTop},
      settings: {state: stateSettings},
      update,
      //@ts-ignore
      stateId,
    } = this.props;
    const {waivers = [], errors = [], limitedOptions = []} = this.state || {};
    const regFields = registration.type === 'ClubMember' ? event.participantRegFields : event.volunteerRegFields;
    const registrationType = registration.type === 'ClubMember' ? 'clubMember' : 'volunteer';
    //console.log("WAIVERS", waivers);
    // console.log("REGISTRATION", registration);
    // console.log("PARTICIPANT", participant);
    // console.log("PROPS", this.props);
    // console.log("STATE ID", stateId);


    // @ts-ignore
    return <div>
      <RenderCustomForm
          customForm={applyEventLimitedOptions(regFields, limitedOptions)}
          customFormData={registration.regData || {}}
          //@ts-ignore
          readOnly={
              stateId === 'US-UT'
              && registration.externalParticipantId
              && (
                  registration.status === 'Active'
                  || registration.status === 'PendingApproval'
                  || registration.status === 'Canceled'
                  || registration.status === 'NoShow'
              )
          }
          onChange={(regData: any) => update({}, {regData})}/>
      {/*{event.participantWaivers?.length ? <FormData data={registration?.waiverData || {}}
                                                    onChange={(newData: any) => update({}, {waiverData: newData})}>*/}
      {waivers?.length ? <FormData data={registration?.waiverData || {}}
                                   onChange={(newData: any) => update({}, {waiverData: newData})}>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <h3 className={classes.infoText}>
              Read over and complete the following releases
            </h3>
          </GridItem>
          <GridItem xs={12}>
            <Accordion
                onSelect={() => {
                  scrollToTop(260);
                }}
                active={0}
                collapses={waivers.map((waiver: any) => ({
                  title: waiver.title + (waiver?.signatureOptional ? " (Optional)" : ''),
                  content: <WaiverView waiver={waiver}
                                       memberType={registrationType}
                                       readOnly={stateId === 'US-UT' && registration.externalParticipantId}
                                       confirmableWaivers={stateSettings.enrollmentSteps.waivers[registrationType]}
                                       waiverConfirmationData={participant.enrollmentData?.waiverData}
                                       updateWaiverConfirmationData={(waiverData: any) => update({
                                         enrollmentData: {
                                           waiverData: {
                                             ...participant.enrollmentData?.waiverData,
                                             ...waiverData
                                           }
                                         }
                                       }, {})}/>,
                }))}
            />
          </GridItem>
        </GridContainer>
      </FormData> : ''}

      {registration.type === 'ClubMember' && !event.participantRegFields?.length ? 'No forms to fill out. ' : ''}
      {registration.type === 'ClubMember' && !event.participantWaivers?.length ? 'No waivers to fill out. ' : ''}
      {registration.type === 'ClubMember' && (!event.participantRegFields?.length || !event.participantWaivers?.length) ? 'Please continue to submit/checkout. ' : ''}

      {registration.type === 'Volunteer' && !event.volunteerRegFields?.length ? 'No forms to fill out. ' : ''}
      {registration.type === 'Volunteer' && !event.volunteerWaivers?.length ? 'No waivers to fill out. ' : ''}
      {registration.type === 'Volunteer' && (!event.volunteerRegFields?.length || !event.volunteerWaivers?.length) ? 'Please continue to submit/checkout. ' : ''}

      {errors.length ? <GridItem><SnackbarContent
          message={<div>
            Please fix the following errors before continuing:
            <ul>
              {errors.map((e: any, i: number) => <li key={i}>{e}</li>)}
            </ul>
          </div>}
          onClose={() => this.setState({errors: []})}
          close
          color="danger"
      /></GridItem> : ''}
    </div>
  }
}

export default withStyles({} as any)(Step2Waivers);
