import loadState from '../states';

/**
 * Returns an array of all applicable record book types
 * for a given state and organization
 */
export async function recordBookTypesForState(stateId, org, countyName) {
  const state = await loadState(stateId);
  //console.log("STATE", state);
  if (state && state.projectTypes) {
    return state.projectTypes.filter(t => !!t.types[org]
        && (!countyName || !t.visibleToCounties || t.visibleToCounties.includes(countyName)))
        .map(t => ({...t, types: t.types[org]}));
  } else {
    return [];
  }
}

export async function countySpecificProjectOptions(stateId, org, backendClient) {
  const state = await loadState(stateId);
  const countiesList = await backendClient.listCounties({stateId});


  // let countiesSettings = [];
  // for(let i=0; i < countiesList.length; i++){
  //   let countySettings = await backendClient.getCountySettings({countyId: countiesList[i].id, orgId:org});
  //   countiesSettings.push(countySettings)
  // }
  const countiesSettings = await backendClient.getCountySettingsByState({stateId, orgId: org});
  let countiesSettingsFiltered = countiesSettings.flatMap(cs => [{
    countyProjectTypes: cs.countyProjectTypes,
    countyId: cs.countyId
  }]);
  //console.log("COUNTIES SETTINGS", countiesSettings );
  // console.log("COUNTIES SETTINGS FILTERED", countiesSettingsFiltered);

  return countiesSettingsFiltered;


}

/**
 * Finds a specific type by tag, returns undefined if none is found
 */
export async function findRecordBookType(type, stateId, org) {
  if (!stateId || !org) {
    throw new Error('stateId and org is required to find record book type');
  }
  return ((await recordBookTypesForState(stateId, org)) || [])
      .find(r => r.tag === type);
}

/**
 * computes a single object with primary types as keys
 * and an array subtypes as values
 * @param projectTypes - an array of project types
 *                       usually the output from recordBookTypesForState()
 */
export function computeRecordBookTypes(projectTypes) {
  const result = {};
  Object.values(projectTypes).forEach(type => {
    Object.keys(type.types || {}).forEach(primaryType => {
      result[primaryType] = result[primaryType] || {};
      result[primaryType].subTypes = result[primaryType].subTypes || {};
      (type.types[primaryType] || []).forEach(subType => {
        result[primaryType].subTypes[subType] = result[primaryType].subTypes[subType] ||
            {};
        result[primaryType].subTypes[subType][type.tag] = type;
      });
    });
  });
  return result;
}

/**
 * returns an array of a every primaryType with every subtype as a string
 * such as Primary/Sub
 * @param projectTypes
 * @param onlyEnrollmentTypes - set to true to only include record book types only for enrollments
 * @returns {Array<string>}
 */
export function allRecordBookTypes(projectTypes, onlyEnrollmentTypes = false) {
  const result = new Set();
  // console.log("PROJECT TYPES", projectTypes);
  Object.values(projectTypes).forEach(type => {
    //if (type.excludeFromEnrollments !== true || type.alwaysShow === true /*|| onlyEnrollmentTypes !==true*/) {
    if ((type.alwaysShow === true && type.excludeFromEnrollments !== true) || (type.excludeFromEnrollments !== true)  /*|| onlyEnrollmentTypes !==true*/) {
      Object.keys(type.types || {}).forEach(primaryType => {
        if (type.types[primaryType]?.length) {
          type.types[primaryType].forEach(subType => {
            result.add(primaryType + '/' + subType);
          });

        } else {
          result.add(primaryType);
        }
      });
    }
  });
  // console.log("RESULT", result);
  return Array.from(result).sort((a, b) => a.localeCompare(b));
}

/**
 * MADE THIS NEW FUNCTION FOR THE TEMPLATES VIEW TO MAKE SURE THAT ANY CHANGES I MAKE TO THIS CODE ONLY EFFECT THE TEMPLATES TAB.
 * returns an array of a every primaryType with every subtype as a string
 * such as Primary/Sub
 * @param projectTypes
 * @param onlyEnrollmentTypes - set to true to only include record book types only for enrollments
 * @returns {Array<string>}
 */
export function allRecordBookTemplates(projectTypes, onlyEnrollmentTypes = false) {
  //const result = new Set();
  const result = [];
  // console.log("PROJECT TYPES", projectTypes);
  Object.values(projectTypes).forEach(type => {
    if ((type.excludeFromEnrollments !== true || type.alwaysShow === true) /*|| onlyEnrollmentTypes !==true*/) {
      Object.keys(type.types || {}).forEach(primaryType => {
        if (type.types[primaryType]?.length) {
          type.types[primaryType].forEach(subType => {
            //result.add(primaryType + '/' + subType);
            result.push({id: primaryType + '/' + subType+';;;'+type.name, label: primaryType + "/" + subType + " (" + type.name + ")"});
          });

        } else {
          //result.add(primaryType);
          result.push({id: primaryType+';;;'+type.name, label: primaryType + " (" + type.name + ")"});
        }
      });
    }
  });
  // console.log("RESULT", result);
  return Array.from(result).sort((a, b) => (a.id || '').localeCompare((b.id || '')));
}

export function allRecordBookTemplatesForAdmins(projectTypes, onlyEnrollmentTypes = false) {
  const result = new Set();
  // console.log("PROJECT TYPES", projectTypes);
  Object.values(projectTypes).forEach(type => {
    if ((type.excludeFromEnrollments !== true || type.alwaysShow === true) /*|| onlyEnrollmentTypes !==true*/) {
      Object.keys(type.types || {}).forEach(primaryType => {
        if (type.types[primaryType]?.length) {
          type.types[primaryType].forEach(subType => {
            result.add(primaryType + '/' + subType);
          });

        }
        else {
          result.add(primaryType);
        }
      });
    }
  });
  // console.log("RESULT", result);
  return Array.from(result).sort((a, b) => a.localeCompare(b));
}




/**
 * returns an array of a every primaryType with every subtype as a string
 * such as Primary/Sub
 * @param projectTypes
 * @param onlyEnrollmentTypes - set to true to only include record book types only for enrollments
 * @returns {Array<string>}
 */
export function allProjectsForES237(projectTypes, onlyEnrollmentTypes = false) {
  const result = new Set();
  //console.log("PROJECT TYPES FROM RECORD BOOK TYPES", projectTypes);


  Object.values(projectTypes).forEach(type => {
    if (type.excludeFromEnrollments !== true || type.alwaysShow === true && type.hideFromReports !== true /*|| onlyEnrollmentTypes !==true*/) {
      Object.keys(type.types || {}).forEach(primaryType => {
        if (type.types[primaryType]?.length) {
          type.types[primaryType].forEach(subType => {
            result.add(primaryType + '/' + subType);
          });

        } else {
          result.add(primaryType);
        }
      });
    }
  });
  //console.log("RESULT FROM RECORD BOOK TYPES", result);
  return Array.from(result).sort((a, b) => a.localeCompare(b));
}