import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import Button from '../CustomButtons/Button';
import Card from '../../components/Card/Card.js';

import wizardStyle from '../../assets/jss/material-dashboard-pro-react/components/wizardStyle.js';
import LoadingButton from '../../../core/components/loading_button';
import {ArrowLeft, Save as SaveIcon} from '@material-ui/icons';
import {runSweetAlert} from '../../../core/components/Modal/Modal';
import {Link as RouterLink} from 'react-router-dom';

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    var width;
    if (this.props.steps.length === 1) {
      width = '100%';
    } else {
      if (window.innerWidth < 600) {
        if (this.props.steps.length !== 3) {
          width = '50%';
        } else {
          width = 100 / 3 + '%';
        }
      } else {
        if (this.props.steps.length === 2) {
          width = '50%';
        } else {
          width = 100 / 3 + '%';
        }
      }
    }
    const currentStep = Math.max(Math.min(props.steps.length - 1, props.initialStep || 0), 0);
    this.state = {
      currentStep,
      color: this.props.color,
      nextButton: this.props.steps.length > 1,
      previousButton: false,
      finishButton: this.props.steps.length === 1,
      width: width || 'auto',
      movingTabStyle: {
        transition: 'transform 0s',
      },
      allStates: {},
    };
    this.navigationStepChange = this.navigationStepChange.bind(this);
    this.refreshAnimation = this.refreshAnimation.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.finishButtonClick = this.finishButtonClick.bind(this);
    this.updateWidth = this.updateWidth.bind(this);
  }

  componentDidMount() {
    this.refreshAnimation(this.state.currentStep);
    window.addEventListener('resize', this.updateWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth);

  }

  updateWidth() {
    this.refreshAnimation(this.state.currentStep);
  }

  async navigationStepChange(key) {
    if (this.props.steps) {
      let validationState = true;
      if (key > this.state.currentStep) {
        for (let i = this.state.currentStep; i < key; i++) {
          const stepId = this.props.steps[i].stepId;

          if (this[stepId].isValidated) {
            const result = this[stepId].isValidated();
            if (result instanceof Promise) {
              if (!(await result)) {
                validationState = false;
                break;
              }
            } else if (!result) {
              validationState = false;
              break;
            }
          }
          if (this[stepId].sendState !== undefined) {
            try {
              this.setState({
                allStates: {
                  ...this.state.allStates,
                  [stepId]: await this[stepId].sendState(),
                },
              });
            } catch (e) {
              console.error(e);
            }
          }
          /*if (
              this[this.props.steps[i].stepId].isValidated !== undefined &&
              this[this.props.steps[i].stepId].isValidated() === false
          ) {
            validationState = false;
            break;
          }*/
        }
      }
      if (validationState) {
        this.setState({
          currentStep: key,
          nextButton: this.props.steps.length > key + 1,
          previousButton: key > 0,
          finishButton: this.props.steps.length === key + 1,
        });
        this.refreshAnimation(key);
      }
    }
  }

  async nextButtonClick() {
    // check if next button has already been clicked
    if (this.state.isValidating) {
      return;
    }
    try {
      await new Promise(r => this.setState({isValidating: true}, r));
      const {session} = this.props.extraProps;
      const currentStep =
              this[this.props.steps[this.state.currentStep].stepId],
          currentStepId = this.props.steps[this.state.currentStep].stepId,
          {validate} = this.props;

      if (currentStep.scrollToTop) {
        currentStep.scrollToTop();
      }
      if (validate && currentStep.isValidated) {
        const result = currentStep.isValidated();
        if (result instanceof Promise) {
          if (!(await result)) return;
        } else if (!result) {
          return;
        }
      }

      if (currentStep.sendState !== undefined) {
        try {
          this.setState({
            allStates: {
              ...this.state.allStates,
              [currentStepId]: await currentStep.sendState(),
            },
          });
        } catch (e) {
          console.error(e);
        }
      }

      var key = this.state.currentStep + 1;
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1,
        previousButton: key > 0,
        finishButton: this.props.steps.length === key + 1,
      });
      this.refreshAnimation(key);
      if (session) {
        session.scrollToTop();
      }
    } finally {
      this.setState({isValidating: false});
    }
  }

  async previousButtonClick() {
    const {session} = this.props.extraProps;
    const currentStep =
            this[this.props.steps[this.state.currentStep].stepId],
        currentStepId = this.props.steps[this.state.currentStep].stepId;

    if (currentStep.sendState !== undefined) {
      try {
        this.setState({
          allStates: {
            ...this.state.allStates,
            [currentStepId]: await currentStep.sendState(),
          },
        });
      } catch (e) {
        console.error(e);
      }
    }

    const key = this.state.currentStep - 1;
    if (key >= 0) {
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1,
        previousButton: key > 0,
        finishButton: this.props.steps.length === key + 1,
      });
      this.refreshAnimation(key);
    }

    if (session) {
      session.scrollToTop();
    }
  }

  async finishButtonClick() {
    // check if next button has already been clicked
    if (this.state.isValidating) {
      return;
    }
    try {
      await new Promise(r => this.setState({isValidating: true}, r));
      const currentStepId = this.props.steps[this.state.currentStep].stepId,
          currentStep = this[currentStepId],
          {validate} = this.props;
      if (
          (!validate && this.props.finishButtonClick !== undefined) ||
          (validate && ((currentStep.isValidated !== undefined &&
                      (await Promise.resolve(currentStep.isValidated())))
                  || currentStep.isValidated === undefined) &&
              this.props.finishButtonClick !== undefined)
      ) {
        return new Promise((res) => {
          this.setState({
                allStates: {
                  ...this.state.allStates,
                  [currentStepId]: currentStep.sendState(),
                },
              }, () => {
                res(this.props.finishButtonClick(this.state.allStates));
              },
          );
        });
      }
    } finally {
      this.setState({isValidating: false});
    }
  }

  refreshAnimation(index) {
    var total = this.props.steps.length;
    var li_width = 100 / total;
    var total_steps = this.props.steps.length;
    var move_distance = this.refs.wizard.children[0].offsetWidth / total_steps;
    if (move_distance === 0) {
      return setTimeout(this.refreshAnimation.bind(this, index), 0);
    }
    var index_temp = index;
    var vertical_level = 0;

    var mobile_device = window.innerWidth < 600 && total > 3;

    if (mobile_device) {
      move_distance = this.refs.wizard.children[0].offsetWidth / 2;
      index_temp = index % 2;
      li_width = 50;
    }

    this.setState({width: li_width + '%'});

    var step_width = move_distance;
    move_distance = move_distance * index_temp;

    var current = index + 1;

    if (current === 1 || (mobile_device === true && index % 2 === 0)) {
      move_distance -= 8;
    } else if (
        current === total_steps ||
        (mobile_device === true && index % 2 === 1)
    ) {
      move_distance += 8;
    }

    if (mobile_device) {
      vertical_level = parseInt(index / 2, 10);
      vertical_level = vertical_level * 38;
    }
    var movingTabStyle = {
      width: step_width,
      transform:
          'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)',
      transition: 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)',
    };
    this.setState({movingTabStyle: movingTabStyle});
  }

  render() {
    const {
          classes,
          title,
          subtitle,
          color,
          steps,
          extraProps,
          extraProps: {enrollment},
          plain,
          nextDisabled,
          finishDisabled,
        } = this.props,
        stepData = (steps || [])[this.state.currentStep] || {};

    //console.log("EXTRA PROPS", extraProps);

    return (
        <div className={classes.wizardContainer} ref="wizard">
          <Card className={classes.card} plain={plain}>
            <div className={classes.wizardHeader}>
              <h3 className={classes.title}>{title}</h3>
              <h5 className={classes.subtitle}> {subtitle}</h5>
              {extraProps.enrollment && extraProps.adminView === undefined ?
                  <Button color="primary" component={RouterLink}
                          simple to={'/dashboard'}>
                    Back To Dashboard
                  </Button> : ''
              }
            </div>
            <div className={classes.wizardNavigation}>
              <ul className={classes.nav}>
                {steps.map((prop, key) => {
                  return (
                      <li className={classes.steps}
                          key={key}
                          style={{width: this.state.width}}>
                        <a href="#pablo"
                           className={classes.stepsAnchor}
                           onClick={e => {
                             e.preventDefault();
                             if (!nextDisabled) {
                               this.navigationStepChange(key);
                             }
                           }}>
                          {prop.stepName}
                        </a>
                      </li>
                  );
                })}
              </ul>
              <div
                  className={classes.movingTab + ' ' + classes[color]}
                  style={this.state.movingTabStyle}
              >
                {steps[this.state.currentStep].stepName}
              </div>
            </div>
            <div className={classes.content}>
              {steps.map((prop, key) => {
                const stepContentClasses = cx({
                  [classes.stepContentActive]: this.state.currentStep === key,
                  [classes.stepContent]: this.state.currentStep !== key,
                });
                return (
                    <div className={stepContentClasses} key={key}>
                      <prop.stepComponent
                          innerRef={node => (this[prop.stepId] = node)}
                          allStates={this.state.allStates}
                          next={this.nextButtonClick.bind(this)}
                          prev={this.previousButtonClick.bind(this)}
                          changeStep={this.navigationStepChange.bind(this)}
                          currentStep={key}
                          numSteps={steps.length}
                          {...extraProps}
                      />
                    </div>
                );
              })}
            </div>
            <div className={classes.footer}>
              <div className={classes.left}>
                {this.props.leftNav}
                {this.state.previousButton ? (
                    <Button
                        className={this.props.previousButtonClasses}
                        onClick={() => this.previousButtonClick()}
                    >
                      {this.props.previousButtonText}
                    </Button>
                ) : null}
              </div>

              <div className={classes.right}>
                {extraProps.enrollment && extraProps.adminView === undefined ?
                    <Button color="primary" component={RouterLink}
                            simple to={'/dashboard'}>
                      Back To Dashboard
                    </Button> : ''
                }
                {stepData.showSaveBtn ? (
                    <Button
                        color="rose" simple
                        className={this.props.nextButtonClasses}
                        style={{marginRight: '2rem'}}
                        onClick={async () => {
                          await this[stepData.stepId].save();
                          await runSweetAlert({
                            success: true,
                            title: 'Saved!',
                            body: 'Your data has been Saved',
                          });
                          if (this.props.afterSave) {
                            return this.props.afterSave(stepData);
                          }
                        }}>
                      <SaveIcon/> Save for Later
                    </Button>
                ) : null}
                {this.state.nextButton && !nextDisabled ? (
                    <Button
                        color="rose"
                        className={this.props.nextButtonClasses}
                        onClick={() => this.nextButtonClick()}
                        disabled={this.isValidating}
                    >
                      {this.props.nextButtonText}
                    </Button>
                ) : null}
                {stepData.showOneTimePay ? (
                    <LoadingButton
                        color="warning"
                        className={this.finishButtonClasses}
                        onClick={async () => await stepData.payEnrollment()}>
                      {stepData.showOneTimePayText}
                    </LoadingButton>
                ) : null}
                {this.state.finishButton && !finishDisabled && (enrollment ? enrollment?.status !== 'Ineligible' : true)
                    ? (
                        <LoadingButton
                            color="rose"
                            className={this.finishButtonClasses}
                            onClick={() => this.finishButtonClick()}>
                          {this.props.finishButtonText}
                        </LoadingButton>
                    )
                    : null}
                {stepData.showSubmit && (enrollment ? enrollment?.status !== 'Ineligible' : true) ? (
                    <LoadingButton
                        color="rose"
                        className={this.finishButtonClasses}
                        onClick={() => this.finishButtonClick()}>
                      {stepData.submitButtonText}
                    </LoadingButton>
                ) : null}
              </div>
              <div className={classes.clearfix}/>
            </div>
          </Card>
        </div>
    );
  }
}

Wizard.defaultProps = {
  color: 'rose',
  title: 'Here should go your title',
  subtitle: 'And this would be your subtitle',
  previousButtonText: 'Previous',
  previousButtonClasses: '',
  nextButtonClasses: '',
  nextButtonText: 'Next',
  finishButtonClasses: '',
  finishButtonText: 'Finish',
};

Wizard.propTypes = {
  classes: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(
      PropTypes.shape({
        stepName: PropTypes.string.isRequired,
        stepComponent: PropTypes.any.isRequired,
        stepId: PropTypes.string.isRequired,
      }),
  ).isRequired,
  color: PropTypes.oneOf([
    'primary',
    'warning',
    'danger',
    'success',
    'info',
    'rose',
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  previousButtonClasses: PropTypes.string,
  previousButtonText: PropTypes.string,
  nextButtonClasses: PropTypes.string,
  nextButtonText: PropTypes.string,
  finishButtonClasses: PropTypes.string,
  finishButtonText: PropTypes.element,
  finishButtonClick: PropTypes.func,
  validate: PropTypes.bool,
  afterSave: PropTypes.func,
  extraProps: PropTypes.object,
  plain: PropTypes.bool,
  finishDisabled: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  initialStep: PropTypes.number,
};

export default withStyles(wizardStyle)(Wizard);
