import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Accordion_ from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
// @material-ui/icons
import ExpandMore from '@material-ui/icons/ExpandMore';

import styles from '../../assets/jss/material-dashboard-pro-react/components/accordionStyle.js';

const useStyles = makeStyles(styles);

export default function Accordion(props) {
  const [active, setActive] = React.useState(props.active);
  const handleChange = panel => (event, expanded) => {
    setActive(expanded ? panel : -1);
    if (props.onSelect) { props.onSelect(panel); }
  };
  const classes = useStyles();
  const {collapses} = props;
  return (
      <div className={classes.root}>
        {collapses.map((prop, key) => {
          return (
              <Accordion_
                  expanded={active === key}
                  onChange={handleChange(key)}
                  key={key}
                  classes={{
                    root: classes.expansionPanel,
                    expanded: classes.expansionPanelExpanded,
                  }}
              >
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    classes={{
                      root: classes.expansionPanelSummary,
                      expanded: classes.expansionPanelSummaryExpaned,
                      content: classes.expansionPanelSummaryContent,
                      expandIcon: classes.expansionPanelSummaryExpandIcon,
                    }}
                >
                  <h4 className={classes.title}>{prop.title}</h4>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.expansionPanelDetails}>
                  {prop.content}
                </AccordionDetails>
              </Accordion_>
          );
        })}
      </div>
  );
}

Accordion.defaultProps = {
  active: -1,
};

Accordion.propTypes = {
  // index of the default active collapse
  active: PropTypes.number,
  collapses: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.node,
      }),
  ).isRequired,
  onSelect: PropTypes.func,
};
