import React from 'react';
import { KeyboardDatePicker, KeyboardDateTimePicker, KeyboardTimePicker } from '@material-ui/pickers';
import CustomInput from '../../../vendor/components/CustomInput/CustomInput';
import { FormDataContext } from './index';

function DateTimeFormInput(props) {
  const formControlProps = props.formControlProps;
  return <FormDataContext.Consumer>
    {({data, onChange}) => {
      let value = data[props.id],
          sharedProps = {
            label: props.labelText || props.label,
            value: value || null,
            disabled: props.disabled,
            minutesStep: 5,
            TextFieldComponent: (props) =>
                <CustomInput {...props}
                             labelText={props.label}
                             inputProps={{
                               ...props.InputProps,
                               value: props.value,
                               onChange: props.onChange,
                               disabled: props.disabled,
                               inputRef: props.inputRef
                             }}
                             formControlProps={formControlProps || {fullWidth: true}}
                />
          };
      if (props.timeFormat && !props.dateFormat) {
        return <KeyboardTimePicker
            {...sharedProps}
            format='hh:mm A'
            mask="__:__ _M"
            placeholder="08:00 AM"
            inputValue={value}
            onChange={(time) => time === null
                ? onChange({[props.id]: time})
                : time.isValid() && onChange({[props.id]: time.format('hh:mm A')})}
            invalidDateMessage='Invalid Time'
        />;
      }
      if (props.dateFormat && !props.timeFormat) {
        return <KeyboardDatePicker
            {...sharedProps}
            autoOk
            variant="inline"
            format='MM/DD/YYYY'
            onChange={(date) =>
                (date == null
                ? onChange({[props.id]: date})
                : date.isValid() && onChange({[props.id]: date.format('YYYY-MM-DD')}))}
            invalidLabel='Invalid Date'
        />;
      }
      if (props.dateFormat && props.timeFormat) {
        return <KeyboardDateTimePicker
            {...sharedProps}
            format='MM/DD/YYYY hh:mm A'
            onChange={(datetime) => datetime === null
                ? onChange({[props.id]: datetime})
                : datetime.isValid() && onChange({[props.id]: datetime.format('YYYY-MM-DD[T]HH:mm:ss')})}
            invalidLabel='Invalid Date/Time'
        />;
      }

    }}
  </FormDataContext.Consumer>;
}

export default DateTimeFormInput;