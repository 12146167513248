/**
 * This file is executed before all others and mounts
 * the parent App component to the root element
 * on the DOM
 */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './core/components/app';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App/>, document.getElementById('root'));
serviceWorker.register();