import React, {Component} from 'react';
import FileInput from "../file_input";
import {FormDataContext} from "./index";

interface Props {
  id: string,
  labelText: string,
  accept?: string[],
  disabled?: boolean,
  required?: boolean,
  helperText?: string,
  error?: boolean,
  endAdornment?: any,
  noRemove?: boolean
}

class CustomFileFormInput extends Component<Props> {
  render() {
    const {
      id, labelText, helperText, error, endAdornment, required, noRemove,
      accept = ['image/*', 'video/*', 'application/*', 'text/*'],
    } = this.props;
    return (
      <FormDataContext.Consumer>
        {({data, onChange}: { data: any, onChange(_: any): void }) =>
          <FileInput
            labelText={labelText}
            accept={accept}
            error={error}
            helperText={helperText}
            value={data[id]}
            onChange={(url: string) => onChange({[id]: url})}
            noRemove={noRemove}
          />}
      </FormDataContext.Consumer>
    );
  }
}

export default CustomFileFormInput;