import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import customSelectStyle from '../../vendor/assets/jss/material-dashboard-pro-react/customSelectStyle';
import customCheckboxRadioSwitch from '../../vendor/assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';
import { withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListSubheader from '@material-ui/core/ListSubheader';

const styles = {
  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
};

class SelectMultiple extends Component {

  handleChange(values) {
    const {onChange, options: _options = []} = this.props,
        options = _options.map(
            o => (typeof o === 'string') ? {name: o, id: o} : o);
    onChange(values.map(id => (options.find(c => c.id === id) || {}).id)
        .filter(x => !!x));
  }

  render() {
    const {
          classes, label = 'Select One or Multiple', value: _value, id,
          options: _options = [], noSort, required, helperText, endAdornment,
          fullWidth, disabled
        } = this.props,
        value = _value || [],
        options = _options.map(
            o => (typeof o === 'string') ? {name: o, id: o} : o);

    const sortedOptions = noSort ? options : options.sort(
        (a, b) => (a.name || '').localeCompare(b.name || ''));
    return (
        <FormControl fullWidth={fullWidth} className={classes.selectFormControl}
                     required={required} error={this.props.error}>
          <InputLabel htmlFor={id} className={classes.selectLabel}
                      error={this.props.error}>
            {label}
          </InputLabel>
          <Select
              multiple
              MenuProps={{className: classes.selectMenuItemNonSelect}}
              error={this.props.error}
              value={Array.isArray(value) ? value : [value]}
              disabled={disabled}
              onChange={(e) => this.handleChange(e.target.value)}
              renderValue={selected => Array.isArray(selected) ? selected.map(
                  id => (sortedOptions.find(c => c.id === id) || '').name)
                  .filter(x => !!x).join(', ') : selected}
              inputProps={{
                name: id,
                id,
                readOnly: this.props.readOnly,
              }}
              endAdornment={endAdornment ? <InputAdornment position="end"
                                                           style={{marginRight: '15px'}}>{endAdornment}</InputAdornment> : undefined}>
            {sortedOptions.map((option) => option.subHeader
                ? <ListSubheader>{option.name}</ListSubheader>
                : <MenuItem value={option.id}
                            key={option.id}
                            classes={{
                              selected: classes.selectMenuItemSelected,
                            }} disabled={value.indexOf(option.id) === -1 && option.disabled}> <Checkbox
                    checked={value.indexOf(option.id) > -1}/>
                  {option.name}</MenuItem>)}

          </Select>
          {helperText ? <FormHelperText>{helperText}</FormHelperText> : ''}
        </FormControl>
    );
  }
}

export default withStyles(styles)(SelectMultiple);