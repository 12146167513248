import React from 'react';
import CustomInput from '../../../vendor/components/CustomInput/CustomInput';
import { FormDataContext } from './index';

function computeValidationState(validateFn, value, data) {
  if (!validateFn || typeof validateFn !== 'function') {
    return;
  }
  return validateFn(value, data) ? 'success' : 'error';
}

export default function CustomFormInput(props) {
  return <FormDataContext.Consumer>
    {({data, onChange}) => {
      const success = props.success === undefined
          ? data[props.id + 'State'] === 'success' : props.success,
          error = props.error === undefined
              ? data[props.id + 'State'] === 'error' : props.error;
      return <CustomInput {...props}
                          success={success}
                          error={error}
                          required={props.required}
                          helperText={props.helperText}
                          formControlProps={{
                            fullWidth: true, ...(props.formControlProps || {}),
                          }}
                          inputProps={{
                            id: props.id,
                            type: props.type,
                            value: (data[props.id] === undefined ||
                                data[props.id] === null)
                                ? ''
                                : data[props.id],
                            onChange: e => onChange({
                              [props.id]: e.target.value,
                              [props.id + 'State']: (error ||
                                  data[props.id + 'State'])
                                  ? computeValidationState(props.validate,
                                      e.target.value, data)
                                  : undefined,
                            }),
                            startAdornment: props.startAdornment,
                            endAdornment: props.endAdornment,
                            disabled: props.disabled,
                            autoFocus: props.autoFocus,
                            onBlur: e => {
                              onChange({
                                [props.id + 'State']: computeValidationState(
                                    props.validate, e.target.value, data,
                                ),
                              });
                            },
                            ...(props.inputProps || {}),
                          }}/>;
    }
    }
  </FormDataContext.Consumer>;
}
