import React, { Component } from 'react';
import Card from '../../vendor/components/Card/Card';
import CardHeader from '../../vendor/components/Card/CardHeader';
import CardIcon from '../../vendor/components/Card/CardIcon';
import { Edit, Warning } from '@material-ui/icons';
import CardBody from '../../vendor/components/Card/CardBody';
import dashboardStyle from '../components/dashboard/dashboardStyle';
import { withSession } from '../session_context';
import withStyles from '@material-ui/core/styles/withStyles';
import CustomFormInput from '../components/form_data/custom_form_input';
import GridContainer from '../../vendor/components/Grid/GridContainer';
import GridItem from '../../vendor/components/Grid/GridItem';
import Button from '../../vendor/components/CustomButtons/Button';
import { runSweetAlert } from '../components/Modal/Modal';
import SnackbarContent from '../../vendor/components/Snackbar/SnackbarContent';
import CustomSelectFormInput from '../components/form_data/custom_select_form_input';
import { FormData } from '../components/form_data';

class GreenbrierSignup extends Component {
  async componentDidMount() {
    const {session: {backendClient}} = this.props;
    this.setState({
      states: await backendClient.listStates(),
    });
  }

  async submit() {
    const {session: {backendClient}, history} = this.props,
        {firstName, lastName, email, stateId} = this.state || {};
    if (!firstName) {
      return this.setState({error: 'First Name is required'});
    }
    if (!lastName) {
      return this.setState({error: 'Last Name is required'});
    }
    if (!email) {
      return this.setState({error: 'Email is required'});
    }
    if (!stateId) {
      return this.setState({error: 'State is required'});
    }

    await backendClient.createGreenbrierSignup({}, {
      firstName, lastName, email, stateId,
    });
    await runSweetAlert({
      success: true,
      title: 'Success!',
      body: 'You have successfully signed up',
    });
    history.push('/');
  }

  render() {
    const {classes} = this.props,
        {states = [], error} = this.state || {};
    return (
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} md={9}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary"><Edit/></CardIcon>
                <h3 className={classes.cardIconTitle}>
                  Signup
                </h3>
              </CardHeader>
              <CardBody className={classes.root}>
                <FormData data={this.state || {}}
                          onChange={this.setState.bind(this)}>
                  <CustomFormInput labelText="First Name" id="firstName"/>
                  <CustomFormInput labelText="Last Name" id="lastName"/>
                  <CustomFormInput labelText="Email Address" id="email"/>

                  <CustomSelectFormInput labelText="State" id="stateId"
                                         options={
                                           states
                                               .sort((
                                                   a,
                                                   b) => a.name.localeCompare(
                                                   b.name))
                                               .map(s => ({
                                                 label: s.name,
                                                 value: s.id,
                                               }))
                                           }/>

                  {error && <div style={{marginTop: '1rem'}}>
                    <SnackbarContent message={error} close
                                     onClose={() => this.setState(
                                         {error: undefined})}
                                     icon={Warning} color="danger"/>
                  </div>}
                  <Button color="primary"
                          onClick={this.submit.bind(this)}>
                    Submit</Button>
                </FormData>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
    );
  }
}

export default withSession(withStyles(dashboardStyle)(GreenbrierSignup));