import React, {Component} from 'react';
import {DropzoneArea} from 'material-ui-dropzone/dist';
import {FormDataContext} from "./index";

interface Props {
  id: string,
  labelText: string,
  dropzoneText?: string,
  accept?: string[],
  maxFileSize?: number
}

class FileSelectFormInput extends Component<Props> {
  render() {
    const {
      id, labelText, dropzoneText,
      accept = ['image/*', 'video/*', 'application/*', 'text/*'],
      maxFileSize = 250000000
    } = this.props;
    return (
        <FormDataContext.Consumer>
          {({data, onChange}: { data: any, onChange(_: any): void }) =>
            <div style={{width: 'calc(100% - 30px)', marginBottom: '1.5rem'}}>
              <label style={{display: 'inline-block', marginBottom: '0.5rem'}}>
                {labelText}
              </label>
              <DropzoneArea
                dropzoneText={dropzoneText}
                onChange={files => onChange({...data, [id]: files[0]})}
                acceptedFiles={accept}
                filesLimit={1}
                maxFileSize={maxFileSize}
              />
              </div>}
        </FormDataContext.Consumer>
    );
  }
}

export default FileSelectFormInput;