import React, { Component } from 'react';
import Button from '../../vendor/components/CustomButtons/Button';
import { Autorenew } from '@material-ui/icons';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  spin: {
    animationName: 'spin',
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    width: 24, height: 24,
  },
  '@keyframes spin': {
    from: {transform: 'rotate(0deg)'},
    to: {transform: 'rotate(360deg)'},
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  }
};

class LoadingButton extends Component {
  async onClick() {
    const {onClick} = this.props;
    if (onClick) {
      try {
        this.setState({loading: true});
        const result = onClick();
        if (result instanceof Promise) {
          await result;
        }
      } finally {
        this.setState({loading: false});
      }
    }
  }

  render() {
    const {
          block,
          children,
          key,
          id,
          color,
          simple,
          className,
          size,
          disabled,
          loadingMsg,
          classes,
          type,
          fullWidth,
          ...rest
        } = this.props,
        {loading} = this.state || {},
        loadingMessage = loadingMsg || 'Processing...';

    return (
        <Button {...{
          block, key, id, color, simple, className, size,
          disabled: disabled || loading, type, fullWidth, ...rest
        }} onClick={this.onClick.bind(this)}>
          {loading ? <span className={classes.container}><Autorenew
              className={classes.spin}/> {loadingMessage}</span> : children}
        </Button>
    );
  }
}

export default withStyles(styles)(LoadingButton);