import React, { Component } from 'react';
import { withSession } from '../../session_context';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import styles from '../../../vendor/assets/jss/material-dashboard-pro-react/customSelectStyle';
import { withStyles } from '@material-ui/core';
import StateSelect from './state_select';
import GridContainer from '../../../vendor/components/Grid/GridContainer';
import GridItem from '../../../vendor/components/Grid/GridItem';

class DistrictSelect extends Component {

  async componentDidMount() {
    const {session: {backendClient}, value, stateId} = this.props;
    if (stateId) {
      this.setState(
          {districts: await backendClient.listDistricts({stateId}), stateId});
    } else if (value) {
      const district = await backendClient.findDistrict({id: value});
      this.setState({
        districts: await backendClient.listDistricts(
            {stateId: district.stateId}),
        stateId: district.stateId,
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const {session: {backendClient}, value} = this.props;
    if ((value !== prevProps.value) && !this.state) {
      const district = await backendClient.findDistrict({id: value});
      this.setState({
        districts: await backendClient.listDistricts(
            {stateId: district.stateId}),
        stateId: district.stateId,
      });
    }
  }

  async changeState(stateId) {
    const {session: {backendClient}, onChange} = this.props;
    this.setState(
        {districts: await backendClient.listDistricts({stateId}), stateId});
    onChange();
  }

  render() {
    const {classes, label = 'District', value, onChange, id} = this.props,
        {districts = [], stateId} = this.state || {};
    return (<GridContainer>
      {this.props.stateId ? '' : <GridItem xs={12} sm={12} md={12}>
        <StateSelect value={stateId} onChange={this.changeState.bind(this)}/>
      </GridItem>}
      {stateId && <GridItem sm={12} xs={12} md={12}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor={id} className={classes.selectLabel}>
            {label}
          </InputLabel>
          <Select
              MenuProps={{className: classes.selectMenu}}
              classes={{select: classes.select}}
              value={value || ''}
              onChange={e => onChange(e.target.value)}
              inputProps={{name: id, id}}>
            {districts.map((district) =>
                <MenuItem value={district.id}
                          key={district.id}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}>{district.name}</MenuItem>)}

          </Select>
        </FormControl>
      </GridItem>}
    </GridContainer>);
  }
}

export default withSession(withStyles(styles)(DistrictSelect));