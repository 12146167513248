import React, {useState} from 'react';
import {InputProps, TextField} from "@material-ui/core";
import MaterialAutoComplete from '@material-ui/lab/Autocomplete';

interface Props<T> {
  label?: string;
  InputProps?: InputProps;
  disabled?: boolean;
  options?: T[];
  value?: T;
  onChange?: (newValue: T) => void;
  width?: number;
  loadOptions?: (query: string) => Promise<T[] | undefined>;
  renderOption?: (option: T) => string;
  getOptionLabel?: (option: T) => string;
  compareOptions?: (option: T, value: T) => boolean;
}

export default function AutoComplete<T>(props: Props<T>) {
  const [inputValue, setInputValue] = useState<string>();
  const [options, setOptions] = useState<T[]>();
  return <MaterialAutoComplete
    options={props.options || options || []}
    value={props.value || null}
    onChange={(e: any, value: any) => props.onChange?.(value as T)}
    getOptionLabel={props.getOptionLabel}
    getOptionSelected={props.compareOptions}
    renderOption={props.renderOption}
    renderInput={(params) =>
      // Using a TextField here instead of a CustomInput because I was having issues with the inputRef
      // not being set when using CustomInput
      <TextField {...params}
                 label={props.label}
                 value={inputValue || ''}
                 onChange={async (e) => {
                   setInputValue(e.target?.value);
                   if (props.loadOptions) {
                     setOptions(await props.loadOptions(e.target?.value));
                   }
                 }}/>}
    style={{width: props.width || '100%', maxWidth: '100%'}}
  />
}