import React from 'react';

import defaultImage from '../../../vendor/assets/img/default-avatar.png';
import Spinner from '../../../core/components/spinner';
import Dialog from '@material-ui/core/Dialog';
import modalStyle from '../../../vendor/assets/jss/material-dashboard-pro-react/modalStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../vendor/components/CustomButtons/Button';
import Close from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import GridContainer from '../../../vendor/components/Grid/GridContainer';
import GridItem from '../../../vendor/components/Grid/GridItem';
import {runSweetAlert} from '../Modal/Modal';


function getAvatarUrls() {
  const avatarUrls = [];
  for (let i = 1; i < 27; i++) {
    avatarUrls.push(`/avatars/av${i}.jpg`);
  }
  return avatarUrls;
}

class AvatarUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      imagePreviewUrl: defaultImage,
      modal: false,
    };
    this.fileInput = React.createRef();
  }

  showAvatarOptions(e) {
    e.preventDefault();
    this.setState({modal: true});
  }

  hideAvatarOptions() {
    this.setState({modal: false});
  }

  async handleImageChange(e) {
    e.preventDefault();
    this.setState({modal: false});
    const {onUpload} = this.props,
        file = e.target.files[0];
    if(!['image/png', 'image/jpeg'].includes(file.type.toLowerCase())) {
      await runSweetAlert({
        danger: true,
        body: `Invalid file type ${file.type}, please select a PNG or JPEG file`
      });
    } else {
      if (onUpload) {
        this.setState({uploading: true});
        try {
          await onUpload(file);
        } catch (e) {
          console.error('Upload Error', e);
        }
        this.setState({uploading: false});
      } else {
        let reader = new FileReader();
        reader.onloadend = () => {
          this.setState({
            file, imagePreviewUrl: reader.result,
          });
        };
        reader.readAsDataURL(file);
      }
    }
  }

  chooseAvatar(avatarUrl) {
    const {onChange} = this.props;
    this.setState({modal: false});
    onChange(avatarUrl);
  }

  render() {
    const {label = 'Choose Picture', url, alt, classes, session:{settings}} = this.props,
        {uploading} = this.state;
    return (
        <div>
          <div className="picture-container">
            {uploading
                ? <Spinner/>
                : <div className="picture"
                       onClick={this.showAvatarOptions.bind(this)}>
                  <img src={url || this.state.imagePreviewUrl}
                       className="picture-src" alt="profile"/>
                </div>}
            <h6 className="description">{label}</h6>
          </div>
          <Dialog
              fullWidth

              open={this.state.modal}
              keepMounted
              onClose={() => this.hideAvatarOptions()}
              aria-labelledby="modal-slide-title"
              aria-describedby="modal-slide-description">
            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}>
              <Button
                  justIcon
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="transparent"
                  onClick={() => this.hideAvatarOptions()}
              >
                <Close className={classes.modalClose}/>
              </Button>
              <h4 className={classes.modalTitle}>Choose an Avatar</h4>
            </DialogTitle>

            <DialogContent>
              <GridContainer>
                {getAvatarUrls()
                    .map((avatarUrl) => <div className="picture-container" key={avatarUrl}>

                      <GridItem align='center'>
                        <div className="picture">
                          <img src={avatarUrl}
                               className="picture-src" alt={alt}
                               onClick={() => this.chooseAvatar(avatarUrl)}/>
                        </div>
                        <h6 className="description">{label}</h6>
                      </GridItem>

                    </div>)
                }

                {settings.disableCustomProfilePicture !== true ? <GridItem>
                  <div className="picture-container">
                    <div className="picture">
                      <img src={this.state.imagePreviewUrl}
                           className="picture-src" alt={alt}/>
                      <input type="file" accept="image/png, image/jpeg"
                             onChange={this.handleImageChange.bind(this)}/>
                    </div>
                    <h6 className="description">Upload My Avatar</h6>
                  </div>
                </GridItem> : ''}
              </GridContainer>

            </DialogContent>
            <DialogActions
                className={classes.modalFooter + ' ' +
                classes.modalFooterCenter}>
              <Button className={classes.modalCloseOnlyButton}
                      onClick={() => this.hideAvatarOptions()}
                      color="info">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
    );
  }
}

export default withStyles(modalStyle)(AvatarUpload);
