import React from 'react';
// used for making the prop types of this component
import PropTypes from 'prop-types';
// core components
import Button from '../../../vendor/components/CustomButtons/Button';

import defaultImage from '../../../vendor/assets/img/image_placeholder.jpg';
import defaultAvatar from '../../../vendor/assets/img/placeholder.jpg';
import { withSession } from '../../session_context';
import Alert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';
import { runModalForm } from "../Modal/Modal";
import CustomFormInput from "../form_data/custom_form_input";

function _ImageUpload(props) {
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
      props.avatar ? defaultAvatar : defaultImage,
  );
  let fileInput = React.createRef();
  const [error, setError] = React.useState(null);

  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!['image/png', 'image/jpeg'].includes(file.type.toLowerCase())) {
      return setError(`Invalid file type ${file.type}, please choose a PNG or JPEG file`);
    } else {
      setError(null);
    }
    if (file) {
      reader.onloadend = () => {
        setFile(file);
        setImagePreviewUrl(reader.result);
        props.onUpload && props.onUpload(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
    props.onChange && props.onChange(null);
  };
  const handleRotate = async () => {

    const {session: {backendClient}, url} = props;
    const oldUrl = await backendClient.getUserFileUrl({path: ''});
    const newUrl = url.replace(oldUrl, '');
    await backendClient.rotateUploadImage({path: newUrl});
    if (props.onChange) {
      props.onChange(url.replace(/\?.*$/, '') + '?' + new Date().getTime());
    }
  };

  let {avatar, addButtonProps, changeButtonProps, removeButtonProps, alt, onAltChange} = props;
  let url;
  if (props.url !== '' && typeof props.url === 'string') {
    url = props.url;
  }
  return (
      <div className="fileinput text-center">
        <input type="file" onChange={handleImageChange} ref={fileInput} accept="image/png, image/jpeg"/>
        <div className={'thumbnail' + (avatar ? ' img-circle' : '')}>
          <img src={url || imagePreviewUrl} alt={alt}/>
        </div>
        <div>
          {(!url && file === null) ? (
              <Button {...addButtonProps} onClick={() => handleClick()}>
                {avatar ? 'Add Photo' : 'Select image'}
              </Button>
          ) : (
              <span>
            <Button {...changeButtonProps} onClick={() => handleClick()}>
              Change
            </Button>
                {avatar ? <br/> : null}
                <Button {...removeButtonProps} onClick={() => handleRemove()}>
              <i className="fas fa-times"/> Remove
            </Button>
                {url ? <Button {...removeButtonProps}
                               onClick={() => handleRotate()}>
                  <i className="fas fa-times"/> Rotate
                </Button> : ''}
                {onAltChange ? <Button onClick={async () => {
                  try {
                    const {altValue} = await runModalForm(() => <>
                      <CustomFormInput id="altValue" labelText="Alt Image Text"/>
                    </>, {title: 'Edit Alt', initialState: {altValue: alt}});
                    onAltChange(altValue);
                  } catch (e) {
                    if(e) {
                      throw e;
                    }
                  }
                }}>Edit Alt</Button> : ''}
          </span>
          )}
          {error ? <Container maxWidth="sm">
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          </Container> : ''}
        </div>
      </div>
  );
}

_ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};

const ImageUpload = withSession(_ImageUpload);
export default ImageUpload;