import React, {Component} from 'react';
import Spinner from './spinner';
import Card from '../../vendor/components/Card/Card';
import CardHeader from '../../vendor/components/Card/CardHeader';
import CardBody from '../../vendor/components/Card/CardBody';
import dashboardStyle from './dashboard/dashboardStyle';
import {withStyles} from '@material-ui/core';
import {Help} from '@material-ui/icons';
import CardIcon from '../../vendor/components/Card/CardIcon';
import GridContainer from '../../vendor/components/Grid/GridContainer';
import GridItem from '../../vendor/components/Grid/GridItem';
import {NavLink, Route, Switch} from 'react-router-dom';
import {toKebabCase} from '../shared/shared_helpers';

const styles = {
  ...dashboardStyle,
};

class HelpCenterView extends Component {
  async componentDidMount() {
    const {loadHelpData} = this.props;

    if (loadHelpData) {
      this.setState({helpData: await loadHelpData()});
    }
  }

  render() {
    const {classes, baseUrl = ''} = this.props,
        {helpData} = this.state || {};
    if (!helpData) {
      return <Spinner/>;
    }
    const {sections = [], homeComponent} = helpData;
    return (
        <Card>
          <CardHeader color="warning" icon>
            <CardIcon color="warning">
              <Help/>
            </CardIcon>
            <h3 className={classes.cardIconTitle}>Help Center</h3>
            <p className={classes.cardCategory}>{helpData.title}</p>
          </CardHeader>
          <CardBody>
            <hr/>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} lg={3}>

                <NavLink to={baseUrl} exact
                         activeStyle={{fontWeight: 'bold'}}>
                  Home
                </NavLink>

                {sections.map(s =>
                    <div key={s.title} style={{marginTop: '2rem'}}>
                      <h4>{s.title}</h4>
                      {s.modules.map(m =>
                          <div key={m.title}>
                            <NavLink to={baseUrl + '/' + toKebabCase(m.title)}
                                     activeStyle={{fontWeight: 'bold'}}>
                              {m.title}
                            </NavLink>
                          </div>,
                      )}
                    </div>,
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={8} lg={9}>
                <Switch>
                  {sections.map(s => s.modules)
                      .filter(s => !!s.component)
                      .map(s =>
                          <Route key={s.title}
                                 path={baseUrl + '/' + toKebabCase(s.title)}
                                 exact component={s.component}/>)
                      .reduce((a, b) => a.concat(b), [])}
                  <Route path={baseUrl} exact component={homeComponent || (() =>
                      <div>
                        <h2>Welcome to the Help Center</h2>
                        <p>To get started select a topic on the left</p>
                      </div>)}/>
                </Switch>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
    );
  }
}

export default withStyles(styles)(HelpCenterView);