import React, { Component } from 'react';
import Spinner from '../../../core/components/spinner';
import { Redirect } from 'react-router-dom';
import { Session } from '../../../core/components/app/types';
import { Event, EventRegistration, ExternalEventParticipant } from '../../../core/services/backend-client';
import SessionContext, { withSession } from '../../../core/session_context';
import Wizard from '../../../vendor/components/Wizard/Wizard';
import GridContainer from '../../../vendor/components/Grid/GridContainer';
import GridItem from '../../../vendor/components/Grid/GridItem';
import Step1GeneralInfo from './steps/step_1_general_info';
import Step2Waivers from './steps/step_2_waivers';
import Step3Checkout from './steps/step_3_checkout';
import Card from '../../../vendor/components/Card/Card';
import wizardStyle from '../../../vendor/assets/jss/material-dashboard-pro-react/components/wizardStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import { confirmModal, runSweetAlert } from '../../../core/components/Modal/Modal';
import Step0EventInfo from './steps/step_0_event_info';
import moment from 'moment';
import { calculateFormCost } from '../../../core/components/custom_form/custom_form_editor';
import { RouteProps, withRouter, } from 'react-router';
import { extractError } from '../../../core/shared/shared_helpers';

interface Props extends RouteProps {
  session: Session,
  classes: any,
  match: { params: { eventId: number } },
  history: any
}

interface State {
  event: EventWithSpace | undefined,
  stateId: string,
  settings: any,
  loading: boolean,
  error: string | undefined,
  nextDisabled: boolean,
  participant: ExternalEventParticipant,
  registration: EventRegistration,
  checkout: boolean,
}

interface EventWithSpace extends Event {
  remainingClubMemberSpace: number | undefined,
  remainingVolunteerSpace: number | undefined
}

class ExternalEventRegistration extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      checkout: false
    } as any;
  }

  async componentDidMount() {
    const {match: {params: {eventId}}, location, history, session} = this.props,
        {backendClient, account} = session,
        extParticipantId = parseInt(new URLSearchParams(location?.search).get("extParticipantId") || ''),
        participant = extParticipantId
            ? await backendClient.findExternalEventParticipant({id: extParticipantId}).catch(() => undefined) : undefined,
        registration = extParticipantId
            ? await backendClient.findExternalEventRegistration({eventId, extParticipantId}).catch(() => undefined) : undefined;

    const event = await backendClient.findEvent({id: eventId}).catch(() => undefined);
    let remainingSpace = event?.id ? await backendClient.findEventRemainingSpace({id: event.id}) : {};
    let stateId, stateSettings, settings;
    if (event?.stateId) {
      stateId = event.stateId;
      stateSettings = await backendClient.getStateSettings({stateId: event.stateId, orgId: event.orgId});
      settings = await backendClient.getSettings({stateId: event.stateId, orgId: event.orgId});
    } else if (event && event.countyIds.length > 0) {
      const county = await backendClient.findCounty({id: event.countyIds[0]});
      stateId = county.stateId;
      stateSettings = await backendClient.getStateSettings({stateId: county.stateId, orgId: event.orgId});
      settings = await backendClient.getSettings({stateId: county.stateId, orgId: event.orgId});
    }

    if(account && account.accountType === 'Household' && session?.state?.id === stateId) {
      return history.replace(`/event-registrations/${eventId}`);
    }

    //console.log('Remaining space', remainingSpace);
    this.showNextButton({
      ...event,
      remainingClubMemberSpace: remainingSpace.ClubMember || undefined,
      remainingVolunteerSpace: remainingSpace.Volunteer || undefined
    } as EventWithSpace, registration);
    this.setState({
      event: {
        ...event,
        remainingClubMemberSpace: remainingSpace.ClubMember || undefined,
        remainingVolunteerSpace: remainingSpace.Volunteer || undefined
      } as EventWithSpace,
      stateId: stateId as string,
      settings: {...settings, state: stateSettings},
      participant: participant as ExternalEventParticipant,
      registration: registration as EventRegistration,
    }, () => this.setState({
      loading: false
    }));
  }

  update(participant: Partial<ExternalEventParticipant>, registration: Partial<EventRegistration>) {
    this.setState({
      participant: {...this.state.participant, ...participant},
      registration: {...this.state.registration, ...registration}
    });
  }

  getComputedFee() {
    const {event, registration} = this.state;
    if (event && registration) {
      return (event.fee || 0) +
          (calculateFormCost(registration.regData, registration.type === 'ClubMember'
              ? event?.participantRegFields : event?.volunteerRegFields).total || 0);
    }
    return 0;
  }

  async onCheckoutClick() {
    const {session: {backendClient, orgId}, history} = this.props;
    const {event, participant, registration} = this.state;
    const computedFee = this.getComputedFee();

    if (computedFee && (!event?.payAfterApproval || registration.status === 'PendingPayment')) {
      const moveToCheckout = await confirmModal('Move to Checkout', 'Are you sure you want to move to checkout?').catch(() => false);

      if (moveToCheckout) {
        let newParticipant = participant, newRegistration = registration;
        if (participant.id) {
          await backendClient.updateExternalEventParticipant({}, participant);
          await backendClient.updateEventRegistration({}, registration);
        } else {
          newParticipant = await backendClient.createExternalEventParticipant({}, participant);
          newRegistration = await backendClient.createEventRegistration({}, {
            ...registration,
            externalParticipantId: newParticipant.id,
            eventId: event?.id as number
          }).then(newReg => backendClient.updateEventRegistration({}, {
            ...newReg,
            ...registration
          }));

          this.update(newParticipant, newRegistration);
        }

        try {
          await backendClient.submitEventRegistration({}, {
            eventRegistrationId: newRegistration.id,
          });
          // Load county settings for payments
          const countySettings = await backendClient.getCountySettings({countyId: participant.countyId, orgId});
          this.setState(s => ({checkout: true, settings: {...s.settings, county: countySettings}}));
        } catch (e) {
          const error = await extractError(e, 'Unable to save registration');
          await runSweetAlert({error: true, title: 'Error', body: error});
        }
      }
    } else {
      const confirmSubmission = await confirmModal('Submit Registration', 'Are you sure you want to submit your registration?').catch(() => false);
      if (confirmSubmission) {
        let newParticipant = participant, newRegistration = registration;
        if (participant.id) {
          await backendClient.updateExternalEventParticipant({}, participant);
          await backendClient.updateEventRegistration({}, registration);
        } else {
          newParticipant = await backendClient.createExternalEventParticipant({}, participant);
          newRegistration = await backendClient.createEventRegistration({}, {
            ...registration,
            externalParticipantId: newParticipant.id,
            eventId: event?.id as number
          }).then(newReg => backendClient.updateEventRegistration({}, {
            ...newReg,
            ...registration
          }));

          this.update(newParticipant, newRegistration);
        }

        try {
          await backendClient.submitEventRegistration({}, {
            eventRegistrationId: newRegistration.id,
          });

          await runSweetAlert({
            success: true,
            title: 'Success!',
            body: 'Your event registration has been successfully submitted!'
          });
          history.push('/');
        } catch (e) {
          const error = await extractError(e, 'Unable to save registration');
          await runSweetAlert({error: true, title: 'Error', body: error});
        }
      }
    }


  }

  showNextButton(event: EventWithSpace, registration?: EventRegistration) {

    // If someone is pending payment, allow them to pay after the registration close date
    if (registration?.status === 'PendingPayment') {
      return;
    }

    if (event?.registrationOpen && moment().isBefore(event.registrationOpen)) {
      this.setState({
        error: `Registration Opens: ${moment(event.registrationOpen).format('LLL')}`,
        nextDisabled: true
      });
      return;
    }

    if (event?.registrationClose && moment().isAfter(event.registrationClose)) {
      this.setState({
        error: 'Registration is Closed for this Event',
        nextDisabled: true
      });
      return;
    }

    if ((event?.remainingClubMemberSpace !== undefined && event.remainingClubMemberSpace <= 0)
        && (!event?.allowVolunteerReg
            || (event?.remainingVolunteerSpace !== undefined && event.remainingVolunteerSpace <= 0))) {
      this.setState({
        error: 'No remaining spaces available for this Event',
        nextDisabled: true
      });
      return;
    }
    //Disable next button if event is closed
    if(event?.status === "Closed") {
      this.setState({
        error: 'This Event is Closed',
        nextDisabled: true
      });
      return;
    }

    this.setState({nextDisabled: false});
  }

  render() {
    const {session, classes} = this.props;
    const {
      event,
      stateId,
      participant,
      registration,
      settings,
      loading,
      error,
      nextDisabled,
      checkout
    } = this.state || {};
    const computedFee = this.getComputedFee();

    const noSubmit = !!(participant?.id && stateId === 'US-UT' && registration.status !== 'InProgress' && registration.status !== 'PendingPayment');

    if (!event) {
      if (loading) {
        return <Spinner/>
      } else {
        return <Redirect to={'/'}/>
      }
    }

    if (!event.allowExternalRegistration) {
      return <Redirect to={'/'}/>
    }

    if (checkout) {
      return <GridContainer justify='center' style={{width: '100%', margin: 0}}>
        <GridItem xs={12} md={10} style={{maxWidth: 760}}>
          <div className={classes.wizardContainer}>
            <Card className={classes.card}>
              <div className={classes.wizardHeader}>
                <h3 className={classes.title}>{event.name} Registration Checkout</h3>
              </div>
              <div className={classes.content}>
                <Step3Checkout {...{
                  event,
                  stateId,
                  participant: participant || {},
                  registration: registration || {},
                  session,
                  settings,
                  update: this.update.bind(this),
                  back: () => this.setState({checkout: false})
                }}/>
              </div>
            </Card>
          </div>
        </GridItem>
      </GridContainer>
    }

    return <SessionContext.Provider value={{...session, settings}}>
      <GridContainer justify="center" style={{width: '100%', margin: 0}}>
        <GridItem xs={12} md={10} style={{maxWidth: 760}}>
          <Wizard title={`${event.name} Registration`}
                  initialStep={(participant?.id && !nextDisabled) ? 1 : undefined}
                  subtitle="" validate
                  color="info"
                  extraProps={{
                    event,
                    stateId,
                    participant: participant || {},
                    registration: registration || {},
                    session,
                    settings,
                    update: this.update.bind(this),
                    error,
                  }}
                  finishDisabled={noSubmit}
                  finishButtonText={<span>{(computedFee === 0 ||
                      (event.payAfterApproval && registration.status !== 'PendingPayment'))
                      ? 'Submit Registration'
                      : 'Checkout'}</span>}
                  finishButtonClick={() => this.onCheckoutClick()}
                  nextDisabled={nextDisabled}
                  steps={[
                    {
                      stepName: 'Event Information',
                      stepId: 'eventInfo',
                      stepComponent: Step0EventInfo,
                    },
                    {
                      stepName: 'General Information',
                      stepId: 'general',
                      stepComponent: Step1GeneralInfo,
                    },
                    {
                      stepName: 'Registration Information',
                      stepId: 'waivers',
                      stepComponent: Step2Waivers,
                    },
                  ]}/>
        </GridItem>
      </GridContainer>
    </SessionContext.Provider>
  }
}

export default withStyles(wizardStyle as any)(withRouter(withSession(ExternalEventRegistration)));