import React from 'react';
import NavPills from '../../../vendor/components/NavPills/NavPills';
import Spinner from '../spinner';

export default function LazyNavPills(props) {
  const [active, setActive] = React.useState(0);

  return <NavPills {...props}
                   onChange={setActive}
                   tabs={props.tabs.map((tab, i) => ({...tab, tabContent: i === active ? tab.tabContent : <Spinner/>}))}
  />;
}