class AnalyticsSocketClient {
  constructor() {
    this.socketUrl = process.env.REACT_APP_WS_ANALYTICS ||
        window.location.protocol.replace('http', 'ws')
        + '//' + window.location.host + '/ws-analytics';
  }

  async connect() {
    await new Promise((resolve, reject) => {
      this.disconnect();
      this.socket = new WebSocket(this.socketUrl);
      this.socket.onopen = e => {
        this.onOpen(e);
        resolve();
      };
      this.socket.onclose = this.onClose.bind(this);
      this.socket.onmessage = this.onMessage.bind(this);
      this.socket.onerror = this.onError.bind(this);
      setTimeout(() => {
        if (!this.isConnected()) {
          reject();
        }
      }, 5000);
    });
    const {id} = await this.waitFor();
    this.sessionId = id;
  }

  isConnected() {
    return this.socket && this.socket.readyState === 1;
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
    }
  }

  send(data) {
    if (this.isConnected()) {
      this.socket.send(JSON.stringify(data));
    }
  }

  waitFor(data) {
    return new Promise((resolve, reject) => {
      if (this.waitForCallback) {
        throw new Error('Only one subscribe/waitFor call at a time');
      }
      this.waitForCallback = result => {
        this.waitForCallback = undefined;
        this.waitForCallbackError = undefined;
        resolve(result);
      };
      this.waitForCallbackError = e => {
        this.waitForCallback = undefined;
        this.waitForCallbackError = undefined;
        reject(e);
      };
      if (data) {
        this.send(data);
      }
    });
  }

  subscribe(data, callback) {
    if (this.waitForCallback) {
      throw new Error('Only one subscribe/waitFor call at a time');
    }
    this.waitForCallback = callback;
    this.waitForCallbackError = e => {
      console.error('Subscription error', e);
    };
    if (data) {
      this.send(data);
    }
  }

  unsubscribe(data) {
    this.waitForCallback = undefined;
    this.waitForCallbackError = undefined;
    this.send(data);
  }

  onOpen() {
    // console.log('Socket Open');
  }

  onMessage(e) {
    const data = JSON.parse(e.data);
    if (this.waitForCallback) {
      this.waitForCallback(data);
    } else {
      // console.log('got message', data);
    }
  }

  onError(e) {
    // console.log('Analytics Error', e);
    if (this.waitForCallbackError) {
      this.waitForCallbackError(e);
    }
  }

  onClose(e) {
    // console.log('Closed', e);
  }
}

export default AnalyticsSocketClient;