import React from 'react';
import GridItem from '../../../vendor/components/Grid/GridItem';
import GridContainer from '../../../vendor/components/Grid/GridContainer';
import { InputLabel } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CustomInput from '../../../vendor/components/CustomInput/CustomInput';
import { FormDataContext } from './index';

export default function DateRangeFormInput(props) {
  //console.log(props)
  // added props.startValue and props.endValue to allow for direct passing of props to and from component
  return (<FormDataContext.Consumer>
    {({data, onChange}) => {
      const onChangeCb = (value) => {
        onChange(value);
        if (props.onChange) {
          props.onChange(value);
        }
      };
      //console.log(data)
      const sharedProps = {
        autoOk: true,
        variant: 'inline',
        format: 'MM/DD/YYYY',
        disabled: props.disabled,
        TextFieldComponent: (props) =>
            <CustomInput {...props}
                         labelText={props.label}
                         inputProps={{
                           ...props.InputProps,
                           value: props.value,
                           onChange: props.onChange,
                           disabled: props.disabled,
                           inputRef: props.inputRef
                         }}
                         formControlProps={{fullWidth: true}}
            />
      };
      return <GridContainer justify='center'>
        {props.labelText ? <GridItem xs={12}>
          <InputLabel htmlFor={props.startDateId} style={{marginBottom: 5}}>
            {props.labelText}
          </InputLabel>
        </GridItem> : ''}
        <GridItem md={5} lg={5}>
          <KeyboardDatePicker
              {...sharedProps}
              label={props.startLabel || 'Start Date'}
              value={data[props.startDateId] || props.startValue || null}
              onChange={(date) => date === null
                  ? onChangeCb({[props.startDateId]: date})
                  : date.isValid() && onChangeCb({[props.startDateId]: date.format('YYYY-MM-DD')})}
          />
        </GridItem>
        <GridItem md='auto' lg='auto'>
          <br/>
          <p style={{marginTop: 15}}>to</p>
        </GridItem>
        <GridItem md={5} lg={5}>
          <KeyboardDatePicker
              {...sharedProps}
              label={props.endLabel || 'End Date'}
              value={data[props.endDateId] || props.endValue || null}
              onChange={(date) => date === null
                  ? onChangeCb({[props.endDateId]: date})
                  : date.isValid() && onChangeCb({[props.endDateId]: date.format('YYYY-MM-DD')})}
          />
        </GridItem>
      </GridContainer>;
    }}
  </FormDataContext.Consumer>);
}